import { motion } from "framer-motion";
import Nav from "../../../components/partials/Nav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import {
  useGetAllCategoriesQuery,
  useGetSeasonContestantsQuery,
  useGetTransactionsQuery,
} from "../../../app/apiSlice";
import LoadingButton from "../../../components/LoadingButton";

const statuses = [
  {
    name: "pending",
    value: "Pending",
  },
  {
    name: "complete",
    value: "Complete",
  },
  {
    name: "failed",
    value: "Failed",
  },
];
const paymentTypes = [
  {
    name: "mobilemoney",
    value: "Mobile Money",
  },
  {
    name: "card",
    value: "Card",
  },
];

const sources = [
  {
    name: "app",
    value: "App",
  },
  {
    name: "hubtel",
    value: "USSD",
  },
];

const Transactions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code, season_code, team_code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");

  const [pageNumber, setPageNumber] = useState(1);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [contID, setContID] = useState("");
  const [catID, setCatID] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentSource, setPaymentSource] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [searchContestant, setSearchContestant] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchPaymentType, setSearchPaymentType] = useState("");
  const [searchPaymentSource, setSearchPaymentSource] = useState("");
  const [reference, setReference] = useState("");
  const [searchPaymentStatus, setSearchPaymentStatus] = useState("");
  const [selectedContestant, setSelectedContestant] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedPaymentType, setSelectedPaymentType] = useState({});
  const [selectedPaymentSource, setSelectedPaymentSource] = useState({});
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState({});
  const [isExportReload, setIsExportReload] = useState(false);

  const [isContestantActive, setIsContestantActive] = useState(false);
  const [isCategoryActive, setIsCategoryActive] = useState(false);
  const [isPaymentTypeActive, setIsPaymentTypeActive] = useState(false);
  const [isPaymentStatusActive, setIsPaymentStatusActive] = useState(false);
  const [isPaymentSourceActive, setIsPaymentSourceActive] = useState(false);
  const [contestants, setContestants] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [categories, setCategories] = useState([]);
  const contestantDropdownRef = useRef(null);
  const paymentTypeDropdownRef = useRef(null);
  const paymentSourceDropdownRef = useRef(null);
  const paymentStatusDropdownRef = useRef(null);
  const categoryDropdownRef = useRef(null);
  let currentDate = new Date().toISOString().split("T")[0];
  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  const initialUserInput = {
    from: currentDate,
    to: currentDate,
    pageSearch: "",
    payment_type: "",
    source: "",
    status: "",
    ref: "",
  };
  const [userInput, setUserInput] = useState(initialUserInput);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const {
    data: alltransactions,
    isLoading: isTransactionsLoading,
    isFetching,
  } = useGetTransactionsQuery({
    showID: activeShow,
    seasonID: season,
    fromValue: fromValue != "" ? fromValue : currentDate,
    toValue: toValue != "" ? toValue : currentDate,
    page: pageNumber,
    ...(contID !== undefined && contID !== "" && { contID }),
    ...(catID !== undefined && catID !== "" && { catID }),
    ...(paymentType !== undefined && paymentType !== "" && { paymentType }),
    ...(paymentSource !== undefined &&
      paymentSource !== "" && { paymentSource }),
    ...(paymentStatus !== undefined &&
      paymentStatus !== "" && { paymentStatus }),
    ...(reference !== undefined && reference !== "" && { reference }),
  });

  const {
    data: allCategories,
    isLoading,
    refetch: refetchCategories,
  } = useGetAllCategoriesQuery({
    showID: activeShow,
  });

  useEffect(() => {
    if (allCategories) {
      setCategories(allCategories.data.teams);
    }
  }, [allCategories]);
  const handleExportTransactions = () => {
    setIsExportReload(true);

    toast.success("Your data will be exported soon", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    // const apiUrl = `https://realityshowsapi-staging.3news.com/api/v1/export`;
    const apiUrl = `https://realityshowsapi.3news.com/api/v1/export`;
    const params = new URLSearchParams({
      show_id: activeShow,
      season_id: season,
      from: fromValue,
      to: toValue,
      page: pageNumber,
    });

    if (contID !== undefined && contID !== "")
      params.append("filter[cont_id]", contID);
    // if (catID !== undefined && catID !== "")
    //   params.append("filter[team_id]", catID);
    if (paymentType !== undefined && paymentType !== "")
      params.append("filter[payment_type]", paymentType);
    if (paymentSource !== undefined && paymentSource !== "")
      params.append("filter[source]", paymentSource);
    if (paymentStatus !== undefined && paymentStatus !== "")
      params.append("filter[status]", paymentStatus);
    if (reference !== undefined && reference !== "")
      params.append("filter[vote_reference]", reference);

    const finalUrl = `${apiUrl}?${params.toString()}`;

    setTimeout(() => {
      window.location.replace(finalUrl);
      setIsExportReload(false);
    }, 3000);
  };

  const { data: allcontestants } = useGetSeasonContestantsQuery({
    showID: activeShow,
    seasonID: season,
  });
  useEffect(() => {
    if (alltransactions) {
      setTransactions(alltransactions.data);
    }
  }, [alltransactions]);

  useEffect(() => {
    if (allcontestants) {
      setContestants(allcontestants.data.contestants);
    }
  }, [allcontestants]);

  const onContestantChange = (currentContestant) => {
    if (currentContestant === null) {
      setSearchContestant("");
      setSelectedContestant({});
    }
    setSelectedContestant(currentContestant);
    setSearchContestant(currentContestant?.stage);
  };
  const onCategoryChange = (currentCategory) => {
    if (currentCategory === null) {
      setSearchCategory("");
      setSelectedCategory({});
    }
    setSelectedCategory(currentCategory);
    setSearchCategory(currentCategory?.name);
  };
  const onPaymentStatusChange = (currentStatus) => {
    if (currentStatus === null) {
      setSearchPaymentStatus("");
      setSelectedPaymentStatus({});
    } else {
      setSelectedPaymentStatus(currentStatus);
      setSearchPaymentStatus(currentStatus?.name);
    }
  };

  const onPaymentTypeChange = (currentType) => {
    if (currentType === null) {
      setSearchPaymentType("");
      setSelectedPaymentType({});
    } else {
      setSelectedPaymentType(currentType);
      setSearchPaymentType(currentType?.name);
    }
  };

  const onPaymentSourceChange = (currentSource) => {
    if (currentSource === null) {
      setSearchPaymentSource("");
      setSelectedPaymentSource({});
    } else {
      setSelectedPaymentSource(currentSource);
      setSearchPaymentSource(currentSource?.name);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (contestantDropdownRef.current &&
          !contestantDropdownRef.current.contains(event.target)) ||
        (paymentStatusDropdownRef.current &&
          !paymentStatusDropdownRef.current.contains(event.target)) ||
        (paymentTypeDropdownRef.current &&
          !paymentTypeDropdownRef.current.contains(event.target)) ||
        (paymentSourceDropdownRef.current &&
          !paymentSourceDropdownRef.current.contains(event.target)) ||
        (categoryDropdownRef.current &&
          !categoryDropdownRef.current.contains(event.target))
      ) {
        setIsContestantActive(false);
        setIsCategoryActive(false);
        setIsPaymentStatusActive(false);
        setIsPaymentTypeActive(false);
        setIsPaymentSourceActive(false);
        if (selectedContestant) {
          setSearchContestant(selectedContestant.stage);
        }
        if (selectedPaymentStatus) {
          setSearchPaymentStatus(selectedPaymentStatus.name);
        }
        if (selectedPaymentType) {
          setSearchPaymentType(selectedPaymentType.name);
        }
        if (selectedPaymentSource) {
          setSearchPaymentSource(selectedPaymentSource.name);
        }
        if (selectedCategory) {
          setSearchCategory(selectedCategory.name);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    searchContestant,
    searchCategory,
    searchPaymentStatus,
    searchPaymentType,
    searchPaymentSource,
  ]);

  const filteredContestantList = contestants?.filter((item) => {
    if (searchContestant === "") {
      return item;
    } else {
      return item?.stage
        ?.toLowerCase()
        .includes(searchContestant?.toLowerCase());
    }
  });

  const filteredPaymentStatusesList = statuses?.filter((item) => {
    if (searchPaymentStatus === "") {
      return item;
    } else {
      return item?.name?.includes(searchPaymentStatus?.toLowerCase());
    }
  });

  const filteredPaymentTypesList = paymentTypes?.filter((item) => {
    if (searchPaymentType === "") {
      return item;
    } else {
      return item?.name?.includes(searchPaymentType?.toLowerCase());
    }
  });

  const filteredPaymentSourcesList = sources?.filter((item) => {
    if (searchPaymentSource === "") {
      return item;
    } else {
      return item?.name?.includes(searchPaymentSource?.toLowerCase());
    }
  });

  const filteredCategoryList = categories?.filter((item) => {
    if (searchCategory === "") {
      return item;
    } else {
      return item?.name?.toLowerCase().includes(searchCategory?.toLowerCase());
    }
  });
  const handleSearchTransaction = (e) => {
    e.preventDefault();

    const { to, from, ref } = userInput;
    setPageNumber(1);
    setUserInput((prev) => ({ ...prev, pageSearch: 1 }));

    const currentDate = new Date().toISOString().split("T")[0];
    if (ref !== "") {
      setReference(ref);
    }
    if (from !== "") {
      setFromValue(from);
    }
    if (to !== "") {
      setToValue(to ? to : currentDate);
    }
    if (selectedContestant) {
      setContID(selectedContestant.id);
    } else {
      setContID("");
    }
    if (selectedCategory) {
      setCatID(selectedCategory.id);
    } else {
      setCatID("");
    }
    if (selectedPaymentStatus) {
      setPaymentStatus(selectedPaymentStatus.name);
    }
    if (selectedPaymentType) {
      setPaymentType(selectedPaymentType.name);
    }
    if (selectedPaymentSource) {
      setPaymentSource(selectedPaymentSource.name);
    }
  };

  const handleClearFields = () => {
    setUserInput(initialUserInput);
    setSearchContestant("");
    setSearchCategory("");
    setSearchPaymentSource("");
    setSearchPaymentStatus("");
    setSearchPaymentType("");
    setReference("");
    setSelectedContestant({});
    setSelectedCategory({});
    setSelectedPaymentStatus({});
    setSelectedPaymentSource({});
    setSelectedPaymentType({});
    setPageNumber(1);
    setFromValue(currentDate);
    setToValue(currentDate);
    setContID("");
    setCatID("");
    setPaymentSource("");
    setPaymentType("");
    setPaymentStatus("");
    setPaymentStatus("");
  };

  function searchPageNumber(event) {
    event.preventDefault();
    const { pageSearch } = userInput;

    if (pageSearch <= 0 || pageSearch > transactions?.transactions?.last_page) {
      toast.error("Page Number is Invalid", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setPageNumber(pageSearch);
    }
  }

  const handleSeasonNavigate = () => {
    if (code === "sdg") {
      navigate(
        `/reality-show/${code}/${season_code}/categories?show=${activeShow}&season=${season}`
      );
    } else {
      navigate(
        `/reality-show/${code}/${season_code}?show=${activeShow}&season=${season}`
      );
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />

      <div className="container body-width">
        <div className="col-md-12">
          <div className="indication1">
            <div
              className="d-flex justify-content-between cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <div className="">
                <img src="/assets/img/home.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Home</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate("/reality-shows")}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Reality Shows</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() =>
                navigate(`/reality-show/${code}?show=${activeShow}`)
              }
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">{convertSnakeCaseToTitleCase(code)}</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={handleSeasonNavigate}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1 text-capitalize">{convertSnakeCaseToTitleCase(season_code)}</h4>
              </div>
            </div>
            {code !== "sdg" && (
              <div className="d-flex justify-content-between ms-2">
                <div className="">
                  <img
                    src="/assets/img/arr-right.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1 disabled-color text-capitalize">
                    Contestants
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="row  mt-5 justify-content-between align-items-center">
            <div className="col-md-3">
              <div className="row title-font mt-5 mt-md-0">
                <h4 className="text-capitalize">Transactions</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row d-md-flex justify-content-between mt-4">
            <div className="col-md-4 col-12">
              <div className="card card-t-h">
                <h4>Total Transaction Count</h4>
                <h6 className="text-secondary">
                  {transactions &&
                  transactions.total_transaction_count !== undefined
                    ? parseInt(
                        transactions.total_transaction_count
                      ).toLocaleString()
                    : "0"}
                </h6>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="card card-t-h">
                <h4>Total Success</h4>
                <h6 className="text-success">
                  {transactions &&
                  transactions.total_transaction_count !== undefined
                    ? parseFloat(transactions?.total_success).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : "0.00"}
                </h6>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="card card-t-h">
                <h4>Total Fails</h4>
                <h6 className="text-danger">
                  {transactions &&
                  transactions.total_transaction_count !== undefined
                    ? parseFloat(transactions?.total_failed).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : "0.00"}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <form action="" onSubmit={handleSearchTransaction}>
          <div className="col-md-12 col-12 mt-top-2">
            <div className="row d-flex">
              <div className="col-md-2 col-12 mb-4 mb-md-0">
                <label htmlFor="from" className="label-width">
                  From{" "}
                  <input
                    type="date"
                    id="from"
                    name="from"
                    className="form-control"
                    value={userInput.from}
                    onChange={(e) => handleUserInput(e)}
                    required={userInput.to != ""}
                  />
                </label>
              </div>
              <div className="col-md-2 col-12 mb-4 mb-md-0">
                <label htmlFor="to" className="label-width">
                  To{" "}
                  <input
                    type="date"
                    className="form-control"
                    id="to"
                    name="to"
                    value={userInput.to}
                    onChange={(e) => handleUserInput(e)}
                    min={userInput.from}
                  />
                </label>
              </div>
              <div className="col-md-2 col-12 mb-4 mb-md-0">
                <label htmlFor="" className="label-width">
                  Payment Type
                  <div className="region-dropdown-cover w-100">
                    <div
                      className="dropdown"
                      onClick={() => setIsPaymentTypeActive((prev) => !prev)}
                    >
                      <div className="dropdown-btn dropdown-btn-mobile">
                        <input
                          type="text"
                          placeholder="-- Select --"
                          className="form-control cover-b"
                          value={searchPaymentType}
                          onChange={(event) =>
                            setSearchPaymentType(event.target.value)
                          }
                          onFocus={() => setSearchPaymentType("")}
                        />
                        <img
                          src="/assets/img/arrowDown1.svg"
                          alt=""
                          className="ms-2 arrow-w"
                        />
                      </div>
                      {isPaymentTypeActive && (
                        <div
                          className="dropdown-content-cover dropdown-btn-mobile1"
                          ref={paymentTypeDropdownRef}
                        >
                          <div className="dropdown-content dropdown-content-mobile">
                            {filteredPaymentTypesList?.length > 0 ? (
                              <>
                                <div
                                  className="dropdown-item"
                                  name="no_value"
                                  onClick={() => {
                                    onPaymentTypeChange(null);
                                  }}
                                >
                                  -- Select --
                                </div>
                                {filteredPaymentTypesList?.map(
                                  (paymentType, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="dropdown-item"
                                        onClick={() =>
                                          onPaymentTypeChange(paymentType)
                                        }
                                      >
                                        {paymentType?.value}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <div className="no-data-found">
                                <h4>No Type Found</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
              <div className="col-md-2 col-12 mb-4 mb-md-0">
                <label htmlFor="" className="label-width">
                  Source
                  <div className="region-dropdown-cover w-100">
                    <div
                      className="dropdown"
                      onClick={() => setIsPaymentSourceActive((prev) => !prev)}
                    >
                      <div className="dropdown-btn dropdown-btn-mobile">
                        <input
                          type="text"
                          placeholder="-- Select --"
                          className="form-control cover-b"
                          value={searchPaymentSource}
                          onChange={(event) =>
                            setSearchPaymentSource(event.target.value)
                          }
                          onFocus={() => setSearchPaymentSource("")}
                        />
                        <img
                          src="/assets/img/arrowDown1.svg"
                          alt=""
                          className="ms-2 arrow-w"
                        />
                      </div>
                      {isPaymentSourceActive && (
                        <div
                          className="dropdown-content-cover dropdown-btn-mobile1"
                          ref={paymentSourceDropdownRef}
                        >
                          <div className="dropdown-content dropdown-content-mobile">
                            {filteredPaymentSourcesList?.length > 0 ? (
                              <>
                                <div
                                  className="dropdown-item"
                                  name="no_value"
                                  onClick={() => {
                                    onPaymentSourceChange(null);
                                  }}
                                >
                                  -- Select --
                                </div>
                                {filteredPaymentSourcesList?.map(
                                  (paymentSource, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="dropdown-item"
                                        onClick={() =>
                                          onPaymentSourceChange(paymentSource)
                                        }
                                      >
                                        {paymentSource?.value}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <div className="no-data-found">
                                <h4>No Source Found</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
              <div className="col-md-2 col-12 mb-4 mb-md-0">
                <label htmlFor="" className="label-width">
                  Status
                  <div className="region-dropdown-cover w-100">
                    <div
                      className="dropdown"
                      onClick={() => setIsPaymentStatusActive((prev) => !prev)}
                    >
                      <div className="dropdown-btn dropdown-btn-mobile">
                        <input
                          type="text"
                          placeholder="-- Select --"
                          className="form-control cover-b"
                          value={searchPaymentStatus}
                          onChange={(event) =>
                            setSearchPaymentStatus(event.target.value)
                          }
                          onFocus={() => setSearchPaymentStatus("")}
                        />
                        <img
                          src="/assets/img/arrowDown1.svg"
                          alt=""
                          className="ms-2 arrow-w"
                        />
                      </div>
                      {isPaymentStatusActive && (
                        <div
                          className="dropdown-content-cover dropdown-btn-mobile1"
                          ref={paymentStatusDropdownRef}
                        >
                          <div className="dropdown-content dropdown-content-mobile">
                            {filteredPaymentStatusesList?.length > 0 ? (
                              <>
                                <div
                                  className="dropdown-item"
                                  name="no_value"
                                  onClick={() => {
                                    onPaymentStatusChange(null);
                                  }}
                                >
                                  -- Select --
                                </div>
                                {filteredPaymentStatusesList?.map(
                                  (status, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="dropdown-item"
                                        onClick={() =>
                                          onPaymentStatusChange(status)
                                        }
                                      >
                                        {status?.value}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <div className="no-data-found">
                                <h4>No Status Found</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
              <div className="col-md-2 col-12">
                <label htmlFor="" className="label-width">
                  Contestant
                  <div className="region-dropdown-cover w-100">
                    <div
                      className="dropdown"
                      onClick={() => setIsContestantActive((prev) => !prev)}
                    >
                      <div className="dropdown-btn dropdown-btn-mobile">
                        <input
                          type="text"
                          placeholder="-- Select --"
                          className="form-control cover-b"
                          value={searchContestant}
                          onChange={(event) =>
                            setSearchContestant(event.target.value)
                          }
                          onFocus={() => setSearchContestant("")}
                        />
                        <img
                          src="/assets/img/arrowDown1.svg"
                          alt=""
                          className="ms-2 arrow-w"
                        />
                      </div>
                      {isContestantActive && (
                        <div
                          className="dropdown-content-cover dropdown-btn-mobile1"
                          ref={contestantDropdownRef}
                        >
                          <div className="dropdown-content dropdown-content-mobile">
                            {}
                            {filteredContestantList?.length > 0 ? (
                              <>
                                <div
                                  className="dropdown-item"
                                  name="no_value"
                                  onClick={() => {
                                    onContestantChange(null);
                                  }}
                                >
                                  -- Select --
                                </div>
                                {filteredContestantList?.map(
                                  (contestant, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="dropdown-item"
                                        onClick={() =>
                                          onContestantChange(contestant)
                                        }
                                      >
                                        {contestant?.stage}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <div className="no-data-found">
                                <h4>No Constestant Found</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
              {code === "sdg" && (
                <div className="col-md-2 mt-4 col-12">
                  <label htmlFor="" className="label-width">
                    Category
                    <div className="region-dropdown-cover w-100">
                      <div
                        className="dropdown"
                        onClick={() => setIsCategoryActive((prev) => !prev)}
                      >
                        <div className="dropdown-btn dropdown-btn-mobile">
                          <input
                            type="text"
                            placeholder="-- Select --"
                            className="form-control cover-b"
                            value={searchCategory}
                            onChange={(event) =>
                              setSearchCategory(event.target.value)
                            }
                            onFocus={() => setSearchCategory("")}
                          />
                          <img
                            src="/assets/img/arrowDown1.svg"
                            alt=""
                            className="ms-2 arrow-w"
                          />
                        </div>
                        {isCategoryActive && (
                          <div
                            className="dropdown-content-cover dropdown-btn-mobile1"
                            ref={categoryDropdownRef}
                          >
                            <div className="dropdown-content dropdown-content-mobile">
                              {}
                              {filteredCategoryList?.length > 0 ? (
                                <>
                                  <div
                                    className="dropdown-item"
                                    name="no_value"
                                    onClick={() => {
                                      onCategoryChange(null);
                                    }}
                                  >
                                    -- Select --
                                  </div>
                                  {filteredCategoryList?.map((category, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="dropdown-item"
                                        onClick={() =>
                                          onCategoryChange(category)
                                        }
                                      >
                                        {category?.name}
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="no-data-found">
                                  <h4>No Category Found</h4>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              )}
              <div className="col-md-2 mt-4 col-12">
                <label htmlFor="ref" className="label-width">
                  Reference
                  <input
                    type="text"
                    placeholder="Type reference"
                    className="form-control"
                    name="ref"
                    value={userInput.ref}
                    onChange={(e) => handleUserInput(e)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12 mt-top-2">
            <div className="row d-flex justify-content-between">
              <div className="col-md-4"></div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 col-6">
                    <button
                      type="submit"
                      className="btn btn-success form-control mt-4 mt-md-0"
                      isTransactionsLoading={isFetching}
                      disabled={
                        userInput?.to === "" &&
                        userInput?.from === "" &&
                        !selectedContestant?.id &&
                        !selectedPaymentStatus?.name &&
                        !selectedPaymentSource?.name &&
                        !selectedPaymentType?.name
                      }
                    >
                      search
                    </button>
                  </div>
                  <div className="col-md-4 col-6">
                    <button
                      disabled={isExportReload}
                      type="button"
                      className={
                        isExportReload
                          ? "form-control btn btn-dark mt-4 mt-md-0 disabled"
                          : "form-control btn btn-dark mt-4 mt-md-0"
                      }
                      onClick={handleExportTransactions}
                    >
                      {isExportReload ? "Exporting..." : "Export"}
                    </button>
                  </div>
                  <div className="col-md-4 col-12 d-flex align-items-end mt-4 mt-md-0">
                    <button
                      className="btn btn-out-dark w-100"
                      type="button"
                      title="clear fliters"
                      onClick={handleClearFields}
                      isLoading={isFetching}
                      disabled={
                        userInput?.to === "" &&
                        userInput?.from === "" &&
                        !selectedContestant?.id &&
                        !selectedPaymentStatus?.name &&
                        !selectedPaymentSource?.name &&
                        !selectedPaymentType?.name
                      }
                    >
                      Clear Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="col-12">
          <div className="card card-table-height-1 ccc-he mt-4">
            <div className="card-body booking_card">
              <div className="table-responsive">
                <table className="datatable table table-stripped table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Contestant</th>
                      {code === "sdg" && <th>Category</th>}
                      <th>Amount</th>
                      <th>Vote No.</th>
                      <th>Reference</th>
                      <th>Payment Type</th>
                      <th>Source</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isTransactionsLoading && !isFetching ? (
                      <>
                        {transactions?.transactions?.data?.length === 0 ? (
                          <div className="no-data">
                            <h1> No Transaction Available</h1>
                          </div>
                        ) : (
                          transactions?.transactions?.data?.map(
                            (transaction, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-capitalize">
                                    {transaction?.cont?.person?.stage_name}
                                  </td>
                                  {code === "sdg" && (
                                    <td>{transaction?.cont?.team?.name}</td>
                                  )}
                                  <td>{transaction?.vote_price}</td>
                                  <td>{transaction?.vote_number}</td>
                                  <td>{transaction?.vote_reference}</td>
                                  <td>{transaction?.payment_type || "N/A"} </td>
                                  <td>{transaction?.source}</td>
                                  <td>
                                    <div
                                      className={
                                        transaction?.status?.toLowerCase() ===
                                        "complete"
                                          ? "bg-success-light"
                                          : transaction?.status?.toLowerCase() ===
                                            "pending"
                                          ? "bg-warning-light"
                                          : "bg-danger-light"
                                      }
                                    >
                                      {transaction?.status}
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        )}
                      </>
                    ) : (
                      <div className="no-data">
                        <LoadingButton />
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {!isTransactionsLoading && !isFetching && (
              <>
                {transactions?.transactions?.total > 20 && (
                  <div className="page-section">
                    <div className="d-md-flex w-100 justify-content-between">
                      <form action="">
                        <div className="d-flex">
                          <div className="input-cover">
                            <input
                              type="number"
                              name="pageSearch"
                              className="form-control"
                              value={userInput.pageSearch}
                              onChange={(e) => handleUserInput(e)}
                              required
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-dark px-2 ms-2 btn-f"
                            onClick={searchPageNumber}
                            isLoading={isFetching}
                          >
                            Go To Page
                          </button>
                        </div>
                      </form>
                      <div className="d-flex align-items-center me-4 float-end mt-5 mt-md-4">
                        <button
                          className="arrow-cover"
                          type="button"
                          onClick={() => {
                            setPageNumber((prev) => Math.max(prev - 1, 1));
                            setUserInput((prev) => ({
                              ...prev,
                              pageSearch: Math.max(prev.pageSearch - 1, 1),
                            }));
                          }}
                          isTransactionsLoading={isFetching}
                        >
                          <img src="/assets/img/arrow-left.svg" alt="" />
                        </button>
                        <h4 className="mx-3  page-font">
                          Page <span>{pageNumber}</span> to{" "}
                          <span>{transactions?.transactions?.last_page}</span>
                        </h4>
                        <button
                          type="button"
                          className="arrow-cover"
                          onClick={() => {
                            setPageNumber((prev) =>
                              Math.min(
                                prev + 1,
                                transactions?.transactions?.last_page
                              )
                            );
                            setUserInput((prev) => ({
                              ...prev,
                              pageSearch: Math.min(
                                prev.pageSearch + 1,
                                transactions?.transactions?.last_page
                              ),
                            }));
                          }}
                          isTransactionsLoading={isFetching}
                        >
                          <img src="/assets/img/arrow-right1.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default Transactions;
