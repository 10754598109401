import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useAddPicturesMutation } from "../../../app/apiSlice";
import { useLocation, useParams } from "react-router-dom";

const AddPicturesModal = ({
  setShowUploadPicsModal,
  showUploadPicsModal,
  refetchPictures,
  setIsPicturesReload,
  contestants,
}) => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    arrows: true,
  };
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState([]);
  const initialUserInput = {
    img_title: "",
    contestant: "",
  };
  const [userInput, setUserInput] = useState(initialUserInput);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeGalleryShow = queryParams.get("show");
  const selectedGallerySeason = queryParams.get("season");
  const activeAlbum = queryParams.get("album");
 

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const sliderRef = useRef(null);
  const [isloading, setLoading] = useState(false);

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      setImages(file);
      return URL.createObjectURL(file);
    });
    setImages(selectedFiles);
    setSelectedImages((previousImages) => previousImages?.concat(imagesArray));
  };

  const [addPictures, { data, isSuccess, isError, error, isLoading }] =
    useAddPicturesMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("img_title", userInput?.img_title);
    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      formData.append("images[]", file);
    }
    formData.append("img_type", "gallery");
    formData.append("show_id", activeGalleryShow);
    formData.append("season_id", selectedGallerySeason);
    formData.append("album_id", activeAlbum);
    formData.append("cont_id", userInput?.contestant);
    setLoading(true);

    if (selectedImages.length === 0) {
      toast.error("Image is required", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        await addPictures(formData);
      } catch (error) {
        console.error("Error adding pictures:", error);
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowUploadPicsModal(false);
      setSelectedImages([]);
      setUserInput(initialUserInput);
      setIsPicturesReload(true);
      refetchPictures().then(() => {
        setIsPicturesReload(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  const closeModal = () => {
    setShowUploadPicsModal(false);
    setSelectedImages([]);
    setUserInput(initialUserInput);
  };
  return (
    <>
      <Modal
        show={showUploadPicsModal}
        onHide={closeModal}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Upload New Pictures</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="upload-img-cover mt-4 mt-md-0">
              <div
                className={
                  selectedImages?.length >= 1 ? "img-cont" : "img-cont-1"
                }
              >
                <input
                  type="file"
                  id="uploadBtn"
                  onChange={(e) => {
                    onSelectFile(e);
                  }}
                  multiple
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                />
                {selectedImages?.length >= 1 ? (
                  <Slider ref={sliderRef} {...settings}>
                    {selectedImages &&
                      selectedImages.map((image, index) => {
                        return (
                          <div className="upload-img-cover1">
                            <img
                              src={image}
                              alt=""
                              className="upload-btn-img2"
                              key={image}
                            />
                            <input
                              type="button"
                              onClick={() =>
                                setSelectedImages(
                                  selectedImages.filter((e) => e !== image)
                                )
                              }
                              className="btn bg-danger-light delete-img"
                              value="delete"
                            />
                          </div>
                        );
                      })}
                  </Slider>
                ) : (
                  <label htmlFor="uploadBtn">
                    <div className="upload-cover cursor-pointer">
                      <div>
                        <img src="/assets/img/upload.svg" alt="" />
                      </div>
                      <div>
                        <h4>Click to upload image(s)</h4>
                      </div>

                      <div className="browse-cover d-flex">
                        <div className="my-auto">
                          <img
                            src="/assets/img/browse.svg"
                            alt=""
                            className="browse-icon me-2 my-auto"
                          />
                        </div>
                        <h4 className="my-auto"> Browse file</h4>
                      </div>
                    </div>
                  </label>
                )}
              </div>
            </div>

            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="img_title">
                  <h4>Image Title</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="text"
                  name="img_title"
                  id="img_title"
                  value={userInput.img_title}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter image title"
                  required
                />
              </div>
              <div className="d-block mt-4">
                <div className="email-font">
                  <label htmlFor="contestant">
                    <h4>Contestant</h4>
                  </label>
                </div>
                <div className="text-box-cover">
                  <select
                    name="contestant"
                    id="contestant"
                    className="select-box-width"
                    value={userInput?.contestant?.id}
                    onChange={handleUserInput}
                    required
                  >
                    <option value="">-- select --</option>
                    {contestants?.map((ele, index) => (
                      <option key={index} value={ele?.id}>
                        {ele?.stage}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/arrow-down.svg"
                    alt=""
                    className="select-box-img"
                  />
                </div>
              </div>
            </div>

            <div classame="d-block">
              {!isLoading && (
                <div className="create-btn-cover mt-4">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Upload Image(s)"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover mt-4">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Uploading..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddPicturesModal;
