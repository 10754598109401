import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useAddContestantMutation,
  useGetAllCategoriesQuery,
  useGetCategoryQuery,
} from "../../../app/apiSlice";
import { useLocation, useParams } from "react-router-dom";

const AddContestantModal = ({
  setContestantModal,
  contestantModal,
  setIsReloadContestants,
  refetchContestants,
  team_name,
  teamID,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [categorySelected, setCategorySelected] = useState([]);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");

  const [selectedImage, setSelectedImage] = useState(null);
  const [socialAccounts, setSocialAccounts] = useState([
    { socialType: "", socialHandle: "" },
  ]);
  const [phoneNumbers, setPhoneNumbers] = useState([
    { network: "", number: "" },
  ]);

  const inputHeightRef = useRef(null);
  const inputWeightRef = useRef(null);
  const inputAgeRef = useRef(null);
  const inputHubtelIDRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      if (
        document.activeElement === inputHeightRef.current ||
        document.activeElement === inputWeightRef.current ||
        document.activeElement === inputAgeRef.current ||
        document.activeElement === inputHubtelIDRef.current
      ) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (document.activeElement === inputHeightRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
      if (document.activeElement === inputWeightRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
      if (document.activeElement === inputAgeRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
      if (document.activeElement === inputHubtelIDRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { data: category } = useGetCategoryQuery(
    {
      teamID: teamID,
    },
    {
      skip: !teamID || teamID === null || teamID === "",
    }
  );

  useEffect(() => {
    if (category) {
      setCategorySelected(category?.data?.team);
    }
  }, [category]);

  const initialUserInput = {
    first_name: "",
    last_name: "",
    other_names: "",
    stage_name: "",
    height: "",
    weight: "",
    gender: "",
    town_name: "",
    school_name: "",
    region: "",
    residence: "",
    social_type: "",
    social_handle: "",
    bio: "",
    hobbies: "",
    age: "",
    best_performer: 1,
    no_of_apperances: "",
    mobile_network: "",
    telephone: "",
    hubtel_id: "",
  };
  const [userInput, setUserInput] = useState(initialUserInput);
  const [tags, setTags] = useState([]);
  let spacebarCount = 0;
  const addTags = (e) => {
    if (e.key === " ") {
      spacebarCount++;

      if (spacebarCount === 1) {
        setTimeout(() => {
          spacebarCount = 0;
        }, 300);
      } else if (spacebarCount === 2) {
        setTags([...tags, e.target.value]);
        e.target.value = "";

        spacebarCount = 0;
      }
    }
  };
  const removeTag = (i) => {
    setTags(tags.filter((_, index) => index != i));
  };
  const handleAddSocialAcc = () => {
    const updatedSocialAccounts = [...socialAccounts];
    let hasEmptyField = false;

    updatedSocialAccounts.forEach((social, index) => {
      if (social.socialType === "") {
        updatedSocialAccounts[index].errorType = "required";
        hasEmptyField = true;
      } else {
        updatedSocialAccounts[index].errorType = "";
      }

      if (social.socialHandle === "") {
        updatedSocialAccounts[index].errorHandle = "required";
        hasEmptyField = true;
      } else {
        updatedSocialAccounts[index].errorHandle = "";
      }
    });

    if (!hasEmptyField) {
      updatedSocialAccounts.push({
        socialType: "",
        socialHandle: "",
        errorType: "",
        errorHandle: "",
      });
    }

    setSocialAccounts(updatedSocialAccounts);
  };

  const handleAddPhoneNumber = () => {
    const updatedPhoneNumbers = [...phoneNumbers];
    let hasEmptyField = false;

    updatedPhoneNumbers.forEach((phone, index) => {
      if (phone.network === "") {
        updatedPhoneNumbers[index].errorType = "required";
        hasEmptyField = true;
      } else {
        updatedPhoneNumbers[index].errorType = "";
      }

      if (phone.number === "") {
        updatedPhoneNumbers[index].errorHandle = "required";
        hasEmptyField = true;
      } else {
        updatedPhoneNumbers[index].errorHandle = "";
      }
    });

    if (!hasEmptyField) {
      updatedPhoneNumbers.push({
        network: "",
        number: "",
        errorType: "",
        errorHandle: "",
      });
    }

    setPhoneNumbers(updatedPhoneNumbers);
  };

  const handleSocialsDelete = (e, i) => {
    const list = [...socialAccounts];
    list.splice(i, 1);
    setSocialAccounts(list);
  };
  const handleNumbersDelete = (e, i) => {
    const list = [...phoneNumbers];
    list.splice(i, 1);
    setPhoneNumbers(list);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...socialAccounts];
    list[index][name] = value;
    setSocialAccounts(list);
  };

  const handlePhoneChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...phoneNumbers];
    list[index][name] = value;
    setPhoneNumbers(list);
  };

  const handleUserInput = (e) => {
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [addContestant, { data, isSuccess, isError, error, isLoading }] =
    useAddContestantMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("first_name", userInput.first_name);
    formData.append("region", userInput.region);
    formData.append("best_performer", parseInt(userInput.best_performer));
    formData.append("residence", userInput.residence);
    formData.append("no_of_apperances", userInput.no_of_apperances);
    formData.append("stage_name", userInput.stage_name);
    formData.append("school_name", userInput.school_name);
    userInput.height && formData.append("height", parseFloat(userInput.height));
    userInput.weight && formData.append("weight", parseFloat(userInput.weight));
    formData.append("last_name", userInput.last_name);
    formData.append("other_names", userInput.other_names);
    userInput?.age && formData.append("age", parseInt(userInput.age));
    formData.append("gender", userInput.gender);
    formData.append("town_name", userInput.town_name);
    formData.append("show_id", activeShow);
    userInput?.hubtel_id &&
      formData.append("hubtel_id", parseInt(userInput?.hubtel_id));
    formData.append("season_id", season);
    formData.append("hobbies", tags.join(", "));
    categorySelected?.id && formData.append("team_id", categorySelected?.id);

    const socialTypeArray = socialAccounts.map((social) => social.socialType);
    const socialHandleArray = socialAccounts.map(
      (social) => social.socialHandle
    );

    const socials = socialTypeArray.map((item1, index) => ({
      social_type: item1,
      social_handle: socialHandleArray[index],
    }));
    socials.forEach((social, index) => {
      const socialKeys = Object.keys(social);
      socialKeys.forEach((key) => {
        socialAccounts[0]?.socialType !== "" ||
          (socialAccounts[0]?.socialHandle !== "" &&
            formData.append(`social_accounts[${index}][${key}]`, social[key]));
      });
    });
    const networkArray = phoneNumbers.map((network) => network.network);
    const telephoneArray = phoneNumbers.map((number) => number.number);
    const telephones = networkArray.map((item1, index) => ({
      network: item1,
      number: telephoneArray[index],
    }));
    telephones.forEach((telephone, index) => {
      const telephoneKeys = Object.keys(telephone);
      telephoneKeys.forEach((key) => {
        phoneNumbers[0]?.network !== "" ||
          (phoneNumbers[0]?.number !== "" &&
            formData.append(`phone_numbers[${index}][${key}]`, telephone[key]));
      });
    });
    const picturesData = [
      {
        img_url: selectedImage,
        img_desc: "this is the profile image",
        img_title: "Profile Pic",
        img_type: "profile_pic",
      },
    ];
    picturesData.forEach((picture, index) => {
      const pictureKeys = Object.keys(picture);
      pictureKeys.forEach((key) => {
        formData.append(`pictures[${index}][${key}]`, picture[key]);
      });
    });
    formData.append("biography", userInput?.bio);
    if (selectedImage < 1) {
      toast.error("Contestant image is required", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        await addContestant(formData);
      } catch (error) {
        console.error("Error adding shows:", error);
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setContestantModal(false);
      setUserInput(initialUserInput);
      setSelectedImage(null);
      setTags([]);
      setSocialAccounts([{ socialType: "", socialHandle: "" }]);
      setPhoneNumbers([{ network: "", number: "" }]);
      setIsReloadContestants(true);
      refetchContestants().then(() => {
        setIsReloadContestants(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log("error", error?.data?.message);
      let errorMessages = error?.data?.message;
      let errorMessageString = "";

      for (const key in errorMessages) {
        if (errorMessages.hasOwnProperty(key)) {
          errorMessageString += errorMessages[key].join(", ") + ", ";
        }
      }
      errorMessageString = errorMessageString.slice(0, -2);
      toast.error(errorMessageString, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  useEffect(() => {
    const capitalizeWord = (word) =>
      word.charAt(0).toUpperCase() + word.slice(1);

    let fullName = "";
    if (userInput.first_name || userInput.other_names || userInput.last_name) {
      fullName = `${capitalizeWord(
        userInput.first_name || ""
      )} ${capitalizeWord(userInput.other_names || "")} ${capitalizeWord(
        userInput.last_name || ""
      )}`;
    }

    let regionName = capitalizeWord(userInput.region || "");
    let residence = capitalizeWord(userInput.residence || "");
    let townName = capitalizeWord(userInput.town_name || "");
    let stageName = capitalizeWord(userInput.stage_name || "");
    let age = userInput.age || "";

    let bio = "";

    if (fullName && regionName && residence && stageName && age && townName) {
      bio = `I am ${fullName} with stage name ${stageName}. I am ${age} years old. I come from ${townName} in ${regionName}, but I reside in ${residence}.`;
    } else if (fullName && regionName && residence && age && stageName) {
      bio = `I am ${fullName} with stage name ${stageName}. I am ${age} years old. I come from the ${regionName}, but I reside in ${residence}.`;
    } else if (fullName && age && regionName && townName && stageName) {
      bio = `I am ${fullName} with stage name ${stageName}. I am ${age} years old.  I come from ${townName} in ${regionName}.`;
    } else if (fullName && age && regionName && stageName) {
      bio = `I am ${fullName} with stage name ${stageName}. I am ${age} years old.  I come from the ${regionName}.`;
    }

    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      bio,
    }));
  }, [
    userInput.first_name,
    userInput.last_name,
    userInput.other_names,
    userInput.school_name,
    userInput.town_name,
    userInput.region,
    userInput.residence,
    userInput.age,
    userInput.stage_name,
  ]);

  return (
    <>
      <Modal
        show={contestantModal}
        onHide={() => setContestantModal(false)}
        dialogClassName="contestant-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Add New Contestant</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="col-md-12 mt-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="d-block">
                    <div className="email-font mrgin-show">
                      <label htmlFor="first_name">
                        <h4>First Name</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <img
                        src="/assets/img/profile.svg"
                        alt=""
                        className="text-box-img"
                      />
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={userInput.first_name}
                        onChange={handleUserInput}
                        className="text-box-width"
                        placeholder="Enter First Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block">
                    <div className="email-font mt-4 mt-md-0">
                      <label htmlFor="last_name">
                        <h4>Last Name</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <img
                        src="/assets/img/profile.svg"
                        alt=""
                        className="text-box-img"
                      />
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={userInput.last_name}
                        onChange={handleUserInput}
                        className="text-box-width"
                        placeholder="Enter Last Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="other_names">
                        <h4>Other Names</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <img
                        src="/assets/img/profile.svg"
                        alt=""
                        className="text-box-img"
                      />
                      <input
                        type="text"
                        name="other_names"
                        id="other_names"
                        value={userInput.other_names}
                        onChange={handleUserInput}
                        className="text-box-width"
                        placeholder="Enter Other Names"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="stage_name">
                        <h4>Stage Name</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <img
                        src="/assets/img/profile.svg"
                        alt=""
                        className="text-box-img"
                      />
                      <input
                        type="text"
                        name="stage_name"
                        id="stage_name"
                        value={userInput.stage_name}
                        onChange={handleUserInput}
                        className="text-box-width"
                        required
                        placeholder="Enter Stage Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="height">
                        <h4>Height</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <input
                        type="number"
                        name="height"
                        id="height"
                        value={userInput.height}
                        onChange={handleUserInput}
                        className="text-box-width1"
                        placeholder="Height"
                        ref={inputHeightRef}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="Weight">
                        <h4>Weight</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <input
                        type="number"
                        name="weight"
                        id="weight"
                        value={userInput.weight}
                        onChange={handleUserInput}
                        className="text-box-width1"
                        placeholder="Weight"
                        ref={inputWeightRef}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="gender">
                        <h4>Gender</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <select
                        name="gender"
                        id="gender"
                        className="select-box-width"
                        value={userInput.gender}
                        onChange={handleUserInput}
                      >
                        <option value="">-- Select --</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      <img
                        src="/assets/img/arrow-down.svg"
                        alt=""
                        className="select-box-img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="age">
                        <h4>Age</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <input
                        type="number"
                        name="age"
                        id="age"
                        value={userInput.age}
                        onChange={handleUserInput}
                        className="text-box-width1"
                        ref={inputAgeRef}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="hubtel_id">
                        <h4>Hubtel ID</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <input
                        type="number"
                        name="hubtel_id"
                        id="hubtel_id"
                        value={userInput.hubtel_id}
                        onChange={handleUserInput}
                        className="text-box-width1"
                        ref={inputHubtelIDRef}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="town_name">
                        <h4>Hometown</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <input
                        type="text"
                        name="town_name"
                        id="town_name"
                        value={userInput.town_name}
                        onChange={handleUserInput}
                        className="text-box-width1"
                        placeholder="Enter Town's Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="region">
                        <h4>Region</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <select
                        name="region"
                        id="region"
                        className="select-box-width"
                        value={userInput.region}
                        onChange={handleUserInput}
                      >
                        <option value="">-- Select --</option>
                        <option value="Asanti">Ashanti</option>
                        <option value="Bono">Bono</option>
                        <option value="Bono East">Bono East</option>
                        <option value="Ahafo">Ahafo</option>
                        <option value="Central">Central</option>
                        <option value="Eastern">Eastern</option>
                        <option value="Greater Accra">Greater Accra</option>
                        <option value="Northern">Northern</option>
                        <option value="Savannah">Savannah</option>
                        <option value="North East">North East</option>
                        <option value="Upper East">Upper East</option>
                        <option value="Upper West">Upper West</option>
                        <option value="Volta">Volta</option>
                        <option value="Oti">Oti</option>
                        <option value="Western">Western</option>
                        <option value="Western North">Western North</option>
                      </select>
                      <img
                        src="/assets/img/arrow-down.svg"
                        alt=""
                        className="select-box-img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="school_name">
                        <h4>Last School Attended</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <input
                        type="text"
                        name="school_name"
                        id="school_name"
                        value={userInput.school_name}
                        onChange={handleUserInput}
                        className="text-box-width1"
                        placeholder="Enter School's Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="residence">
                        <h4>Residence</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <input
                        type="text"
                        name="residence"
                        id="residence"
                        value={userInput.residence}
                        onChange={handleUserInput}
                        className="text-box-width1"
                        placeholder="Enter Residence"
                      />
                    </div>
                  </div>
                </div>
                <div className={team_name ? "col-md-6" : "col-md-12"}>
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="gender">
                        <h4>Best Performer</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <select
                        name="best_performer"
                        id="best_performer"
                        className="select-box-width"
                        value={userInput?.best_performer}
                        onChange={handleUserInput}
                        required
                      >
                        <option value="">-- Select --</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                      </select>
                      <img
                        src="/assets/img/arrow-down.svg"
                        alt=""
                        className="select-box-img"
                      />
                    </div>
                  </div>
                </div>
                {team_name && (
                  <div className="col-md-6">
                    <div className="d-block mt-4">
                      <div className="email-font">
                        <label htmlFor="team_id">
                          <h4>Category</h4>
                        </label>
                      </div>
                      <div className="text-box-cover">
                        <input
                          type="text"
                          value={categorySelected?.name}
                          className="text-box-width1"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="text-box-cover">
                      <textarea
                        name="bio"
                        id="bio"
                        value={userInput.bio}
                        onChange={handleUserInput}
                        className="text-area-width1"
                        placeholder="Enter Contestant's Bio"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-block mt-4">
                    <div className="picture-box-cover">
                      <input
                        type="file"
                        id="uploadBtn"
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                      />
                      {selectedImage ? (
                        <label htmlFor="uploadBtn">
                          <div className="pic-section-width cursor-pointer">
                            <div className="upload-cover-img">
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="uploaded-file"
                                className="show_img"
                              />
                            </div>
                          </div>
                        </label>
                      ) : (
                        <div className="pic-section-width">
                          <label htmlFor="uploadBtn">
                            <div className="upload-cover cursor-pointer">
                              <div>
                                <img src="/assets/img/upload.svg" alt="" />
                              </div>
                              <div>
                                <h4>Click to upload image</h4>
                              </div>

                              <div className="browse-cover d-flex">
                                <div className="my-auto">
                                  <img
                                    src="/assets/img/browse.svg"
                                    alt=""
                                    className="browse-icon me-2 my-auto"
                                  />
                                </div>
                                <h4 className="my-auto"> Browse file</h4>
                              </div>
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-block mt-4">
                    <div className="email-font">
                      <label htmlFor="hobbies">
                        <h4>Hobbies</h4>
                      </label>
                    </div>
                    <div className="text-box-cover">
                      <div className="tags-input">
                        <ul className="tag-ul">
                          {tags.map((tag, index) => {
                            return (
                              <li key={index}>
                                <span>{tag}</span>
                                <img
                                  src="/assets/img/close1.svg"
                                  alt=""
                                  className="cursor-pointer"
                                  onClick={() => removeTag(index)}
                                />
                              </li>
                            );
                          })}
                        </ul>
                        <input
                          type="text"
                          name="hobbies"
                          id="hobbies"
                          onKeyUp={(e) => (e.key === " " ? addTags(e) : null)}
                          className="text-box-tag"
                          placeholder="Type and double press space to add tags"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {socialAccounts.map((social, i) => (
                  <>
                    <div className="col-md-6">
                      <div className="d-block mt-4">
                        <div className="email-font">
                          <label htmlFor={`socialType${i}`}>
                            <h4>Social Media Type</h4>
                          </label>
                        </div>
                        <div className="text-box-cover">
                          <select
                            name="socialType"
                            id={`socialType${i}`}
                            className="select-box-width"
                            onChange={(e) => handleChange(e, i, social)}
                          >
                            <option value="">-- select --</option>
                            <option value="facebook">Facebook</option>
                            <option value="twitter">Twitter</option>
                            <option value="instagram">Instagram</option>
                            <option value="tiktok">Tiktok</option>
                          </select>
                          <img
                            src="/assets/img/arrow-down.svg"
                            alt=""
                            className="select-box-img"
                          />
                        </div>
                        {social.errorType && (
                          <div className="text-danger">{social.errorType}</div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        socialAccounts.length === 1 ? "col-md-6" : "col-md-5"
                      }
                    >
                      <div className="d-block mt-4">
                        <div className="email-font">
                          <label htmlFor={`socialHandle${i}`}>
                            <h4>Social Handle</h4>
                          </label>
                        </div>
                        <div className="text-box-cover">
                          <input
                            type="text"
                            name="socialHandle"
                            id={`socialHandle${i}`}
                            onChange={(e) => handleChange(e, i)}
                            className="text-box-width1"
                            placeholder="Enter social handle"
                          />
                        </div>
                      </div>
                      {social.errorHandle && (
                        <div className="text-danger">{social.errorHandle}</div>
                      )}
                    </div>
                    {socialAccounts.length !== 1 && (
                      <div className="col-md-1 col-12">
                        <div className="d-block mt-4 cursor-pointer">
                          <div className="email-font dis-none">
                            <label>
                              <h4></h4>
                            </label>
                          </div>
                          <div className="text-box-cover">
                            <div
                              className="add-cover"
                              onClick={(e) => handleSocialsDelete(e, i)}
                            >
                              <div className="minus"></div>
                              <button
                                type="button"
                                className="btn btn-dark rounded-pill w-100 btn-w-font"
                              >
                                {" "}
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {socialAccounts.length - 1 === i && (
                      <div className="col-md-12">
                        <div className="d-block mt-4">
                          <button
                            className="btn w-100 btn-secondary d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={(e) => handleAddSocialAcc(e, i)}
                            disabled={
                              socialAccounts.length - 1 === i &&
                              socialAccounts.length < 4
                                ? false
                                : true
                            }
                          >
                            <img
                              src="/assets/img/plus1.svg"
                              alt=""
                              className="me-2 add-s"
                            />
                            Add New Social Account
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ))}
                {phoneNumbers.map((phone, i) => (
                  <>
                    <div className="col-md-6">
                      <div className="d-block mt-4">
                        <div className="email-font">
                          <label htmlFor={`network${i}`}>
                            <h4>Mobile Network</h4>
                          </label>
                        </div>
                        <div className="text-box-cover">
                          <select
                            name="network"
                            id={`network${i}`}
                            className="select-box-width"
                            onChange={(e) => handlePhoneChange(e, i, phone)}
                          >
                            <option value="">-- select --</option>
                            <option value="mtn">MTN</option>
                            <option value="vodafone">Vodafone</option>
                            <option value="airtel_tigo">AirtelTigo</option>
                            <option value="glo">GLO</option>
                          </select>
                          <img
                            src="/assets/img/arrow-down.svg"
                            alt=""
                            className="select-box-img"
                          />
                        </div>
                        {phone.errorType && (
                          <div className="text-danger">{phone.errorType}</div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        phoneNumbers.length === 1 ? "col-md-6" : "col-md-5"
                      }
                    >
                      <div className="d-block mt-4">
                        <div className="email-font">
                          <label htmlFor={`number${i}`}>
                            <h4>Number</h4>
                          </label>
                        </div>
                        <div className="text-box-cover">
                          <input
                            type="text"
                            name="number"
                            id={`number${i}`}
                            onChange={(e) => handlePhoneChange(e, i)}
                            className="text-box-width1"
                            placeholder="Enter Phone Number"
                          />
                        </div>
                      </div>
                      {phone.errorHandle && (
                        <div className="text-danger">{phone.errorHandle}</div>
                      )}
                    </div>
                    {phoneNumbers.length !== 1 && (
                      <div className="col-md-1 col-12">
                        <div className="d-block mt-4 cursor-pointer">
                          <div className="email-font dis-none">
                            <label>
                              <h4></h4>
                            </label>
                          </div>
                          <div className="text-box-cover">
                            <div
                              className="add-cover"
                              onClick={(e) => handleNumbersDelete(e, i)}
                            >
                              <div className="minus"></div>
                              <button
                                type="button"
                                className="btn btn-dark rounded-pill w-100 btn-w-font"
                              >
                                {" "}
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {phoneNumbers.length - 1 === i && (
                      <div className="col-md-12">
                        <div className="d-block mt-4">
                          <button
                            className="btn w-100 btn-secondary d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={(e) => handleAddPhoneNumber(e, i)}
                            disabled={
                              phoneNumbers.length - 1 === i &&
                              phoneNumbers.length < 5
                                ? false
                                : true
                            }
                          >
                            <img
                              src="/assets/img/plus1.svg"
                              alt=""
                              className="me-2 add-s"
                            />
                            Add New Phone Number
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>

            <div className="d-block mt-4">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Create Contestant"
                  />
                  <img
                    src="/assets/img/arrow-right.svg d-none d-md-block"
                    alt=""
                    className="create-btn-img1"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Creating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddContestantModal;
