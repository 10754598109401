import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditShowModal = ({
  showEditModal,
  setShowEditModal,
  selectedShow,
  refetchShows,
  setIsReloadShows,
}) => {
  const [userInput, setUserInput] = useState([]);
  useEffect(() => {
    if (selectedShow) setUserInput(selectedShow);
  }, [selectedShow]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isloading, setLoading] = useState(false);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("name", userInput.name);
    data.append("description", userInput.description);
    data.append("status", userInput.status);
    selectedImage && data.append("logo_url", selectedImage);

    axios
      .post(`/api/v1/reality_shows/${userInput?.id}?_method=PUT`, data)
      .then((res) => {
        setLoading(false);
        let msg = res.data.message;
        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setShowEditModal(false);
        setIsReloadShows(true);
        refetchShows().then(() => {
          setIsReloadShows(false);
        });
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (err?.response?.status === 401) {
          toast.error(err?.response?.data?.errors?.name[0], {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  return (
    <>
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Update Show</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="email">
                  <h4>Show Name</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <img
                  src="/assets/img/profile.svg"
                  alt=""
                  className="text-box-img"
                />
                <input
                  type="text"
                  name="name"
                  id="email"
                  value={userInput.name}
                  onChange={handleUserInput}
                  className="text-box-width12"
                  placeholder="Enter Show Name"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="email">
                  <h4>Show Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="description"
                  value={userInput.description}
                  onChange={handleUserInput}
                  className="text-area-width"
                  placeholder="Write text here..."
                ></textarea>
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="picture-box-cover">
                <input
                  type="file"
                  id="uploadBtn"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
                {selectedImage ? (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <div className="upload-cover-img">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="uploaded-file"
                          className="show_img"
                        />
                      </div>
                    </div>
                  </label>
                ) : (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <div className="upload-cover-img">
                        <img
                          src={userInput?.logo_url}
                          alt="uploaded-file"
                          className="show_img"
                        />
                      </div>
                    </div>
                  </label>
                )}
              </div>
            </div>
            <div className="d-block mt-4 mb-4 mb-md-0">
              {!isloading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Update Show"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isloading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Updating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default EditShowModal;
