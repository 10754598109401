import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const initialState = {
  token: null,
  user: null,
  permissions: []
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      secureLocalStorage.setItem(
        "user",
        JSON.stringify({
          user: action.payload.user,
          token: action.payload.token,
          permissions: action.payload.permissions

        })
      );
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.permissions = action.payload.permissions;
    },
    logout: (state) => {
      secureLocalStorage.clear();
      state.user = null;
      state.token = null;
      state.permissions = []
    },
  },
});

export const { setUser, logout } = authSlice.actions;

export default authSlice.reducer;
