import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import { AnimatePresence } from "framer-motion";
import RealityShowPage from "../pages/showsComponents/RealityShowPage";
import SelectedShowPage from "../pages/showsComponents/SelectedShowPage";
import SelectedAlbumPage from "../pages/galleryComponents/SelectedAlbumPage";
import SeasonPage from "../pages/showsComponents/SeasonPage";
import GallerySeasonPage from "../pages/galleryComponents/GallerySeasonPage";
import ShowsGallery from "../pages/galleryComponents/ShowsGallery";
import ShowAlbumsPage from "../pages/galleryComponents/albumComponents/ShowAlbumsPage";
import JudgesScorePage from "../pages/showsComponents/judgesScoreComponents/judgesScorePage";
import LeaderBoard from "../pages/showsComponents/leaderBoardComponents/LeaderBoard";
import PrivateRoute from "./PrivateRoute";
import Transactions from "../pages/showsComponents/transactionComponents/Transactions";
import Settings from "../pages/showsComponents/settingsComponents/Settings";
import ResetPasswordPage from "../pages/resetPasswordComponents/ResetPasswordPage";
import ShowProtectedRoute from "./ShowProtectedRoute";
import ContestantProtectedRoute from "./ContestantProtectedRoute";
import TransactionProtectedRoute from "./TransactionProtectedRoute";
import ResultsProtectedRoute from "./ResultsProtectedRoute";
import SettingsProtectedRoute from "./SettingsProtectedRoute";
import FirstTimePasswordResetPage from "../pages/FirstTimePasswordResetPage";
import FirstTimePasswordProtectedRoute from "./FirstTimePasswordProtectedRoute";
import CategoryPage from "../pages/showsComponents/CategoryPage";
import CategoryProtectedRoute from "./CategoryProtectedPage";

const AnimatedRoute = () => {
  const location = useLocation();

  return (
    <AnimatePresence location={location} key={location.pathname}>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/reality-shows" element={<RealityShowPage />}></Route>
          <Route
            path="/reality-show/:code"
            element={
              <ShowProtectedRoute>
                <SelectedShowPage />
              </ShowProtectedRoute>
            }
          ></Route>
          {[
            "/reality-show/:code/:season_code",
            "/reality-show/:code/:season_code/:team_name",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={
                  <ShowProtectedRoute>
                    <ContestantProtectedRoute>
                      <SeasonPage />
                    </ContestantProtectedRoute>
                  </ShowProtectedRoute>
                }
                key={index}
              />
            );
          })}
          <Route
            path="/reality-show/:code/:season_code/categories"
            element={
              <ShowProtectedRoute>
                <ContestantProtectedRoute>
                  <CategoryProtectedRoute>
                    <CategoryPage />
                  </CategoryProtectedRoute>
                </ContestantProtectedRoute>
              </ShowProtectedRoute>
            }
          ></Route>

          <Route
            path="/reality-show/:code/:season_code/judges_score"
            element={
              <ShowProtectedRoute>
                <ContestantProtectedRoute>
                  <ResultsProtectedRoute>
                    <JudgesScorePage />
                  </ResultsProtectedRoute>
                </ContestantProtectedRoute>
              </ShowProtectedRoute>
            }
          ></Route>
          <Route
            path="/reality-show/:code/:season_code/leader_board"
            element={
              <ShowProtectedRoute>
                <ContestantProtectedRoute>
                  <ResultsProtectedRoute>
                    <LeaderBoard />
                  </ResultsProtectedRoute>
                </ContestantProtectedRoute>
              </ShowProtectedRoute>
            }
          ></Route>

          <Route
            path="/reality-show/:code/:season_code/transactions"
            element={
              <ShowProtectedRoute>
                <ContestantProtectedRoute>
                  <TransactionProtectedRoute>
                    <Transactions />
                  </TransactionProtectedRoute>
                </ContestantProtectedRoute>
              </ShowProtectedRoute>
            }
          ></Route>
          <Route path="/gallery" element={<ShowsGallery />}></Route>
          <Route
            path="/gallery/:code/:season_code/:album_code"
            element={<SelectedAlbumPage />}
          ></Route>
          <Route path="/gallery/:code" element={<GallerySeasonPage />}></Route>
          <Route
            path="/gallery/:code/:season_code"
            element={<ShowAlbumsPage />}
          ></Route>
          <Route
            path="/settings"
            element={
              <SettingsProtectedRoute>
                <Settings />
              </SettingsProtectedRoute>
            }
          ></Route>
          <Route path="/reset_password" element={<ResetPasswordPage />}></Route>
          <Route
            path="/password_reset"
            element={
              <FirstTimePasswordProtectedRoute>
                <FirstTimePasswordResetPage />
              </FirstTimePasswordProtectedRoute>
            }
          ></Route>
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
export default AnimatedRoute;
