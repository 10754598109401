import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useAddVideosMutation } from "../../../app/apiSlice";
import { useLocation } from "react-router-dom";
const AddVideosModal = ({
  setShowUploadVidsModal,
  showUploadVidsModal,
  setCurrentScreen,
  refetchVideos,
  setIsVideosReload,
  contestants,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeGalleryShow = queryParams.get("show");
  const selectedGallerySeason = queryParams.get("season");
  const activeAlbum = queryParams.get("album");

  const initialUserInput = {
    video_url: "",
    video_caption: "",
    desc: "",
    contestant: "",
  };

  const [userInput, setUserInput] = useState(initialUserInput);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [isloading, setLoading] = useState(false);

  const closeModal = () => {
    setSelectedImage(null);
    setShowUploadVidsModal(false);
  };

  const [addVideos, { data, isSuccess, isError, error, isLoading }] =
    useAddVideosMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const youtubeUrlRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|shorts\/)?([a-zA-Z0-9_-]+)/;

    setLoading(true);
    const formData = new FormData();
    formData.append("video_url", userInput.video_url);
    formData.append("video_caption", userInput.video_caption);
    formData.append("media_type", "episode");
    formData.append("desc", userInput?.desc);
    formData.append("thumbnail", selectedImage);
    formData.append("show_id", activeGalleryShow);
    formData.append("season_id", selectedGallerySeason);
    formData.append("album_id", activeAlbum);
    formData.append("cont_id", userInput?.contestant);
    if (selectedImage === null) {
      setLoading(false);
      toast.error("Video thumbnail is required", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      if (youtubeUrlRegex.test(userInput.video_url)) {
        try {
          await addVideos(formData);
        } catch (error) {
          console.error("Error adding videos:", error);
        }
      } else {
        setLoading(false);
        toast.error("Invalid Youtube URL", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowUploadVidsModal(false);
      setSelectedImage(null);
      setUserInput(initialUserInput);
      setCurrentScreen(2);
      setIsVideosReload(true);
      refetchVideos().then(() => {
        setIsVideosReload(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.message?.video_url[0], {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <Modal
        show={showUploadVidsModal}
        onHide={closeModal}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Upload New Video</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mt-4 mt-md-0">
                <label htmlFor="video_url">
                  <h4>Video URL</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="text"
                  name="video_url"
                  id="video_url"
                  value={userInput?.video_url}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter Youtube URL"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="video_caption">
                  <h4>Video Caption</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="text"
                  name="video_caption"
                  id="video_caption"
                  value={userInput?.video_caption}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter video caption"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="desc">
                  <h4>Video Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="desc"
                  id="desc"
                  value={userInput.desc}
                  onChange={handleUserInput}
                  className="text-area-width1"
                  placeholder="Enter video description"
                  required
                ></textarea>
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="contestant">
                  <h4>Contestant</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <select
                  name="contestant"
                  id="contestant"
                  className="select-box-width"
                  value={userInput?.contestant?.id}
                  onChange={handleUserInput}
                  required
                >
                  <option value="">-- select --</option>
                  {contestants?.map((ele, index) => (
                    <option key={index} value={ele?.id}>
                      {ele?.stage}
                    </option>
                  ))}
                </select>
                <img
                  src="/assets/img/arrow-down.svg"
                  alt=""
                  className="select-box-img"
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="picture-box-cover">
                <input
                  type="file"
                  id="uploadBtn"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
                {selectedImage ? (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="uploaded-file"
                        className="show_img"
                      />
                    </div>
                  </label>
                ) : (
                  <div className="pic-section-width">
                    <label htmlFor="uploadBtn">
                      <div className="upload-cover cursor-pointer">
                        <div>
                          <img src="/assets/img/upload.svg" alt="" />
                        </div>
                        <div>
                          <h4>Click to upload thumbnail</h4>
                        </div>

                        <div className="browse-cover d-flex">
                          <div className="my-auto">
                            <img
                              src="/assets/img/browse.svg"
                              alt=""
                              className="browse-icon me-2 my-auto"
                            />
                          </div>
                          <h4 className="my-auto"> Browse file</h4>
                        </div>
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block mt-4">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Upload Video"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Uploading..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddVideosModal;
