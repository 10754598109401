import { motion } from "framer-motion";
import Nav from "../../components/partials/Nav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddContestantModal from "./addContestantComponents/AddContestantModal";
import { useEffect, useState } from "react";
import EditContestantModal from "./addContestantComponents/EditContestantModal";
import { ToastContainer, toast } from "react-toastify";
import { DotLoader } from "react-spinners";
import ContestantGalleryModal from "./contestantGalleryComponents/ContestantGalleryModal";
import {
  useGetContestantQuery,
  useGetSeasonContestantsQuery,
} from "../../app/apiSlice";
import secureLocalStorage from "react-secure-storage";
const SeasonPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code, season_code, team_name } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");
  const teamID = queryParams.get("category");
  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const [search, setSearch] = useState("");
  const [isReloadContestants, setIsReloadContestants] = useState(false);
  const [contestants, setContestants] = useState([]);
  const [selectedContestant, setSelectedContestant] = useState({});
  const [activeContestant, setActiveContestant] = useState({});
  const [contestantModal, setContestantModal] = useState(false);
  const [contestantGalleryModalShow, setContestantGalleryModalShow] =
    useState(false);
  const [contestantEditModal, setContestantEditModal] = useState(false);
  const [isReloadContestant, setIsReloadContestant] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#febf4c");
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const isCanCreateContestantIncluded = permissions?.includes(
    "can_create_contestant"
  );
  const isCanUpdateContestantIncluded = permissions?.includes(
    "can_update_contestant"
  );
  const isCanDeleteContestantIncluded = permissions?.includes(
    "can_delete_contestant"
  );
  const isCanViewTransactionIncluded = permissions?.includes(
    "can_view_transaction"
  );
  const isCanViewResultsIncluded = permissions?.includes("can_view_results");

  const {
    data: allcontestants,
    isLoading: isContestantsLoading,
    refetch: refetchContestants,
  } = useGetSeasonContestantsQuery({
    showID: activeShow,
    seasonID: season,
    ...(teamID !== undefined && teamID !== "" && teamID !== null && { teamID }),
  });

  const {
    data: contestant,
    isLoading: isContestantLoading,
    refetch: refetchContestant,
  } = useGetContestantQuery(
    {
      contestantID: selectedContestant.id,
    },
    {
      skip: !selectedContestant.id,
    }
  );

  useEffect(() => {
    if (contestant) {
      setActiveContestant(contestant.data.contestant);
    }
  }, [contestant]);

  useEffect(() => {
    if (allcontestants) {
      setContestants(allcontestants.data.contestants);
    }
  }, [allcontestants]);

  const handleEditContestantClick = async (contestant) => {
    setSelectedContestant(contestant);
    setContestantEditModal(true);
    setIsReloadContestant(true);

    try {
      await refetchContestant({ contestantID: contestant.id }).then(() => {
        setIsReloadContestant(false);
      });
    } catch (error) {
      console.error("Error occurred while refetching regions:", error);
      setIsReloadContestant(false);
    }
  };

  const navigateToScores = () => {
    navigate(
      `/reality-show/${code}/${season_code}/judges_score?show=${activeShow}&season=${season}`
    );
  };
  const navigateToLeaderBoard = () => {
    navigate(
      `/reality-show/${code}/${season_code}/leader_board?show=${activeShow}&season=${season}`
    );
  };
  const filteredList = contestants?.filter((item) => {
    if (search === "") {
      return item;
    } else if (item?.stage?.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />
      <div className="container body-width">
        <div className="col-md-12">
          <div className="indication1">
            <div
              className="d-flex justify-content-between cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <div className="">
                <img src="/assets/img/home.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Home</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate("/reality-shows")}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Reality Shows</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() =>
                navigate(`/reality-show/${code}?show=${activeShow}`)
              }
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">
                  {convertSnakeCaseToTitleCase(code)}
                </h4>
              </div>
            </div>

            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() =>
                navigate(
                  code === "sdg"
                    ? `/reality-show/${code}/${season_code}/categories?show=${activeShow}&season=${season}`
                    : `/reality-show/${code}?show=${activeShow}`
                )
              }
            >
              <div className="">
                <img
                  src={
                    code === "sdg"
                      ? "/assets/img/arr-right1.svg"
                      : "/assets/img/arr-right.svg"
                  }
                  alt=""
                  className="me-2"
                />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1  text-capitalize">
                  {convertSnakeCaseToTitleCase(season_code)}
                </h4>
              </div>
            </div>

            {code === "sdg" && (
              <div className="d-flex justify-content-between ms-2">
                <div className="">
                  <img
                    src="/assets/img/arr-right.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1 disabled-color text-capitalize">
                    {convertSnakeCaseToTitleCase(team_name)}
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
            <div className="col-md-3 col-12">
              <div className="row title-font">
                <h4 className="text-capitalize">Contestants</h4>
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="d-flex float-end mt-3 mt-md-0">
                <div
                  className={
                    expandSearch === true ? "search-active me-2" : "search me-2"
                  }
                >
                  <div
                    className="icon"
                    onClick={() => setExpandSearch((prev) => !prev)}
                  ></div>
                  <div className="input">
                    <input
                      type="text"
                      id="seacrh-box"
                      placeholder={expandSearch === true ? "Search..." : " "}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className="add-cover1 cursor-pointer me-2"
                    data-title="add pictures / videos"
                    onClick={() => setContestantGalleryModalShow(true)}
                  >
                    <img src="/assets/img/media.svg" alt="" />
                  </div>
                </div>
                {code !== "sdg" && (
                  <div>
                    {isCanViewTransactionIncluded && (
                      <div
                        className="add-cover1 cursor-pointer me-2"
                        data-title="Transactions"
                        onClick={() =>
                          navigate(
                            `/reality-show/${code}/${season_code}/transactions?show=${activeShow}&season=${season}`
                          )
                        }
                      >
                        <img src="/assets/img/setting-4.svg" alt="" />
                      </div>
                    )}
                  </div>
                )}
                {code !== "sdg"  && (
                  <>
                    {isCanViewResultsIncluded && (
                      <div>
                        <div
                          className="add-cover1 cursor-pointer me-2"
                          data-title="Leader Board"
                          onClick={() => navigateToLeaderBoard()}
                        >
                          <img src="/assets/img/manage.svg" alt="" />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isCanCreateContestantIncluded && (
                  <div>
                    <div
                      className="add-cover1 cursor-pointer me-2"
                      data-title="Add New Contestant"
                      onClick={() => setContestantModal(true)}
                    >
                      <img src="/assets/img/plus.svg" alt="" />
                    </div>
                  </div>
                )}
                {code !== "sdg" && (
                  <>
                    {isCanViewResultsIncluded && (
                      <div>
                        <div
                          className="add-cover1 cursor-pointer"
                          data-title="Manage Judges Score"
                          onClick={navigateToScores}
                        >
                          <img src="/assets/img/pencil.svg" alt="" />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            {!isContestantsLoading && !isReloadContestants ? (
              <>
                {filteredList
                  ?.filter(
                    (contestant) =>
                      contestant?.status?.toLowerCase() === "winner"
                  )
                  .map((contestant, i) => {
                    return (
                      <div className="col-md-4 col-12 mt-4" key={i}>
                        <div>
                          <div
                            className={
                              contestant?.status?.toLowerCase() === "evicted"
                                ? "contestant-card-background-evicted hover-bg"
                                : contestant?.status?.toLowerCase() === "winner"
                                ? "contestant-card-background-winner hover-bg"
                                : contestant?.status?.toLowerCase() ===
                                  "1st_runner_up"
                                ? "contestant-card-background-1st hover-bg"
                                : contestant?.status?.toLowerCase() === "active"
                                ? "contestant-card-background-active hover-bg"
                                : "contestant-card-background hover-bg"
                            }
                          >
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-card-inner-evicted"
                                  : "contestant-card-inner"
                              }
                            >
                              <div className="contestant-img-overlay"></div>
                              <div className="d-flex absolute-constentant-bg">
                                {isCanUpdateContestantIncluded && (
                                  <div
                                    className="edit-contestant-bg cursor-pointer py-2 px-3"
                                    onClick={() =>
                                      handleEditContestantClick(contestant)
                                    }
                                  >
                                    <h4>Edit</h4>
                                  </div>
                                )}
                                <div className="edit-contestant-bg ms-2 py-2 px-3">
                                  <h4 className="text-capitalize">
                                    {contestant?.status}
                                  </h4>
                                </div>
                              </div>
                              <img src={contestant?.cover_pic} alt="" />
                            </div>
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-title-evicted"
                                  : contestant?.status?.toLowerCase() ===
                                    "winner"
                                  ? "contestant-title-winner"
                                  : contestant?.status?.toLowerCase() ===
                                    "1st_runner_up"
                                  ? "contestant-title-1st"
                                  : contestant?.status?.toLowerCase() ===
                                    "active"
                                  ? "contestant-title-active"
                                  : "contestant-title"
                              }
                            >
                              <h4>{contestant?.stage}</h4>

                              <h3>{contestant?.town}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {filteredList
                  ?.filter(
                    (contestant) =>
                      contestant?.status?.toLowerCase() === "1st_runner_up"
                  )
                  .map((contestant, i) => {
                    return (
                      <div className="col-md-4 col-12 mt-4">
                        <div key={i}>
                          <div
                            className={
                              contestant?.status?.toLowerCase() === "evicted"
                                ? "contestant-card-background-evicted hover-bg"
                                : contestant?.status?.toLowerCase() === "winner"
                                ? "contestant-card-background-winner hover-bg"
                                : contestant?.status?.toLowerCase() ===
                                  "1st_runner_up"
                                ? "contestant-card-background-1st hover-bg"
                                : contestant?.status?.toLowerCase() === "active"
                                ? "contestant-card-background-active hover-bg"
                                : "contestant-card-background hover-bg"
                            }
                          >
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-card-inner-evicted"
                                  : "contestant-card-inner"
                              }
                            >
                              <div className="contestant-img-overlay"></div>
                              <div className="d-flex absolute-constentant-bg">
                                {isCanUpdateContestantIncluded && (
                                  <div
                                    className="edit-contestant-bg cursor-pointer py-2 px-3"
                                    onClick={() =>
                                      handleEditContestantClick(contestant)
                                    }
                                  >
                                    <h4>Edit</h4>
                                  </div>
                                )}
                                <div className="edit-contestant-bg ms-2 py-2 px-3">
                                  <h4 className="text-capitalize">
                                    {contestant?.status.toLowerCase() ===
                                      "1st_runner_up" && "1st Runner Up"}
                                  </h4>
                                </div>
                              </div>
                              <img src={contestant?.cover_pic} alt="" />
                            </div>
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-title-evicted"
                                  : contestant?.status?.toLowerCase() ===
                                    "winner"
                                  ? "contestant-title-winner"
                                  : contestant?.status?.toLowerCase() ===
                                    "1st_runner_up"
                                  ? "contestant-title-1st"
                                  : contestant?.status?.toLowerCase() ===
                                    "active"
                                  ? "contestant-title-active"
                                  : "contestant-title"
                              }
                            >
                              <h4>{contestant?.stage}</h4>

                              <h3>{contestant?.town}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {filteredList
                  ?.filter(
                    (contestant) =>
                      contestant?.status?.toLowerCase() === "2nd_runner_up"
                  )
                  .map((contestant, i) => {
                    return (
                      <div className="col-md-4 col-12 mt-4">
                        <div key={i}>
                          <div
                            className={
                              contestant?.status?.toLowerCase() === "evicted"
                                ? "contestant-card-background-evicted hover-bg"
                                : contestant?.status?.toLowerCase() === "winner"
                                ? "contestant-card-background-winner hover-bg"
                                : contestant?.status?.toLowerCase() ===
                                  "1st_runner_up"
                                ? "contestant-card-background-1st hover-bg"
                                : contestant?.status?.toLowerCase() === "active"
                                ? "contestant-card-background-active hover-bg"
                                : "contestant-card-background hover-bg"
                            }
                          >
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-card-inner-evicted"
                                  : "contestant-card-inner"
                              }
                            >
                              <div className="contestant-img-overlay"></div>
                              <div className="d-flex absolute-constentant-bg">
                                {isCanUpdateContestantIncluded && (
                                  <div
                                    className="edit-contestant-bg cursor-pointer py-2 px-3"
                                    onClick={() =>
                                      handleEditContestantClick(contestant)
                                    }
                                  >
                                    <h4>Edit</h4>
                                  </div>
                                )}
                                <div className="edit-contestant-bg ms-2 py-2 px-3">
                                  <h4 className="text-capitalize">
                                    {contestant?.status.toLowerCase() ===
                                      "2nd_runner_up" && "2nd Runner Up"}
                                  </h4>
                                </div>
                              </div>
                              <img src={contestant?.cover_pic} alt="" />
                            </div>
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-title-evicted"
                                  : contestant?.status?.toLowerCase() ===
                                    "winner"
                                  ? "contestant-title-winner"
                                  : contestant?.status?.toLowerCase() ===
                                    "1st_runner_up"
                                  ? "contestant-title-1st"
                                  : contestant?.status?.toLowerCase() ===
                                    "active"
                                  ? "contestant-title-active"
                                  : "contestant-title"
                              }
                            >
                              <h4>{contestant?.stage}</h4>

                              <h3>{contestant?.town}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {filteredList
                  ?.filter(
                    (contestant) =>
                      contestant?.status?.toLowerCase() === "active"
                  )
                  .map((contestant, i) => {
                    return (
                      <div className="col-md-4 col-12 mt-4">
                        <div key={i}>
                          <div
                            className={
                              contestant?.status?.toLowerCase() === "evicted"
                                ? "contestant-card-background-evicted hover-bg"
                                : contestant?.status?.toLowerCase() === "winner"
                                ? "contestant-card-background-winner hover-bg"
                                : contestant?.status?.toLowerCase() ===
                                  "1st_runner_up"
                                ? "contestant-card-background-1st hover-bg"
                                : contestant?.status?.toLowerCase() === "active"
                                ? "contestant-card-background-active hover-bg"
                                : "contestant-card-background hover-bg"
                            }
                          >
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-card-inner-evicted"
                                  : "contestant-card-inner"
                              }
                            >
                              <div className="contestant-img-overlay"></div>
                              <div className="d-flex absolute-constentant-bg">
                                {isCanUpdateContestantIncluded && (
                                  <div
                                    className="edit-contestant-bg cursor-pointer py-2 px-3"
                                    onClick={() =>
                                      handleEditContestantClick(contestant)
                                    }
                                  >
                                    <h4>Edit</h4>
                                  </div>
                                )}
                                <div className="edit-contestant-bg ms-2 py-2 px-3">
                                  <h4 className="text-capitalize">
                                    {contestant?.status}
                                  </h4>
                                </div>
                              </div>
                              <img src={contestant?.cover_pic} alt="" />
                            </div>
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-title-evicted"
                                  : contestant?.status?.toLowerCase() ===
                                    "winner"
                                  ? "contestant-title-winner"
                                  : contestant?.status?.toLowerCase() ===
                                    "1st_runner_up"
                                  ? "contestant-title-1st"
                                  : contestant?.status?.toLowerCase() ===
                                    "active"
                                  ? "contestant-title-active"
                                  : "contestant-title"
                              }
                            >
                              <h4>{contestant?.stage}</h4>

                              <h3>{contestant?.town}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {filteredList
                  ?.filter(
                    (contestant) =>
                      contestant?.status?.toLowerCase() === "evicted"
                  )
                  .map((contestant, i) => {
                    return (
                      <div className="col-md-4 col-12 mt-4">
                        <div key={i}>
                          <div
                            className={
                              contestant?.status?.toLowerCase() === "evicted"
                                ? "contestant-card-background-evicted hover-bg"
                                : contestant?.status?.toLowerCase() === "winner"
                                ? "contestant-card-background-winner hover-bg"
                                : contestant?.status?.toLowerCase() ===
                                  "1st_runner_up"
                                ? "contestant-card-background-1st hover-bg"
                                : contestant?.status?.toLowerCase() === "active"
                                ? "contestant-card-background-active hover-bg"
                                : "contestant-card-background hover-bg"
                            }
                          >
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-card-inner-evicted"
                                  : "contestant-card-inner"
                              }
                            >
                              <div className="contestant-img-overlay"></div>
                              <div className="d-flex absolute-constentant-bg">
                                {isCanUpdateContestantIncluded && (
                                  <div
                                    className="edit-contestant-bg cursor-pointer py-2 px-3"
                                    onClick={() =>
                                      handleEditContestantClick(contestant)
                                    }
                                  >
                                    <h4>Edit</h4>
                                  </div>
                                )}
                                <div className="edit-contestant-bg ms-2 py-2 px-3">
                                  <h4 className="text-capitalize">
                                    {contestant?.status}
                                  </h4>
                                </div>
                              </div>
                              <img src={contestant?.cover_pic} alt="" />
                            </div>
                            <div
                              className={
                                contestant?.status?.toLowerCase() === "evicted"
                                  ? "contestant-title-evicted"
                                  : contestant?.status?.toLowerCase() ===
                                    "winner"
                                  ? "contestant-title-winner"
                                  : contestant?.status?.toLowerCase() ===
                                    "1st_runner_up"
                                  ? "contestant-title-1st"
                                  : contestant?.status?.toLowerCase() ===
                                    "active"
                                  ? "contestant-title-active"
                                  : "contestant-title"
                              }
                            >
                              <h4>{contestant?.stage}</h4>

                              <h3>{contestant?.town}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {isCanCreateContestantIncluded && (
                  <div className="col-md-4 col-12 mt-4">
                    <div
                      className="show-card-background1"
                      onClick={() => setContestantModal(true)}
                    >
                      {contestants?.length === 0 && (
                        <div className="show-title-background1 bg1">
                          <h4 className="my-2">Create New Contestant</h4>
                        </div>
                      )}

                      <div className="show-card-inner2">
                        <img
                          src="/assets/img/addIcon.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="loader-h">
                <DotLoader
                  color={color}
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isCanCreateContestantIncluded && (
        <AddContestantModal
          setContestantModal={setContestantModal}
          contestantModal={contestantModal}
          setIsReloadContestants={setIsReloadContestants}
          refetchContestants={refetchContestants}
          team_name={team_name}
          teamID={teamID}
        />
      )}
      {isCanUpdateContestantIncluded && (
        <EditContestantModal
          setContestantEditModal={setContestantEditModal}
          contestantEditModal={contestantEditModal}
          activeContestant={activeContestant}
          setIsReloadContestants={setIsReloadContestants}
          refetchContestants={refetchContestants}
          setIsReloadContestant={setIsReloadContestant}
          refetchContestant={refetchContestant}
          isReloadContestant={isReloadContestant}
          teamID={teamID}
          team_name={team_name}
        />
      )}
      <ContestantGalleryModal
        setContestantGalleryModalShow={setContestantGalleryModalShow}
        contestantGalleryModalShow={contestantGalleryModalShow}
      />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default SeasonPage;
