import React from "react";
import RedirectPage from "./RedirectPage";
import secureLocalStorage from "react-secure-storage";

function TransactionProtectedRoute({ children }) {
  const permissions = JSON?.parse(secureLocalStorage?.getItem("user"))?.permissions
  const isCanViewTransactionIncluded = permissions?.includes(
    "can_view_transaction"
  );

  return isCanViewTransactionIncluded ? children : <RedirectPage />;
}

export default TransactionProtectedRoute;
