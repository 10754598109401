import { motion } from "framer-motion";
import Nav from "../../components/partials/Nav";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { setActiveGalleryShow, setAllShows } from "../../app/showsSlice";
import { useGetAllShowsQuery } from "../../app/apiSlice";
import LoadingPage from "../../components/LoadingPage";

const ShowsGallery = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandSearch, setExpandSearch] = useState(false);
  const [allShows, setAllShows] = useState([]);
  const {
    data: shows,
    isLoading,
    refetch: refetchShows,
  } = useGetAllShowsQuery();
  useEffect(() => {
    if (shows) {
      setAllShows(shows.data.shows);
    }
  }, [shows]);

  const handleShowClick = (selectedShow) => {
    navigate(`/gallery/${selectedShow?.code}?show=${selectedShow?.id}`);
  };

  const filteredList = allShows?.filter((item) => {
    if (search === "") {
      return item;
    } else if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />

      <>
        {!isLoading ? (
          <div className="container body-width">
            <div className="col-md-12">
              <div className="indication1">
                <div
                  className="d-flex justify-content-between cursor-pointer"
                  onClick={() => navigate("/dashboard")}
                >
                  <div className="">
                    <img src="/assets/img/home.svg" alt="" className="me-2" />
                  </div>
                  <div className="my-auto">
                    <h4 className="my-auto mt-1">Home</h4>
                  </div>
                </div>
                <div className="d-flex justify-content-between ms-2">
                  <div className="">
                    <img
                      src="/assets/img/arr-right.svg"
                      alt=""
                      className="me-2"
                    />
                  </div>
                  <div className="my-auto">
                    <h4 className="my-auto mt-1 disabled-color">Gallery</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
                <div className="col-md-6">
                  <div className="font-reality text-center text-md-start">
                    <h4>Gallery</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex float-end">
                    <div
                      className={
                        expandSearch === true ? "search-active" : "search"
                      }
                    >
                      <div
                        className="icon"
                        onClick={() => setExpandSearch((prev) => !prev)}
                      ></div>
                      <div className="input">
                        <input
                          type="text"
                          id="seacrh-box"
                          placeholder={
                            expandSearch === true ? "Search..." : " "
                          }
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                {filteredList?.length === 0 ? (
                  <div className="no-data-cover">
                    <div className="no-data">
                      <h1> No Show Available</h1>
                    </div>
                  </div>
                ) : (
                  <>
                    {filteredList?.map((show, i) => {
                      return (
                        <div className="col-md-4 mt-4" key={i}>
                          <div>
                            <div className="show-card-background">
                              <div className="show-card-overlay"></div>
                              <div
                                className="show-card-inner"
                                onClick={() => handleShowClick(show)}
                              >
                                <div className="show-title">
                                  <h4>{show?.name}</h4>
                                </div>
                              </div>
                              <div className="show-image">
                                <img src={show?.logo_url} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <LoadingPage />
        )}
      </>
    </motion.div>
  );
};
export default ShowsGallery;
