import React, { useEffect, useState } from "react";
import {
  useGetAllCategoriesQuery,
  useGetAllShowsQuery,
} from "../../app/apiSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Nav from "../../components/partials/Nav";
import LoadingPage from "../../components/LoadingPage";
import { DotLoader } from "react-spinners";
import AddNewCategory from "./categoryComponents/AddNewCategory";
import EditCategory from "./categoryComponents/EditCategory";
import secureLocalStorage from "react-secure-storage";

function CategoryPage() {
  const [categories, setCategories] = useState([]);
  const [selectedShow, setSelectedShow] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showNewCateModal, setShowNewCatModal] = useState(false);
  const [showEditCatModal, setShowEditCatModal] = useState(false);
  const [isReloadCates, setIsaReloadCates] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#febf4c");
  const [search, setSearch] = useState("");
  const location = useLocation();
  const [expandSearch, setExpandSearch] = useState(false);
  const navigate = useNavigate();
  const { code, season_code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const isCanViewContestantIncluded = permissions?.includes(
    "can_view_contestant"
  );
  const isCanViewTransactionIncluded = permissions?.includes(
    "can_view_transaction"
  );
  const isCanViewResultsIncluded = permissions?.includes("can_view_results");

  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const {
    data: allShows,
    isLoading: showsLoading,
    error: showsError,
    refetch: refetchRoles,
  } = useGetAllShowsQuery();
  useEffect(() => {
    if (allShows?.data?.shows) {
      const selshow = allShows?.data?.shows.find((show) => show.code === code);
      setSelectedShow(selshow);
    }
  }, [allShows, code]);

  const {
    data: allCategories,
    isLoading,
    refetch: refetchCategories,
  } = useGetAllCategoriesQuery({
    showID: activeShow,
  });

  useEffect(() => {
    if (allCategories) {
      setCategories(allCategories.data.teams);
    }
  }, [allCategories]);

  const handleEditCategory = (team) => {
    setSelectedCategory(team);
    setShowEditCatModal(true);
  };

  const filteredList = categories?.filter((item) => {
    if (search === "") {
      return item;
    } else if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });

  const navigateToScores = () => {
    navigate(
      `/reality-show/${code}/${season_code}/judges_score?show=${activeShow}&season=${season}`
    );
  };
  const navigateToLeaderBoard = () => {
    navigate(
      `/reality-show/${code}/${season_code}/leader_board?show=${activeShow}&season=${season}`
    );
  };

  const handleTeamClick = (team) => {
    const teamString = team?.name.toLowerCase().replace(/\s/g, "-");
    navigate(
      `/reality-show/${code}/${season_code}/${teamString}?show=${activeShow}&season=${season}&category=${team?.id}`
    );
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />

      <div className="container body-width">
        <div className="col-md-12">
          <div className="indication1">
            <div
              className="d-flex justify-content-between cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <div className="">
                <img src="/assets/img/home.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Home</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate("/reality-shows")}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Reality Shows</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() =>
                navigate(`/reality-show/${code}?show=${activeShow}`)
              }
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">
                  {convertSnakeCaseToTitleCase(code)}
                </h4>
              </div>
            </div>

            <div className="d-flex justify-content-between ms-2">
              <div className="">
                <img src="/assets/img/arr-right.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1 disabled-color text-capitalize">
                  {convertSnakeCaseToTitleCase(season_code)}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
            <div className="col-md-3 col-12">
              <div className="title-font text-center text-md-start">
                <h4 className="text-capitalize">Categories</h4>
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="d-flex float-end mt-3 mt-md-0">
                <div
                  className={
                    expandSearch === true ? "search-active me-2" : "search me-2"
                  }
                >
                  <div
                    className="icon"
                    onClick={() => setExpandSearch((prev) => !prev)}
                  ></div>
                  <div className="input">
                    <input
                      type="text"
                      id="seacrh-box"
                      placeholder={expandSearch === true ? "Search..." : " "}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  {isCanViewTransactionIncluded && (
                    <div
                      className="add-cover1 cursor-pointer me-2"
                      data-title="Transactions"
                      onClick={() =>
                        navigate(
                          `/reality-show/${code}/${season_code}/transactions?show=${activeShow}&season=${season}`
                        )
                      }
                    >
                      <img src="/assets/img/setting-4.svg" alt="" />
                    </div>
                  )}
                </div>

                {isCanViewResultsIncluded && (
                  <div>
                    <div
                      className="add-cover1 cursor-pointer me-2"
                      data-title="Leader Board"
                      onClick={() => navigateToLeaderBoard()}
                    >
                      <img src="/assets/img/manage.svg" alt="" />
                    </div>
                  </div>
                )}
                <div>
                  <div
                    className="add-cover1 cursor-pointer me-2"
                    data-title="Add New Category"
                    onClick={() => setShowNewCatModal(true)}
                  >
                    <img src="/assets/img/plus.svg" alt="" />
                  </div>
                </div>

                {isCanViewResultsIncluded && (
                  <div>
                    <div
                      className="add-cover1 cursor-pointer"
                      data-title="Manage Judges Score"
                      onClick={navigateToScores}
                    >
                      <img src="/assets/img/pencil.svg" alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            {!isLoading && !isReloadCates ? (
              <>
                {filteredList?.map((team, i) => {
                  return (
                    <div className="col-md-4 mt-4" key={i}>
                      <div>
                        <div
                          className={
                            isCanViewContestantIncluded
                              ? "cate-background cursor-pointer"
                              : "cate-background"
                          }
                        >
                          <div className="d-flex absolute-constentant-bg">
                            <div
                              className="edit-contestant-bg ms-2  show-title-background1-edit cursor-pointer"
                              onClick={() => handleEditCategory(team)}
                            >
                              <h4 className="text-capitalize">Edit</h4>
                            </div>
                          </div>
                          <div className="show-card-overlay"></div>
                          <div
                            className="show-card-inner inner1"
                            onClick={() => {
                              isCanViewContestantIncluded &&
                                handleTeamClick(team);
                            }}
                          >
                            <div className="show-title1">
                              <h4 className="text-white">{team?.name}</h4>
                            </div>
                          </div>
                          <div className="show-image">
                            <img src={selectedShow?.logo_url} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {isLoading && <LoadingPage />}
                <div className="col-md-4 col-12 mt-4">
                  <div
                    className="show-card-background1"
                    onClick={() => setShowNewCatModal(true)}
                  >
                    {categories?.length === 0 && (
                      <div className="show-title-background1 bg1">
                        <h4 className="my-2">Create New Category</h4>
                      </div>
                    )}

                    <div className="show-card-inner2">
                      <img
                        src="/assets/img/addIcon.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="loader-h">
                <DotLoader
                  color={color}
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <AddNewCategory
        setShowNewCatModal={setShowNewCatModal}
        showNewCateModal={showNewCateModal}
        setIsaReloadCates={setIsaReloadCates}
        refetchCategories={refetchCategories}
      />
      <EditCategory
        setShowEditCatModal={setShowEditCatModal}
        showEditCatModal={showEditCatModal}
        selectedCategory={selectedCategory}
        setIsaReloadCates={setIsaReloadCates}
        refetchCategories={refetchCategories}
      />
    </motion.div>
  );
}

export default CategoryPage;
