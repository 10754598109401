import { motion } from "framer-motion";
import Nav from "../../../components/partials/Nav";
import { ToastContainer } from "react-toastify";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import UsersPage from "./uersComponents/UsersPage";
import RolesPage from "./roleComponents/RolesPage";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const Settings = () => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const profile = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.user;
  const user_role = profile.role.title.toLowerCase().replace(/\s+/g, "_");

  const isCanCreateUserIncluded = permissions?.includes("can_create_user");
  const isCanUpdateUserIncluded = permissions?.includes("can_update_user");
  const isCanDeleteUserIncluded = permissions?.includes("can_delete_user");
  const isCanViewUserIncluded = permissions?.includes("can_view_user");

  const navigate = useNavigate();
  const updatePage = (id) => {
    setCurrentScreen(id);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />
      <div className="container body-width">
        <div className="col-md-12">
          <div className="row mb-md-5 mt-5">
            <div className="not-top">
              <div className="row title-font mt-md-0">
                <h4 className="text-capitalize">User Management</h4>
              </div>
              <div className="back-div">
                <button
                  className="btn btn-secondary mt-3 mt-md-0 px-3"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row mt-3 d-flex justify-content-between align-items-center">
            <div className="col-6">
              <div className="d-flex">
                <div className="me-md-4">
                  <div
                    onClick={() => setCurrentScreen(1)}
                    className={
                      currentScreen === 1
                        ? "active-btn1 cursor-pointer"
                        : "inactive1 cursor-pointer"
                    }
                  >
                    <h4>Users</h4>
                  </div>
                </div>
                {user_role === "super_admin" && (
                  <div className="me-md-4 float-end">
                    <div
                      onClick={() => updatePage(2)}
                      className={
                        currentScreen === 2
                          ? "active-btn1 cursor-pointer"
                          : "inactive1 cursor-pointer"
                      }
                    >
                      <h4>Roles</h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-6 ">
              <div className="float-end">
                {isCanCreateUserIncluded && currentScreen === 1 && (
                  <div
                    className="add-cover1 cursor-pointer"
                    data-title={"add new user"}
                    onClick={() => setShowUserModal(true)}
                  >
                    <img src="/assets/img/plus.svg" alt="" />
                  </div>
                )}
                {currentScreen === 2 && (
                  <div
                    className="add-cover1 cursor-pointer"
                    data-title={"add new role"}
                    onClick={() => {
                      setShowRoleModal(true);
                    }}
                  >
                    <img src="/assets/img/plus.svg" alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="leader-card mt-md-3">
            <div className="card-body booking_card">
              {currentScreen === 1 && (
                <Fade>
                  <UsersPage
                    setShowUserModal={setShowUserModal}
                    showUserModal={showUserModal}
                    isCanCreateUserIncluded={isCanCreateUserIncluded}
                    isCanUpdateUserIncluded={isCanUpdateUserIncluded}
                    userRole={user_role}
                  />
                </Fade>
              )}
              {user_role === "super_admin" && (
                <>
                  {currentScreen === 2 && (
                    <Fade>
                      <RolesPage
                        setShowRoleModal={setShowRoleModal}
                        showRoleModal={showRoleModal}
                        setShowEditRoleModal={setShowEditRoleModal}
                        showEditRoleModal={showEditRoleModal}
                      />
                    </Fade>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default Settings;
