import React, { useEffect } from "react";

function RequestTokenPage({ setCurrentScreen, isLoading, handleSubmit }) {
  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="tv3-cover">
        <img
          src="/assets/img/logo1.svg"
          alt=""
          className="img-fluid logo1-size"
        />
      </div>
      <div className="login-text">
        <h4>Reset Password</h4>
      </div>
      <div className="create-btn-cover w-100">
        <div className="mar-t-1">
          <div className="token-font">
            <h6>A token will be sent to your email address</h6>
          </div>
          {!isLoading && (
            <button type="submit" className="btn btn-warning w-100">
              Request Token
            </button>
          )}
          {isLoading && (
            <button className="btn btn-warning w-100" disabled>
              Requesting...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default RequestTokenPage;
