import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useAddCategoryMutation,
  useAddShowMutation,
} from "../../../app/apiSlice";
import { useLocation } from "react-router-dom";
const AddNewCategory = ({
  setShowNewCatModal,
  showNewCateModal,
  setIsaReloadCates,
  refetchCategories,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const [selectedImage, setSelectedImage] = useState(null);

  const initialUserInput = {
    name: "",
    description: "",
  };

  const [userInput, setUserInput] = useState(initialUserInput);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [addCategory, { data, isSuccess, isError, error, isLoading }] =
    useAddCategoryMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, description } = userInput;

    if (name) {
      try {
        await addCategory({
          name,
          description,
          show_id: activeShow,
        });
      } catch (error) {
        console.error("Error adding category:", error);
      }
    } else {
      toast.error("Fill All Fields", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowNewCatModal(false);
      setUserInput(initialUserInput);
      setIsaReloadCates(true);
      refetchCategories().then(() => {
        setIsaReloadCates(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log("error", error);
      let errorMessages = error?.data?.message;
      let errorMessageString = "";

      for (const key in errorMessages) {
        if (errorMessages.hasOwnProperty(key)) {
          errorMessageString += errorMessages[key].join(", ") + ", ";
        }
      }
      errorMessageString = errorMessageString.slice(0, -2);
      toast.error(errorMessageString, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <Modal
        show={showNewCateModal}
        onHide={() => setShowNewCatModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Create New Show</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="name">
                  <h4>Category Name</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <img
                  src="/assets/img/profile.svg"
                  alt=""
                  className="text-box-img"
                />
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={userInput.name}
                  onChange={handleUserInput}
                  className="text-box-width12"
                  placeholder="Enter Category Name"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="description">
                  <h4>Category Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="description"
                  value={userInput.description}
                  onChange={handleUserInput}
                  className="text-area-width"
                  placeholder="Write text here..."
                ></textarea>
              </div>
            </div>
            <div className="d-block mt-4 mb-4 mb-md-0">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Create Category"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Creating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddNewCategory;
