import React from "react";
import RedirectPage from "./RedirectPage";
import secureLocalStorage from "react-secure-storage";

function ResultsProtectedRoute({ children }) {
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;

  const isCanViewResultsIncluded = permissions?.includes("can_view_results");

  return isCanViewResultsIncluded ? children : <RedirectPage />;
}

export default ResultsProtectedRoute;
