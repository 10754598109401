import React from "react";
import RedirectPage from "./RedirectPage";
import secureLocalStorage from "react-secure-storage";

function FirstTimePasswordProtectedRoute({ children }) {
  const reset_password = JSON?.parse(secureLocalStorage?.getItem("user"))?.user?.reset_password
  return reset_password === 1 ? children : <RedirectPage />;
}

export default FirstTimePasswordProtectedRoute;
