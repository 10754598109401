import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUpdateCategoryMutation } from "../../../app/apiSlice";

const EditCategory = ({
  setShowEditCatModal,
  showEditCatModal,
  selectedCategory,
  setIsaReloadCates,
  refetchCategories,
}) => {
  const [userInput, setUserInput] = useState([]);
  useEffect(() => {
    if (selectedCategory) setUserInput(selectedCategory);
  }, [selectedCategory]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isloading, setLoading] = useState(false);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [updateCategory, { data, isSuccess, isError, error, isLoading }] =
    useUpdateCategoryMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, description, id } = userInput;

    if (name) {
      try {
        await updateCategory({
          id,
          body: {
            name,
            description,
          },
        });
      } catch (error) {
        console.error("Error updating role:", error);
      }
    } else {
      toast.error("Select At Least One Permission", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Role Updated Successfully", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowEditCatModal(false);
      setIsaReloadCates(true);
      refetchCategories().then(() => {
        setIsaReloadCates(false);
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <Modal
        show={showEditCatModal}
        onHide={() => setShowEditCatModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Update Category</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="name">
                  <h4>Category Name</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <img
                  src="/assets/img/profile.svg"
                  alt=""
                  className="text-box-img"
                />
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={userInput.name}
                  onChange={handleUserInput}
                  className="text-box-width12"
                  placeholder="Enter Categroy Name"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="description">
                  <h4>Category Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="description"
                  value={userInput.description}
                  onChange={handleUserInput}
                  className="text-area-width"
                  placeholder="Write text here..."
                ></textarea>
              </div>
            </div>

            <div className="d-block mt-4 mb-4 mb-md-0">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Update Category"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Updating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default EditCategory;
