import React, { useEffect, useState } from "react";
import {
  useDeletePicturesMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from "../../../../app/apiSlice";
import AddUser from "./AddUser";
import LoadingButton from "../../../../components/LoadingButton";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import EditUser from "./EditUser";

function UsersPage({
  setShowUserModal,
  showUserModal,
  isCanCreateUserIncluded,
  isCanUpdateUserIncluded,
  userRole,
}) {
  const [users, setUsers] = useState([]);
  const [isUserReload, setIsUserReload] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const {
    data: allUsers,
    isLoading,
    refetch: refetchUsers,
  } = useGetAllUsersQuery();
  useEffect(() => {
    if (allUsers) {
      setUsers(allUsers?.data);
    }
  }, [allUsers]);

  const [
    deleteUser,
    { data: userData, isSuccess: isDeleteSuccess, isLoading: isDeleting },
  ] = useDeleteUserMutation();

  const handleClickDelete = async (user, i) => {
    setDeletingIndex(i);

    const result = await Swal.fire({
      title: `Are you sure you want to delete ${user?.full_name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    });
    if (result.isConfirmed) {
      const formData = new FormData();
      formData.append("user_id", user?.id);

      try {
        await deleteUser(formData);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success(userData.data.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsUserReload(true);
      refetchUsers().then(() => {
        setIsUserReload(false);
      });
    }
  }, [isDeleteSuccess]);

  const handleEditUserClick = (user) => {
    setShowEditUserModal(true);
    setSelectedUser(user);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="datatable table table-stripped table table-hover table-center mb-0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Station</th>
              <th>Role</th>
              {userRole === "super_admin" && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            <>
              {!isLoading && !isUserReload ? (
                <>
                  {users?.length === 0 ? (
                    <div className="no-data">
                      <h1> No User Available</h1>
                    </div>
                  ) : (
                    users?.map((user, i) => {
                      const userRoleTitle = user.role.title
                        .toLowerCase()
                        .replace(/\s+/g, "_");
                      return (
                        <tr key={i}>
                          <td className="text-capitalize">{user.id}</td>
                          <td className="text-capitalize">{user?.full_name}</td>
                          <td>{user?.email}</td>
                          <td>{user?.phone_number?.number || "N/A"}</td>
                          <td className="text-capitalize">
                            {userRoleTitle === "super_admin"
                              ? "All"
                              : user?.station?.name}
                          </td>
                          <td className="text-capitalize">
                            {user?.role?.title}
                          </td>
                          {userRole === "super_admin" && (
                            <td>
                              <div className="d-flex">
                                {isCanUpdateUserIncluded && (
                                  <button
                                    className="btn btn-warning2"
                                    onClick={() => handleEditUserClick(user)}
                                  >
                                    Update
                                  </button>
                                )}
                                <button
                                  className="btn btn-danger2 ms-4"
                                  disabled={isDeleting && deletingIndex === i}
                                  onClick={() => handleClickDelete(user, i)}
                                >
                                  {isDeleting && deletingIndex === i
                                    ? "Deleting..."
                                    : "Delete"}
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </>
              ) : (
                <div className="no-data">
                  <LoadingButton />
                </div>
              )}
            </>
          </tbody>
        </table>
      </div>
      {isCanCreateUserIncluded && (
        <AddUser
          showUserModal={showUserModal}
          setShowUserModal={setShowUserModal}
          refetchUsers={refetchUsers}
          setIsUserReload={setIsUserReload}
        />
      )}
      {isCanUpdateUserIncluded && (
        <EditUser
          showEditUserModal={showEditUserModal}
          setShowEditUserModal={setShowEditUserModal}
          refetchUsers={refetchUsers}
          setIsUserReload={setIsUserReload}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
}

export default UsersPage;
