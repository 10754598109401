import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "../../components/partials/Nav";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { DotLoader } from "react-spinners";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  useAddSeasonMutation,
  useGetShowSeasonQuery,
  useManageVoteMutation,
} from "../../app/apiSlice";
import LoadingPage from "../../components/LoadingPage";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const SelectedShowPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");

  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const isCanViewContestantIncluded = permissions?.includes(
    "can_view_contestant"
  );
  const isCanCreateShowIncluded = permissions?.includes("can_create_show");

  const [isReloadSeasons, setIsaReloadSeasons] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#febf4c");
  const [showSeasons, setShowSeasons] = useState([]);
  const [seasonEditModal, setSeasonEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const [expandSearch, setExpandSearch] = useState(false);
  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const {
    data: seasons,
    isLoading: isSeasonLoading,
    refetch: refetchSeasons,
  } = useGetShowSeasonQuery({
    showID: activeShow,
  });

  useEffect(() => {
    if (seasons) {
      setShowSeasons(seasons.data);
    }
  }, [seasons]);

  const [
    addSeason,
    { data, isSuccess, isError, error, isLoading: isAddSeason },
  ] = useAddSeasonMutation();

  const createNewSeason = async (e) => {
    e.preventDefault();

    Swal.fire({
      title:
        "Creating new season overrides the current active season. Are you sure you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("show_id", activeShow);
        formData.append("status", "active");
        try {
          await addSeason(formData);
        } catch (error) {
          console.error("Error adding season:", error);
        }
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsaReloadSeasons(true);
      refetchSeasons().then(() => {
        setIsaReloadSeasons(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  const handleSeasonClick = (season) => {
    if (code === "sdg") {
      navigate(
        `/reality-show/${code}/${season?.code}/categories?show=${activeShow}&season=${season?.id}`
      );
    } else {
      navigate(
        `/reality-show/${code}/${season?.code}?show=${activeShow}&season=${season?.id}`
      );
    }
  };

  const newData = [...showSeasons];
  const sortedData = newData.sort((a, b) => b.id - a.id);

  const filteredList = sortedData?.filter((item) => {
    if (search === "") {
      return item;
    } else if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });

  const [
    manageVote,
    {
      data: voteData,
      isSuccess: isVoteSuccess,
      isError: isVoteError,
      error: voteError,
      isLoading,
    },
  ] = useManageVoteMutation();

  const handleChangeSeasonStatus = async (season) => {
    const formData = new FormData();
    formData.append("show_id", activeShow);
    formData.append("season_id", season.id);
    season.vote_checker[0].status.toLowerCase() === "inactive"
      ? formData.append("status", "active")
      : formData.append("status", "inactive");
    let message;
    if (season.vote_checker[0].status.toLowerCase() === "inactive") {
      message = "Are you sure you want to enable voting?";
    } else {
      message = "Are you sure you want to disable voting?";
    }

    Swal.fire({
      title: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await manageVote(formData);
        } catch (error) {
          console.error("Error updating vote status:", error);
        }
      }
    });
  };
  useEffect(() => {
    if (isVoteSuccess) {
      toast.success(voteData?.data, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsaReloadSeasons(true);
      refetchSeasons().then(() => {
        setIsaReloadSeasons(false);
      });
    }
  }, [isVoteSuccess]);

  useEffect(() => {
    if (isVoteError) {
      console.log("error", voteError);
      toast.error(voteError?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isVoteError]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />

      <div className="container body-width">
        <div className="col-md-12">
          <div className="indication1">
            <div
              className="d-flex justify-content-between cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <div className="">
                <img src="/assets/img/home.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Home</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate("/reality-shows")}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Reality Shows</h4>
              </div>
            </div>

            <div className="d-flex justify-content-between ms-2">
              <div className="">
                <img src="/assets/img/arr-right.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1 disabled-color text-capitalize">
                  {convertSnakeCaseToTitleCase(code)}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
            <div className="col-md-6 col-12">
              <div className="title-font text-center text-md-start">
                <h4 className="text-capitalize">Seasons</h4>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex float-end mt-2 mt-md-0">
                <div
                  className={
                    expandSearch === true ? "search-active me-2" : "search me-2"
                  }
                >
                  <div
                    className="icon"
                    onClick={() => setExpandSearch((prev) => !prev)}
                  ></div>
                  <div className="input">
                    <input
                      type="text"
                      id="seacrh-box"
                      placeholder={expandSearch === true ? "Search..." : " "}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                {isCanCreateShowIncluded && (
                  <div>
                    <div
                      className="add-cover1 cursor-pointer"
                      data-title="add new season"
                      onClick={createNewSeason}
                    >
                      <img src="/assets/img/plus.svg" alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            {!isSeasonLoading && !isReloadSeasons ? (
              <>
                {filteredList?.map((season, i) => {
                  return (
                    <div className="col-md-4 mt-4" key={i}>
                      <div>
                        <div
                          className={
                            season?.status === "active"
                              ? isCanViewContestantIncluded
                                ? "show-card-background-c-active cursor-pointer"
                                : "show-card-background-c-active"
                              : isCanViewContestantIncluded
                              ? "show-card-background-c cursor-pointer"
                              : "show-card-background-c"
                          }
                        >
                          {season?.status.toLowerCase() === "active" && (
                            <div className="d-flex absolute-constentant-bg">
                              {season?.vote_checker.length > 0 && (
                                <div
                                  className="edit-contestant-bg ms-2  show-title-background1-edit cursor-pointer"
                                  onClick={() =>
                                    handleChangeSeasonStatus(season)
                                  }
                                >
                                  <h4 className="text-capitalize">
                                    {season?.vote_checker[0]?.status?.toLowerCase() ===
                                    "inactive"
                                      ? "Enable Vote"
                                      : "Disable Vote"}
                                  </h4>
                                </div>
                              )}
                            </div>
                          )}
                          <div
                            className="show-card-inner inner1"
                            onClick={() => {
                              isCanViewContestantIncluded &&
                                handleSeasonClick(season);
                            }}
                          >
                            <div className="show-title-background1 py-2 px-3">
                              <h4>{moment(season?.created_at).format("LL")}</h4>
                            </div>
                            <div className="show-title1">
                              <h4>{season?.name}</h4>
                              <h3>{season?.status}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {isLoading && <LoadingPage />}
                {isCanCreateShowIncluded && (
                  <div className="col-md-4 my-4">
                    <div
                      className="show-card-background1"
                      onClick={createNewSeason}
                    >
                      {showSeasons?.length === 0 && (
                        <div className="show-title-background1 bg1">
                          <h4 className="my-2">Create New Season</h4>
                        </div>
                      )}

                      <div className="show-card-inner1">
                        <img
                          src="/assets/img/addIcon.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="loader-h">
                <DotLoader
                  color={color}
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default SelectedShowPage;
