import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useAddUserMutation,
  useGetAllRolesQuery,
  useGetAllStationShowsQuery,
  useGetAllStationsQuery,
} from "../../../../app/apiSlice";
import { DotLoader } from "react-spinners";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
const AddUser = ({
  showUserModal,
  setShowUserModal,
  refetchUsers,
  setIsUserReload,
}) => {
  const initialUserInput = {
    email: "",
    name: "",
    phone_number: "",
    role_id: "",
    station_id: "",
  };
  let [color, setColor] = useState("#febf4c");
  let [loading, setLoading] = useState(true);
  const [producerRole, setProducerRole] = useState({});
  const [userInput, setUserInput] = useState(initialUserInput);
  const [checkedShowIds, setCheckedShowIds] = useState([]);
  const [checkedUserShowIds, setCheckedUserShowIds] = useState([]);
  const profile = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.user;
  const user_role = profile.role.title.toLowerCase().replace(/\s+/g, "_");
  const user_station = profile.station;
  const [shows, setShows] = useState([]);
  const [userShows, setUserShows] = useState([]);
  const { data: allRoles } = useGetAllRolesQuery();
  const { data: allStations } = useGetAllStationsQuery();
  useEffect(() => {
    if (allRoles) {
      const producer = allRoles?.data?.find(
        (item) => item.title.toLowerCase() === "producer"
      );
      setProducerRole(producer);
    }
  }, [allRoles]);
  const inputPhoneRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement === inputPhoneRef.current) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (document.activeElement === inputPhoneRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  const {
    data: allStationShows,
    isLoading: isStationShowsLoading,
    isFetching,
  } = useGetAllStationShowsQuery(
    { stationID: userInput.station_id },
    {
      skip: !userInput.station_id,
    }
  );

  const { data: allUserStationShows } = useGetAllStationShowsQuery(
    { stationID: user_station?.id },
    {
      skip: user_role === "super_admin",
    }
  );

  useEffect(() => {
    if (allStationShows) {
      setShows(allStationShows?.data?.shows);
    }
  }, [allStationShows]);
  useEffect(() => {
    if (allUserStationShows) {
      setUserShows(allUserStationShows?.data?.shows);
    }
  }, [allUserStationShows]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;

    let updatedShows;
    if (name === "allshows") {
      updatedShows = shows.map((show) => ({ ...show, isChecked: checked }));
    } else {
      updatedShows = shows.map((show) =>
        show.code === name ? { ...show, isChecked: checked } : show
      );
    }
    setShows(updatedShows);

    const newCheckedShowIds = updatedShows
      .filter((show) => show.isChecked)
      .map((show) => show.id);
    setCheckedShowIds(newCheckedShowIds);
  };

  const handleUserShowChange = (e) => {
    const { name, checked } = e.target;

    let updatedShows;
    if (name === "allUserShows") {
      updatedShows = userShows.map((show) => ({ ...show, isChecked: checked }));
    } else {
      updatedShows = userShows.map((show) =>
        show.code === name ? { ...show, isChecked: checked } : show
      );
    }
    setUserShows(updatedShows);

    const newCheckedShowIds = updatedShows
      .filter((show) => show.isChecked)
      .map((show) => show.id);
    setCheckedUserShowIds(newCheckedShowIds);
  };

  const [addUser, { data, isSuccess, isError, error, isLoading }] =
    useAddUserMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, name, phone_number, role_id, station_id } = userInput;
    const allCheckedShowIds =
      user_role === "super_admin" ? checkedShowIds : checkedUserShowIds;
    if (email && name) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone_number", phone_number);
      formData.append("email", email);
      if (user_role === "super_admin") {
        formData.append("role_id", parseInt(role_id));
      } else {
        formData.append("role_id", producerRole?.id);
      }
      formData.append("is_staff", 1);
      if (user_role === "super_admin") {
        formData.append("station_id", parseInt(station_id));
      } else {
        formData.append("station_id", user_station?.id);
      }
      if (user_role === "super_admin") {
        checkedShowIds.forEach((id, index) => {
          formData.append(`show_ids[${index}]`, id);
        });
      } else {
        checkedUserShowIds.forEach((id, index) => {
          formData.append(`show_ids[${index}]`, id);
        });
      }

      try {
        await addUser(formData);
      } catch (error) {
        console.error("Error adding user:", error);
      }
    } else {
      toast.error("Fullname, role and email are required", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setCheckedShowIds([]);
      setCheckedUserShowIds([]);
      setUserInput(initialUserInput);
      setShowUserModal(false);
      setIsUserReload(true);
      refetchUsers().then(() => {
        setIsUserReload(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      let errorMessages = error?.data?.message;
      let errorMessageString = "";

      for (const key in errorMessages) {
        if (errorMessages.hasOwnProperty(key)) {
          errorMessageString += errorMessages[key].join(", ") + ", ";
        }
      }
      errorMessageString = errorMessageString.slice(0, -2);
      toast.error(errorMessageString, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <Modal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Create New User</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="name">
                  <h4>Full Name</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={userInput.name}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter FullName"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="email">
                  <h4>Email</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={userInput.email}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="example@gmail.com"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="phone_number">
                  <h4>Phone Number</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="number"
                  name="phone_number"
                  id="phone_number"
                  value={userInput.phone_number}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter Phone Number"
                  ref={inputPhoneRef}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-6 d-block mt-4">
                  <div className="email-font">
                    <label htmlFor="role_id">
                      <h4>Role</h4>
                    </label>
                  </div>
                  {user_role === "super_admin" ? (
                    <div className="text-box-cover">
                      <select
                        name="role_id"
                        id="role_id"
                        className="select-box-width"
                        value={userInput?.role_id}
                        onChange={handleUserInput}
                        required
                      >
                        <option value="">-- select --</option>
                        {allRoles?.data?.map((role, index) => (
                          <option key={index} value={role?.id}>
                            {role?.title}
                          </option>
                        ))}
                      </select>

                      <img
                        src="/assets/img/arrow-down.svg"
                        alt=""
                        className="select-box-img"
                      />
                    </div>
                  ) : (
                    <div className="text-box-cover">
                      <input
                        type="text"
                        value={producerRole?.title}
                        className="text-box-width1"
                        readOnly
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-6 d-block mt-4">
                  <div className="email-font">
                    <label htmlFor="station_id">
                      <h4>Station</h4>
                    </label>
                  </div>
                  {user_role === "super_admin" ? (
                    <div className="text-box-cover">
                      <select
                        name="station_id"
                        id="station_id"
                        className="select-box-width"
                        value={userInput?.station_id}
                        onChange={handleUserInput}
                        required
                      >
                        <option value="">-- select --</option>
                        {allStations?.data?.map((station, index) => (
                          <option key={index} value={station?.id}>
                            {station?.name}
                          </option>
                        ))}
                      </select>

                      <img
                        src="/assets/img/arrow-down.svg"
                        alt=""
                        className="select-box-img"
                      />
                    </div>
                  ) : (
                    <div className="text-box-cover">
                      <input
                        type="text"
                        value={user_station?.name}
                        className="text-box-width1"
                        readOnly
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-6"></div>
                {user_role === "super_admin" ? (
                  <>
                    {userInput?.station_id !== "" && (
                      <>
                        {parseInt(userInput?.role_id) !== 1 && (
                          <div className="col-md-6 d-block">
                            <div className="station-cover">
                              {!isStationShowsLoading && !isFetching ? (
                                <>
                                  <div className="check-width">
                                    <label
                                      htmlFor="allshows"
                                      className="check-cover text-capitalize cursor-pointer"
                                    >
                                      Select All
                                      <input
                                        type="checkbox"
                                        id="allshows"
                                        name="allshows"
                                        checked={
                                          shows.filter(
                                            (show) => show.isChecked !== true
                                          ).length < 1
                                        }
                                        className="checkbox-design radio_check ms-2"
                                        onChange={handleChange}
                                      />
                                    </label>
                                  </div>
                                  {shows?.map((show, i) => {
                                    return (
                                      <div className="check-width" key={i}>
                                        <label
                                          htmlFor={`show${i}`}
                                          className="check-cover text-capitalize cursor-pointer"
                                        >
                                          <input
                                            type="checkbox"
                                            id={`show${i}`}
                                            name={show?.code}
                                            checked={show?.isChecked || false}
                                            className="checkbox-design radio_check"
                                            onChange={handleChange}
                                          />
                                          {show?.name}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="no-data-1">
                                  <DotLoader
                                    color={color}
                                    loading={loading}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="col-md-6 d-block">
                    <div className="station-cover">
                      <div className="check-width">
                        <label
                          htmlFor="allUserShows"
                          className="check-cover text-capitalize cursor-pointer"
                        >
                          Select All
                          <input
                            type="checkbox"
                            id="allUserShows"
                            name="allUserShows"
                            checked={
                              userShows.filter(
                                (show) => show.isChecked !== true
                              ).length < 1
                            }
                            className="checkbox-design radio_check ms-2"
                            onChange={handleUserShowChange}
                          />
                        </label>
                      </div>
                      {userShows?.map((userShow, i) => {
                        return (
                          <div className="check-width" key={i}>
                            <label
                              htmlFor={`userShow${i}`}
                              className="check-cover text-capitalize cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                id={`userShow${i}`}
                                name={userShow?.code}
                                checked={userShow?.isChecked || false}
                                className="checkbox-design radio_check"
                                onChange={handleUserShowChange}
                              />
                              {userShow?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block mt-4 mb-4 mb-md-0">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Create User"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Creating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddUser;
