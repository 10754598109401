import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAddPicturesMutation,
  useGetSeasonAlbumsQuery,
  useGetSeasonContestantsQuery,
} from "../../../app/apiSlice";
import { setAlbumType, setIsPicFromCont } from "../../../app/showsSlice";

const AddPicturesSection = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    arrows: true,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState([]);
  const initialUserInput = {
    img_title: "",
    contestant: "",
    album: "",
  };
  const [userInput, setUserInput] = useState(initialUserInput);
  const [contestants, setContestants] = useState([]);
  const [albums, setAlbums] = useState([]);

  const location = useLocation();
  const { code, season_code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");

  const {
    data: allcontestants,
    isLoading: isContestantsLoading,
    refetch: refetchContestants,
  } = useGetSeasonContestantsQuery({
    showID: activeShow,
    seasonID: season,
  });
  const {
    data: allAlbums,
    isLoading: isAlbumsLoading,
    refetch: refetchAlbums,
  } = useGetSeasonAlbumsQuery({
    showID: activeShow,
    seasonID: season,
  });

  useEffect(() => {
    if (allcontestants) {
      setContestants(allcontestants.data.contestants);
    }
  }, [allcontestants]);
  useEffect(() => {
    if (allAlbums) {
      setAlbums(allAlbums.data.albums);
    }
  }, [allAlbums]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const handleAlbumInput = (event) => {
    const selectedValue = event.target.value;
    const [id, code] = selectedValue.split("*");

    const numericId = parseInt(id, 10);

    setUserInput({
      ...userInput,
      album: {
        id: numericId,
        code: code,
      },
    });
  };
  const sliderRef = useRef(null);
  const [isloading, setLoading] = useState(false);

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      setImages(file);
      return URL.createObjectURL(file);
    });
    setImages(selectedFiles);
    setSelectedImages((previousImages) => previousImages?.concat(imagesArray));
  };

  const [addPictures, { data, isSuccess, isError, error, isLoading }] =
    useAddPicturesMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedImages.length === 0) {
      toast.error("Image is required", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    const formData = new FormData();
    formData.append("img_title", userInput?.img_title);
    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      formData.append("images[]", file);
    }
    formData.append("img_type", "gallery");
    formData.append("show_id", activeShow);
    formData.append("season_id", season);
    formData.append("album_id", userInput.album?.id);
    formData.append("cont_id", userInput?.contestant);

    try {
      await addPictures(formData);
    } catch (error) {
      console.error("Error adding pictures:", error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedImages([]);
      setUserInput(initialUserInput);
      dispatch(setIsPicFromCont(true));
      dispatch(setAlbumType("gallery"));
      navigate(
        `/gallery/${code}/${season_code}/${userInput?.album?.code}?show=${activeShow}&season=${season}&album=${userInput?.album?.id}`
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="upload-img-cover">
          <div
            className={selectedImages?.length >= 1 ? "img-cont" : "img-cont-1"}
          >
            <input
              type="file"
              id="uploadBtn"
              onChange={(e) => {
                onSelectFile(e);
              }}
              multiple
              accept="image/png, image/jpeg, image/jpg, image/webp"
            />
            {selectedImages?.length >= 1 ? (
              <Slider ref={sliderRef} {...settings}>
                {selectedImages &&
                  selectedImages.map((image, index) => {
                    return (
                      <label htmlFor="uploadBtn">
                        <div className="upload-img-cover1">
                          <img
                            src={image}
                            alt=""
                            className="upload-btn-img2"
                            key={image}
                          />
                          <input
                            type="button"
                            onClick={() =>
                              setSelectedImages(
                                selectedImages.filter((e) => e !== image)
                              )
                            }
                            className="btn bg-danger-light delete-img"
                            value="delete"
                          />
                        </div>
                      </label>
                    );
                  })}
              </Slider>
            ) : (
              <label htmlFor="uploadBtn">
                <div className="upload-cover cursor-pointer">
                  <div>
                    <img src="/assets/img/upload.svg" alt="" />
                  </div>
                  <div>
                    <h4>Click to upload image(s)</h4>
                  </div>

                  <div className="browse-cover d-flex">
                    <div className="my-auto">
                      <img
                        src="/assets/img/browse.svg"
                        alt=""
                        className="browse-icon me-2 my-auto"
                      />
                    </div>
                    <h4 className="my-auto"> Browse file</h4>
                  </div>
                </div>
              </label>
            )}
          </div>
        </div>

        <div className="d-block mt-4">
          <div className="email-font">
            <label htmlFor="img_title">
              <h4>Image Title</h4>
            </label>
          </div>
          <div className="text-box-cover">
            <input
              type="text"
              name="img_title"
              id="img_title"
              value={userInput.img_title}
              onChange={handleUserInput}
              className="text-box-width1"
              placeholder="Enter image title"
              required
            />
          </div>
          <div className="d-block mt-4">
            <div className="email-font">
              <label htmlFor="album">
                <h4>Album</h4>
              </label>
            </div>
            <div className="text-box-cover">
              <select
                name="album"
                id="album"
                className="select-box-width"
                value={`${userInput?.album?.id}*${userInput?.album?.code}`}
                onChange={handleAlbumInput}
                required
              >
                <option value="">-- select --</option>
                {albums?.map((ele, index) => (
                  <option key={index} value={`${ele?.id}*${ele?.code}`}>
                    {ele?.name}
                  </option>
                ))}
              </select>

              <img
                src="/assets/img/arrow-down.svg"
                alt=""
                className="select-box-img"
              />
            </div>
          </div>
          <div className="d-block mt-4">
            <div className="email-font">
              <label htmlFor="contestant">
                <h4>Contestant</h4>
              </label>
            </div>
            <div className="text-box-cover">
              <select
                name="contestant"
                id="contestant"
                className="select-box-width"
                value={userInput?.contestant?.id}
                onChange={handleUserInput}
                required
              >
                <option value="">-- select --</option>
                {contestants?.map((ele, index) => (
                  <option key={index} value={ele?.id}>
                    {ele?.stage}
                  </option>
                ))}
              </select>
              <img
                src="/assets/img/arrow-down.svg"
                alt=""
                className="select-box-img"
              />
            </div>
          </div>
        </div>

        <div classame="d-block">
          {!isLoading && (
            <div className="create-btn-cover mt-4">
              <input
                type="submit"
                className="btn btn-warning create-font"
                name=""
                value="Upload Image(s)"
              />
              <img
                src="/assets/img/arrow-right.svg"
                alt=""
                className="create-btn-img1 d-none d-md-block"
              />
            </div>
          )}
          {isLoading && (
            <div className="create-btn-cover mt-4">
              {/* <LoadingButton /> */}

              <input
                className="btn btn-warning create-font"
                disabled
                name=""
                value="Uploading..."
              />
              <img
                src="/assets/img/arrow-right.svg"
                alt=""
                className="create-btn-img1 d-none d-md-block"
              />
            </div>
          )}
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddPicturesSection;
