import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPicFromCont: true,
  isVideoFromCont: true,
  albumType: "",
};

export const showsSlice = createSlice({
  name: "shows",
  initialState,
  reducers: {
    setAlbumType: (state, action) => {
      state.albumType = action.payload;
    },
    setIsPicFromCont: (state, action) => {
      state.isPicFromCont = action.payload;
    },
    setIsVideoFromCont: (state, action) => {
      state.isVideoFromCont = action.payload;
    },
  },
});

export const {
  setIsPicFromCont,
  setAlbumType,
  setIsVideoFromCont,
} = showsSlice.actions;

export default showsSlice.reducer;
