export function deepCompare(oldObj, newObj) {
    const changedFields = {};

    function arraysAreEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }

    for (const key in newObj) {
        if (newObj.hasOwnProperty(key)) {
            if (Array.isArray(oldObj[key]) && Array.isArray(newObj[key])) {
                if (!arraysAreEqual(oldObj[key], newObj[key])) {
                    changedFields[key] = {
                        oldValue: newObj[key],
                        newValue: oldObj[key],
                    };
                }
            } else if (oldObj.hasOwnProperty(key) && oldObj[key] !== newObj[key]) {
                changedFields[key] = {
                    oldValue: newObj[key],
                    newValue: oldObj[key],
                };
            }
        }
    }

    return changedFields;
}
