import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import secureLocalStorage from "react-secure-storage";

export const realityApi = createApi({
  reducerPath: "realityApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://realityshowsapi-staging.3news.com/",
    baseUrl: "https://realityshowsapi.3news.com/",
    prepareHeaders: (headers, { getState }) => {
      const token = JSON?.parse(secureLocalStorage?.getItem("user"))?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => ({
        url: "api/v1/auth/login",
        method: "POST",
        body,
      }),
    }),
    getAllShows: builder.query({
      query: () => "api/v2/shows",
    }),
    getAllStations: builder.query({
      query: () => "api/v1/stations",
    }),
    getAllStationShows: builder.query({
      query: ({ stationID }) => `api/v1/reality_shows?station_id=${stationID}`,
    }),
    getAllPermissions: builder.query({
      query: () => "api/v1/permissions",
    }),
    getAllUsers: builder.query({
      query: () => "api/v2/auth/users",
    }),
    getAllRoles: builder.query({
      query: () => "api/v1/roles",
    }),

    addShow: builder.mutation({
      query: (body) => ({
        url: "api/v1/reality_shows/create",
        method: "POST",
        body,
        headers: {},
      }),
    }),
    addCategory: builder.mutation({
      query: (body) => ({
        url: "api/v2/teams",
        method: "POST",
        body,
        headers: {},
      }),
    }),
    addUser: builder.mutation({
      query: (body) => ({
        url: "api/v2/auth/register",
        method: "POST",
        body,
        headers: {},
      }),
    }),
    addRole: builder.mutation({
      query: (body) => ({
        url: "api/v1/roles/create",
        method: "POST",
        body,
      }),
    }),
    getShowSeason: builder.query({
      query: ({ showID }) => `api/v1/seasons/show/${showID}`,
    }),
    addSeason: builder.mutation({
      query: (body) => ({
        url: "api/v1/seasons/create",
        method: "POST",
        body,
        headers: {},
      }),
    }),
    manageVote: builder.mutation({
      query: (body) => ({
        url: "api/v1/seasons/status",
        method: "POST",
        body,
        headers: {},
      }),
    }),
    getSeasonContestants: builder.query({
      query: ({ showID, seasonID, teamID }) => {
        let queryString = `api/v3/contestants?filter[show_id]=${showID}&filter[season_id]=${seasonID}`;

        if (teamID !== undefined && teamID !== "" && teamID !== null) {
          queryString += `&filter[team_id]=${teamID}`;
        }
        return queryString;
      },
    }),
    getAllCategories: builder.query({
      query: ({ showID }) => `api/v2/teams?filter[show_id]=${showID}`,
    }),
    getContestant: builder.query({
      query: ({ contestantID }) => `/api/v3/contestants/${contestantID}`,
    }),
    getCategory: builder.query({
      query: ({ teamID }) => `api/v2/teams/${teamID}`,
    }),
    getUserShows: builder.query({
      query: ({ userID }) => `api/v2/auth/user/${userID}/shows`,
    }),

    getSeasonAlbums: builder.query({
      query: ({ showID, seasonID }) =>
        `api/v4/albums?filter[show_id]=${showID}&filter[season_id]=${seasonID}`,
    }),
    getWeeks: builder.query({
      query: ({ showID, seasonID }) =>
        `api/v3/weeks?filter[week_type]=scores&filter[show_id]=${showID}&filter[season_id]=${seasonID}&filter[status]=active,inactive`,
    }),
    getWeek: builder.query({
      query: ({ showID, seasonID }) =>
        `api/v3/weeks?filter[week_type]=scores&filter[show_id]=${showID}&filter[season_id]=${seasonID}`,
    }),
    getScores: builder.query({
      query: ({ showID, seasonID, weekID }) => {
        let queryString = `api/v4/scores?filter[show_id]=${showID}&filter[season_id]=${seasonID}&filter[week_id]=${weekID}`;

        return queryString;
      },
    }),
    getGeneralVotes: builder.query({
      query: ({ showID, seasonID, teamID }) => {
        let queryString = `api/v3/votes_count?sort=-votes_count&filter[show_id]=${showID}&filter[season_id]=${seasonID}&sort=-votes_count`;

        if (teamID !== undefined && teamID !== "" && teamID !== null) {
          queryString += `&team_id=${teamID}`;
        }
        return queryString;
      },
    }),
    getViewersVotes: builder.query({
      query: ({ showID, seasonID, teamID }) => {
        let queryString = `api/v3/votes_count?sort=-votes_count&filter[show_id]=${showID}&filter[season_id]=${seasonID}&sort=-viewers_votes_count`;

        if (teamID !== undefined && teamID !== "" && teamID !== null) {
          queryString += `&team_id=${teamID}`;
        }
        return queryString;
      },
    }),

    getTransactions: builder.query({
      query: ({
        showID,
        seasonID,
        fromValue,
        toValue,
        contID,
        page,
        paymentType,
        paymentSource,
        paymentStatus,
        catID,
        reference,
      }) => {
        let queryString = `api/v1/transactions?show_id=${showID}&season_id=${seasonID}&from=${fromValue}&to=${toValue}&page=${page}`;

        if (contID !== undefined && contID !== "") {
          queryString += `&filter[cont_id]=${contID}`;
        }
        if (catID !== undefined && catID !== "") {
          queryString += `&team_id=${catID}`;
        }
        if (paymentType !== undefined && paymentType !== "") {
          queryString += `&filter[payment_type]=${paymentType}`;
        }
        if (paymentSource !== undefined && paymentSource !== "") {
          queryString += `&filter[source]=${paymentSource}`;
        }
        if (paymentStatus !== undefined && paymentStatus !== "") {
          queryString += `&filter[status]=${paymentStatus}`;
        }
        if (reference !== "" && reference !== undefined) {
          queryString += `&filter[vote_reference]=${reference}`;
        }

        return queryString;
      },
    }),

    exportTransactions: builder.query({
      query: ({
        showID,
        seasonID,
        fromValue,
        toValue,
        contID,
        page,
        paymentType,
        paymentSource,
        paymentStatus,
      }) =>
        `api/v1/transactions/export?show_id=${showID}&season_id=${seasonID}&from=${fromValue}&to=${toValue}&filter[cont_id]=${contID}&page=${page}&filter[payment_type]=${paymentType}&filter[source]=${paymentSource}&filter[status]=${paymentStatus}`,
      responseType: "blob",
    }),

    getPictures: builder.query({
      query: ({ albumID, showID, seasonID }) =>
        `api/v3/pictures?filter[album_id]=${albumID}&filter[show_id]=${showID}&filter[season_id]=${seasonID}`,
    }),
    getVideos: builder.query({
      query: ({ showID, seasonID, albumID }) =>
        `api/v3/videos?filter[show_id]=${showID}&filter[season_id]=${seasonID}&filter[album_id]=${albumID}`,
    }),

    addContestant: builder.mutation({
      query: (body) => ({
        url: "api/v4/contestants",
        method: "POST",
        body,
        headers: {},
      }),
    }),
    addAlbum: builder.mutation({
      query: (body) => ({
        url: "api/v3/albums",
        method: "POST",
        body,
        headers: {},
      }),
    }),
    updateScore: builder.mutation({
      query: (body) => ({
        url: "api/v3/scores/${id}",
        method: "PUT",
        body,
      }),
    }),
    addScores: builder.mutation({
      query: (body) => ({
        url: "api/v4/scores",
        method: "POST",
        body,
      }),
    }),
    requestPasswordToken: builder.mutation({
      query: (body) => ({
        url: "api/v2/auth/password_reset_token",
        method: "POST",
        body,
      }),
    }),
    confirmPasswordToken: builder.mutation({
      query: (body) => ({
        url: "api/v2/auth/validate_code",
        method: "POST",
        body,
      }),
    }),

    addPictures: builder.mutation({
      query: (body) => ({
        url: "api/v3/pictures",
        method: "POST",
        body,
      }),
    }),
    addVideos: builder.mutation({
      query: (body) => ({
        url: "api/v3/videos",
        method: "POST",
        body,
      }),
    }),
    createNewVoteWeek: builder.mutation({
      query: (body) => ({
        url: "api/v4/weeks",
        method: "POST",
        body,
      }),
    }),

    updatePassword: builder.mutation({
      query: (body) => ({
        url: "api/v2/auth/reset_password",
        method: "POST",
        body,
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: "api/v2/auth/user?_method=put",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "api/v2/auth/reset_password_web",
        method: "POST",
        body,
      }),
    }),
    deleteVideo: builder.mutation({
      query: ({ id }) => ({
        url: `api/v3/videos/${id}`,
        method: "DELETE",
      }),
    }),
    deleteUser: builder.mutation({
      query: (body) => ({
        url: `api/v2/auth/account-delete`,
        method: "POST",
        body,
      }),
    }),
    deleteRole: builder.mutation({
      query: ({ id }) => ({
        url: `api/v1/roles/${id}`,
        method: "DELETE",
      }),
    }),
    deletePictures: builder.mutation({
      query: ({ id }) => ({
        url: `api/v4/contestants/picture/${id}`,
        method: "DELETE",
      }),
    }),
    updateRole: builder.mutation({
      query: ({ id, body }) => ({
        url: `api/v1/roles/${id}/update`,
        method: "PUT",
        body,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `api/v2/teams/${id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useUpdateCategoryMutation,
  useGetCategoryQuery,
  useGetAllStationsQuery,
  useLoginUserMutation,
  useGetAllShowsQuery,
  useAddShowMutation,
  useGetShowSeasonQuery,
  useAddSeasonMutation,
  useManageVoteMutation,
  useGetSeasonContestantsQuery,
  useAddContestantMutation,
  useGetWeeksQuery,
  useGetScoresQuery,
  useAddScoresMutation,
  useUpdateScoreMutation,
  useGetWeekQuery,
  useGetGeneralVotesQuery,
  useGetViewersVotesQuery,
  useCreateNewVoteWeekMutation,
  useAddPicturesMutation,
  useGetTransactionsQuery,
  useGetSeasonAlbumsQuery,
  useAddAlbumMutation,
  useGetPicturesQuery,
  useGetVideosQuery,
  useGetContestantQuery,
  useDeleteVideoMutation,
  useDeletePicturesMutation,
  useAddVideosMutation,
  useExportTransactionsQuery,
  useGetAllUsersQuery,
  useAddUserMutation,
  useGetAllRolesQuery,
  useRequestPasswordTokenMutation,
  useConfirmPasswordTokenMutation,
  useUpdatePasswordMutation,
  useResetPasswordMutation,
  useGetAllPermissionsQuery,
  useAddRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
  useGetAllCategoriesQuery,
  useGetAllStationShowsQuery,
  useAddCategoryMutation,
  useDeleteUserMutation,
  useGetUserShowsQuery,
  useUpdateUserMutation,
} = realityApi;
