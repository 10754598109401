import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RequestTokenPage from "./RequestTokenPage";
import TokenConfirmationPage from "./TokenConfirmationPage";
import NewPasswordPage from "./NewPasswordPage";
import { useRequestPasswordTokenMutation } from "../../app/apiSlice";
import secureLocalStorage from "react-secure-storage";
const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [tokenCode, setTokenCode] = useState("");

  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const [currentScreen, setCurrentScreen] = useState(1);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [requestPasswordToken, { data, isSuccess, isError, error, isLoading }] =
    useRequestPasswordTokenMutation();
  const email = JSON?.parse(secureLocalStorage?.getItem("user"))?.user?.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      await requestPasswordToken({ email });
    } else {
      toast.error("Email is Required", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setCurrentScreen(2);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      console.log("error", error.data);
      toast.error("Invalid Email", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="login-bg-cover">
        <img src="/assets/img/login-bg.png" className="login-bg" alt="" />
      </div>
      <div className="content-width-1">
        <div className="d-md-flex card-container">
          <div className="card-left-width">
            <img
              src="/assets/img/loginImage.png"
              alt=""
              className="img-fluid left-i"
            />
          </div>
          <div className="card-right-width">
            {currentScreen === 1 && (
              <RequestTokenPage
                setCurrentScreen={setCurrentScreen}
                isLoading={isLoading}
                handleSubmit={(e) => handleSubmit(e)}
              />
            )}
            {currentScreen === 2 && (
              <TokenConfirmationPage
                setCurrentScreen={setCurrentScreen}
                handleRefetchSubmit={(e) => handleSubmit(e)}
                setTokenCode={(e) => setTokenCode(e)}
              />
            )}
            {currentScreen === 3 && (
              <NewPasswordPage
                setCurrentScreen={setCurrentScreen}
                tokenCode={tokenCode}
              />
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default ResetPasswordPage;
