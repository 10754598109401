import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { deepCompare } from "../../../utils/deepCompare";

const EditJudgesScores = ({
  scores,
  refetchScores,
  setIsReloadScore,
  currentWeek,
  isCanUpdateResultsIncluded,
}) => {
  const [search, setSearch] = useState("");
  const [userUpdateInput, setUserUpdateInput] = useState([]);
  const [expandSearch, setExpandSearch] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editScore, setEditScore] = useState(-1);
  const [diff, setDiff] = useState(null);

  useEffect(() => {
    setUserUpdateInput(scores?.scores);
  }, [scores]);

  const inputScoreRefs = useRef([]);

  useEffect(() => {
    inputScoreRefs.current = Array.from(
      { length: userUpdateInput.length },
      (_, i) => inputScoreRefs.current[i] || React.createRef()
    );
  }, [userUpdateInput.length]);

  useEffect(() => {
    const handleWheel = (event) => {
      if (
        inputScoreRefs.current.some(
          (ref) => ref.current && ref.current.contains(document.activeElement)
        )
      ) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (
        inputScoreRefs.current.some(
          (ref) => ref.current && ref.current.contains(document.activeElement)
        )
      ) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleUserUpdateInput = (event, itemId) => {
    let updatedItems = [...userUpdateInput];

    const existingItemIndex = updatedItems.findIndex(
      (item) => item.id === itemId
    );
    if (existingItemIndex !== -1) {
      updatedItems[existingItemIndex] = {
        ...updatedItems[existingItemIndex],
        score_value: Number(event.target.value),
      };
    } else {
      updatedItems.push({
        score_value: Number(event.target.value),
      });
    }
    let deep = deepCompare(userUpdateInput, updatedItems);
    setDiff(deep);

    setUserUpdateInput(updatedItems);
  };

  const saveEditScore = (e, id) => {
    e.preventDefault();

    for (let key in diff) {
      const data = {
        score_value: diff[key]?.oldValue?.score_value,
      };
      setIsSaving(true);
      axios
        .put(`/api/v3/scores/${id}`, data)
        .then((res) => {
          setEditScore(-1);
          setIsSaving(false);
          let msg = res.data.message;
          toast.success(msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsReloadScore(true);
          refetchScores().then(() => {
            setIsReloadScore(false);
          });
        })
        .catch((err) => {
          setIsSaving(false);
          console.log(err.response);
        });
    }
    setDiff([]);
  };

  const showSaveBtn = (e, id) => {
    e.preventDefault();
    setEditScore(id);
  };
  const filteredListUserUpdateInput = userUpdateInput?.filter((item) => {
    if (search === "") {
      return item;
    } else if (
      item?.contestant?.stage?.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    }
  });
  return (
    <>
      <div className="card card-table-height-1 mt-md-4 px-md-5">
        <div className="judge d-flex my-auto">
          <div className="float-end">
            <div
              className={
                expandSearch === true
                  ? "search-active judge1 me-3"
                  : "search judge1 me-3"
              }
            >
              <div
                className="icon"
                onClick={() => setExpandSearch((prev) => !prev)}
              ></div>
              <div className="input">
                <input
                  type="text"
                  id="seacrh-box"
                  placeholder={expandSearch === true ? "Search..." : " "}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body booking_card">
          {filteredListUserUpdateInput?.map((contestant, i) => {
            return (
              <>
                <div
                  className="d-md-flex d-block justify-content-between my-3 w-100"
                  key={i}
                >
                  <div className="d-flex align-items-center mobile-width">
                    <div>
                      <div className="const-img-cover">
                        <img src={contestant?.contestant?.cover_pic} alt="" />
                      </div>
                    </div>
                    <div className="d-block my-auto ms-md-4 ms-2 name-region">
                      <h4 className="my-auto">
                        {contestant?.contestant?.stage}
                      </h4>
                      <h5 className="my-auto">
                        {contestant?.contestant?.town}
                      </h5>
                    </div>
                  </div>
                  <div className="judge-box-cover my-md-auto">
                    <form onSubmit={(e) => saveEditScore(e, contestant?.id)}>
                      {editScore === contestant.id ? (
                        <>
                          <input
                            type="number"
                            key={contestant?.id}
                            name="score_value"
                            id={`dd${contestant?.id}r`}
                            value={contestant?.score_value}
                            onChange={(event) =>
                              handleUserUpdateInput(event, contestant?.id)
                            }
                            className="judge-box-width"
                            placeholder="Enter Judges Score"
                            required
                            ref={inputScoreRefs.current[i]}
                          />

                          <div className="save-pos">
                            {!isSaving && (
                              <button
                                type="submit"
                                className="btn btn-orange btn-size"
                              >
                                Save
                              </button>
                            )}
                            {isSaving && (
                              <button
                                className="btn btn-orange btn-size1"
                                disabled
                              >
                                <div>{/* <LoadingButton /> */}</div>
                                Saving...
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <input
                            type="number"
                            key={contestant?.id}
                            name="score_value"
                            id={`dd${contestant?.id}r`}
                            value={contestant?.score_value}
                            className="judge-box-width"
                            placeholder="Enter Judges Score"
                            readOnly
                          />
                          {isCanUpdateResultsIncluded && (
                            <>
                              {currentWeek.status.toLowerCase() ===
                                "active" && (
                                <div className="save-pos">
                                  <button
                                    type="button"
                                    className="btn btn-secondary1 btn-size"
                                    onClick={(e) =>
                                      showSaveBtn(e, contestant.id)
                                    }
                                  >
                                    Edit
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </form>
                  </div>
                </div>
                <hr />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default EditJudgesScores;
