import { useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "../../../components/partials/Nav";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AddAlbum from "./AddAlbum";
import { DotLoader } from "react-spinners";
import UpdateAlbum from "./UpdateAlbum";
import { useGetSeasonAlbumsQuery } from "../../../app/apiSlice";
import { setActiveAlbum } from "../../../app/showsSlice";

const ShowAlbumsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [albumModal, setAlbumModal] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [showAlbums, setShowAlbums] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState({});
  const [isRelaodAlbum, setIsReloadAlbum] = useState(false);
  const [editAlbumModal, setEditAlbumModal] = useState(false);
  const location = useLocation();
  const { code, season_code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeGalleryShow = queryParams.get("show");
  const selectedGallerySeason = queryParams.get("season");

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#febf4c");

  const {
    data: allAbums,
    isLoading: isAlbumssLoading,
    refetch: refetchAlbums,
  } = useGetSeasonAlbumsQuery({
    showID: activeGalleryShow,
    seasonID: selectedGallerySeason,
  });

  useEffect(() => {
    if (allAbums) {
      setShowAlbums(allAbums.data);
    }
  }, [allAbums]);

  const handleEditAlbumClick = (album) => {
    setSelectedAlbum(album);
    setEditAlbumModal(true);
  };
  const handleAlbumClick = (album) => {
    navigate(
      `/gallery/${code}/${season_code}/${album?.code}?show=${activeGalleryShow}&season=${selectedGallerySeason}&album=${album?.id}`
    );
  };

  const newData = Array.isArray(showAlbums?.albums)
    ? [...showAlbums.albums]
    : [];

  const sortedData = newData.sort((a, b) => b.id - a.id);
  const filteredList = sortedData?.filter((item) => {
    if (search === "") {
      return item;
    } else if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />
      <>
        <div className="container body-width">
          <div className="col-md-12">
            <div className="indication1">
              <div
                className="d-flex justify-content-between cursor-pointer"
                onClick={() => navigate("/dashboard")}
              >
                <div className="">
                  <img src="/assets/img/home.svg" alt="" className="me-2" />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1">Home</h4>
                </div>
              </div>
              <div
                className="d-flex justify-content-between ms-2 cursor-pointer"
                onClick={() => navigate("/gallery")}
              >
                <div className="">
                  <img
                    src="/assets/img/arr-right1.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1">Gallery</h4>
                </div>
              </div>
              <div
                className="d-flex justify-content-between ms-2 cursor-pointer"
                onClick={() => navigate(`/gallery/${code}?show=${activeGalleryShow}`)}
              >
                <div className="">
                  <img
                    src="/assets/img/arr-right1.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1 text-capitalize">
                    {code}
                  </h4>
                </div>
              </div>
              <div className="d-flex justify-content-between ms-2">
                <div className="">
                  <img
                    src="/assets/img/arr-right.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1 disabled-color text-capitalize">
                    {season_code}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
              <div className="col-md-6">
                <div className="title-font text-center text-md-start">
                  <h4>Albums</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex float-end mt-2 mt-md-0">
                  <div
                    className={
                      expandSearch === true
                        ? "search-active me-2"
                        : "search me-2"
                    }
                  >
                    <div
                      className="icon"
                      onClick={() => setExpandSearch((prev) => !prev)}
                    ></div>
                    <div className="input">
                      <input
                        type="text"
                        id="seacrh-box"
                        placeholder={expandSearch === true ? "Search..." : " "}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className="add-cover1 cursor-pointer"
                      data-title="add new show"
                      onClick={() => setAlbumModal(true)}
                    >
                      <img src="/assets/img/plus.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              {!isAlbumssLoading && !isRelaodAlbum ? (
                <>
                  {filteredList?.map((album, i) => {
                    return (
                      <div className="col-md-4 mt-4" key={i}>
                        <div>
                          <div className="show-card-background">
                            <div className="d-flex absolute-constentant-bg1 mt-2">
                              <div
                                className="edit-contestant-bg cursor-pointer"
                                onClick={() => handleEditAlbumClick(album)}
                              >
                                <h4>Update</h4>
                              </div>
                            </div>
                            <div className="show-card-overlay"></div>
                            <div
                              className="show-card-inner"
                              onClick={() => handleAlbumClick(album)}
                            >
                              <div className="show-title">
                                <h4>{album?.name}</h4>
                              </div>
                            </div>
                            <div className="show-image">
                              <img src={album?.thumbnail} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-md-4 my-4">
                    <div
                      className="show-card-background1"
                      onClick={() => setAlbumModal(true)}
                    >
                      {!isAlbumssLoading ? (
                        <>
                          {showAlbums?.albums?.length === 0 && (
                            <div className="show-title-background1 bg1 py-2 px-3">
                              <h4>Create New Album</h4>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="show-card-inner1">
                        <img
                          src="/assets/img/addIcon.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="loader-h">
                  <DotLoader
                    color={color}
                    loading={loading}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>

      <AddAlbum
        albumModal={albumModal}
        setAlbumModal={setAlbumModal}
        refetchAlbums={refetchAlbums}
        setIsReloadAlbum={setIsReloadAlbum}
      />
      <UpdateAlbum
        editAlbumModal={editAlbumModal}
        setEditAlbumModal={setEditAlbumModal}
        selectedAlbum={selectedAlbum}
        refetchAlbums={refetchAlbums}
        setIsReloadAlbum={setIsReloadAlbum}
      />
    </motion.div>
  );
};
export default ShowAlbumsPage;
