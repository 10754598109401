import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useGetAllPermissionsQuery,
  useUpdateRoleMutation,
} from "../../../../app/apiSlice";
const EditRole = ({
  showEditRoleModal,
  setShowEditRoleModal,
  refetchRoles,
  setIsRoleReload,
  convertSnakeCaseToTitleCase,
  selectedRole,
}) => {
  const [permissions, setPermissions] = useState([]);
  const [checkedSubPermissionIds, setCheckedSubPermissionIds] = useState([]);
  const [userInput, setUserInput] = useState([]);

  useEffect(() => {
    if (selectedRole) {
      const permissionIds = selectedRole?.permissions?.map(
        (permission) => permission.id
      );
      setUserInput(selectedRole);
      setCheckedSubPermissionIds(permissionIds);
    }
  }, [selectedRole]);

  useEffect(() => {}, [userInput]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const { data: allPermissions } = useGetAllPermissionsQuery();

  useEffect(() => {
    setPermissions(allPermissions?.data);
  }, [allPermissions]);

  const handleChange = (e, permissionId, subPermissionId) => {
    const { checked } = e.target;
    let tempPermissions = permissions.map((permission) => {
      if (permission.id === permissionId) {
        return {
          ...permission,
          isChecked: checked,
          permissions: permission.permissions.map((sub_permission) => {
            if (sub_permission.id === subPermissionId) {
              return { ...sub_permission, isChecked: checked };
            } else {
              return sub_permission;
            }
          }),
        };
      } else {
        return {
          ...permission,
          permissions: permission.permissions.map((sub_permission) =>
            sub_permission.id === subPermissionId
              ? { ...sub_permission, isChecked: checked }
              : sub_permission
          ),
        };
      }
    });

    setPermissions(tempPermissions);

    if (checked) {
      setCheckedSubPermissionIds([...checkedSubPermissionIds, subPermissionId]);
    } else {
      setCheckedSubPermissionIds(
        checkedSubPermissionIds.filter((id) => id !== subPermissionId)
      );
    }
  };

  const [updateRole, { data, isSuccess, isError, error, isLoading }] =
    useUpdateRoleMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, state, description, id } = userInput;

    if (title && state && checkedSubPermissionIds.length > 0) {
      try {
        await updateRole({
          id,
          body: {
            title,
            state,
            description,
            permissions: checkedSubPermissionIds,
          },
        });
      } catch (error) {
        console.error("Error updating role:", error);
      }
    } else {
      toast.error("Select At Least One Permission", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Role Updated Successfully", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowEditRoleModal(false);
      setIsRoleReload(true);
      refetchRoles().then(() => {
        setIsRoleReload(false);
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <Modal
        show={showEditRoleModal}
        onHide={() => setShowEditRoleModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Update Role</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="email">
                  <h4>Title</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={userInput.title}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter role title"
                  readOnly
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="description">
                  <h4>Role Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="description"
                  id="description"
                  value={userInput.description ?? ""}
                  onChange={handleUserInput}
                  className="text-area-width14"
                  placeholder="Enter role description"
                ></textarea>
              </div>
            </div>

            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="state">
                  <h4>Status</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <select
                  name="state"
                  id="state"
                  className="select-box-width"
                  value={userInput?.state}
                  onChange={handleUserInput}
                  required
                >
                  <option value="">-- select --</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>

                <img
                  src="/assets/img/arrow-down.svg"
                  alt=""
                  className="select-box-img"
                />
              </div>
            </div>

            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="state">
                  <h4>Permissions</h4>
                </label>
              </div>
              <div className="col-12">
                <div className="row">
                  {permissions?.map((permission, i) => {
                    return (
                      <div className="col-md-6 mb-5" key={i}>
                        <div className="check-width">
                          <label
                            htmlFor={`permission_${i}`}
                            className="check-cover"
                          >
                            {permission.name}
                          </label>
                        </div>
                        {permission.permissions?.map((sub_permission, j) => {
                          const isChecked = checkedSubPermissionIds?.includes(
                            sub_permission.id
                          );
                          return (
                            <div className="inner-c" key={j}>
                              <div className="check-width">
                                <label
                                  htmlFor={`sub_permission_${sub_permission.id}`}
                                  className="check-cover"
                                >
                                  <input
                                    type="checkbox"
                                    id={`sub_permission_${sub_permission.id}`}
                                    name={sub_permission.id}
                                    checked={isChecked}
                                    className="checkbox-design radio_check"
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        permission.id,
                                        sub_permission.id
                                      )
                                    }
                                  />
                                  {convertSnakeCaseToTitleCase(
                                    sub_permission.name
                                  )}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="d-block mt-4 mb-4 mb-md-0">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Update Role"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Updating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditRole;
