import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AddPicturesSection from "./AddPicturesSection";
import { Fade } from "react-reveal";
import AddVideoSection from "./AddVideoSection";

const ContestantGalleryModal = ({
  setContestantGalleryModalShow,
  contestantGalleryModalShow,
}) => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const updatePage = (id) => {
    setCurrentScreen(id);
  };
  const closeModal = () => {
    setContestantGalleryModalShow(false);
  };
  return (
    <>
      <Modal
        show={contestantGalleryModalShow}
        onHide={closeModal}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Contestant Gallery</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 mt-4">
            <div className="d-flex mt-4 mt-md-0">
              <div className="me-md-4">
                <div
                  onClick={() => setCurrentScreen(1)}
                  className={
                    currentScreen === 1
                      ? "active-btn1 cursor-pointer"
                      : "inactive1 cursor-pointer"
                  }
                >
                  <h4>Picture</h4>
                </div>
              </div>
              <div className="me-4 float-end">
                <div
                  onClick={() => updatePage(2)}
                  className={
                    currentScreen === 2
                      ? "active-btn1 cursor-pointer"
                      : "inactive1 cursor-pointer"
                  }
                >
                  <h4>Video</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="underl"></div>
          </div>
          {currentScreen === 1 && (
            <Fade>
              <div className="col-md-12">
                <AddPicturesSection />
              </div>
            </Fade>
          )}
          {currentScreen === 2 && (
            <Fade>
              <div className="col-md-12">
                <AddVideoSection />
              </div>
            </Fade>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default ContestantGalleryModal;
