import React, { useEffect, useState } from "react";
import { useConfirmPasswordTokenMutation } from "../../app/apiSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
function TokenConfirmationPage({
  setCurrentScreen,
  handleRefetchSubmit,
  setTokenCode,
}) {
  const email = JSON?.parse(secureLocalStorage?.getItem("user"))?.user?.email;

  const [userInput, setUserInput] = useState({
    code: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [confirmPasswordToken, { data, isSuccess, isError, error, isLoading }] =
    useConfirmPasswordTokenMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { code } = userInput;
    if (email && code) {
      await confirmPasswordToken({ email, code });
    } else {
      toast.error("Code required", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTokenCode(data?.data?.token_code);
      setCurrentScreen(3);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      toast.error(error.data.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);
  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="tv3-cover">
        <img
          src="/assets/img/logo1.svg"
          alt=""
          className="img-fluid logo1-size"
        />
      </div>
      <div className="login-text">
        <h4>Reset Password</h4>
      </div>
      <div className="create-btn-cover w-100">
        <div className="mar-t-1">
          <div className="token-font">
            <h6>
              A token has been sent to your email address. Enter in the textbox
              below
            </h6>
          </div>
          <div className="text-box-cover">
            <img src="/assets/img/key.png" alt="" className="text-box-key" />
            <input
              type="text"
              name="code"
              id="code"
              className="text-box-width12"
              value={userInput?.code}
              onChange={handleUserInput}
              placeholder="Token"
              required
            />
          </div>
          <div className="cover-marg1">
            <h6>
              Didn't receive token?{" "}
              <span onClick={(e) => handleRefetchSubmit(e)}>Request Again</span>
            </h6>
          </div>
          {!isLoading && (
            <button type="submit" className="btn btn-warning w-100">
              Submit
            </button>
          )}
          {isLoading && (
            <button className="btn btn-warning w-100" disabled>
              Submitting...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default TokenConfirmationPage;
