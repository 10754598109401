import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useAddAlbumMutation } from "../../../app/apiSlice";
import { useLocation, useParams } from "react-router-dom";
const AddAlbum = ({
  albumModal,
  setAlbumModal,
  refetchAlbums,
  setIsReloadAlbum,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isloading, setLoading] = useState(false);
  const location = useLocation();
  const { code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeGalleryShow = queryParams.get("show");
  const selectedGallerySeason = queryParams.get("season");

  const initialUserInput = {
    album_name: "",
    description: "",
    album_type: "",
  };

  const [userInput, setUserInput] = useState(initialUserInput);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [addAlbum, { data, isSuccess, isError, error, isLoading }] =
    useAddAlbumMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", userInput.album_name);
    formData.append("desc", userInput.description);
    formData.append("show_id", activeGalleryShow);
    formData.append("code", code);
    formData.append("season_id", selectedGallerySeason);
    formData.append("thumbnail", selectedImage);
    formData.append("album_type", userInput.album_type);
    if (selectedImage === null) {
      setLoading(false);
      toast.error("Album thumbnail is required", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        await addAlbum(formData);
      } catch (error) {
        console.error("Error adding album:", error);
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlbumModal(false);
      setUserInput(initialUserInput);
      setSelectedImage(null);
      setIsReloadAlbum(true);
      refetchAlbums().then(() => {
        setIsReloadAlbum(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <Modal
        show={albumModal}
        onHide={() => setAlbumModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Create New Album</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mt-4 mt-md-0">
                <label htmlFor="email">
                  <h4>Album Name</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <img
                  src="/assets/img/profile.svg"
                  alt=""
                  className="text-box-img"
                />
                <input
                  type="text"
                  name="album_name"
                  id="email"
                  value={userInput.album_name}
                  onChange={handleUserInput}
                  className="text-box-width12"
                  placeholder="Enter Album Name"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="email">
                  <h4>Album Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="description"
                  value={userInput.description}
                  onChange={handleUserInput}
                  className="text-area-width"
                  placeholder="Write text here..."
                  required
                ></textarea>
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="album_type">
                  <h4>Select Album Type</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <select
                  name="album_type"
                  id="album_type"
                  className="select-box-width"
                  value={userInput.album_type}
                  onChange={handleUserInput}
                  required
                >
                  <option value="">-- select --</option>
                  <option value="gallery">Gallery</option>
                  <option value="videos">Videos</option>
                </select>
                <img
                  src="/assets/img/arrow-down.svg"
                  alt=""
                  className="select-box-img"
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="picture-box-cover">
                <input
                  type="file"
                  id="uploadBtn"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
                {selectedImage ? (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="uploaded-file"
                        className="show_img"
                      />
                    </div>
                  </label>
                ) : (
                  <div className="pic-section-width">
                    <label htmlFor="uploadBtn">
                      <div className="upload-cover cursor-pointer">
                        <div>
                          <img src="/assets/img/upload.svg" alt="" />
                        </div>
                        <div>
                          <h4>Click to upload thumbnail</h4>
                        </div>

                        <div className="browse-cover d-flex">
                          <div className="my-auto">
                            <img
                              src="/assets/img/browse.svg"
                              alt=""
                              className="browse-icon me-2 my-auto"
                            />
                          </div>
                          <h4 className="my-auto"> Browse file</h4>
                        </div>
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block mt-4">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Create Album"
                  />
                  <img
                    src="/assets/img/arrow-right.png"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Creating..."
                  />
                  <img
                    src="/assets/img/arrow-right.png"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddAlbum;
