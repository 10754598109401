import { motion } from "framer-motion";
import Nav from "../../../components/partials/Nav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Fade } from "react-reveal";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import {
  useCreateNewVoteWeekMutation,
  useGetAllCategoriesQuery,
  useGetGeneralVotesQuery,
  useGetScoresQuery,
  useGetSeasonContestantsQuery,
  useGetViewersVotesQuery,
  useGetWeekQuery,
} from "../../../app/apiSlice";
import LoadingButton from "../../../components/LoadingButton";
import secureLocalStorage from "react-secure-storage";
const LeaderBoard = () => {
  const [expandSearch, setExpandSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [currentScreen, setCurrentScreen] = useState(1);
  const [isReloadGeneralVotes, setIsReloadGeneralVotes] = useState(false);
  const [isReloadViewersVotes, setIsReloadViewersVotes] = useState(false);
  const [isCategoryActive, setIsCategoryActive] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const [isReloadScores, setIsReloadScores] = useState(false);
  const [contestants, setContestants] = useState([]);
  const [generalVotes, setGeneralVotes] = useState([]);
  const [viewersVotes, setViewersVotes] = useState([]);
  const [scores, setScores] = useState([]);
  const [week, setWeek] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const categoryDropdownRef = useRef(null);
  const { code, season_code } = useParams();
  const [categories, setCategories] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");

  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const isCanCreateResultsIncluded =
    permissions?.includes("can_create_results");

  const {
    data: allCategories,
    isLoading,
    refetch: refetchCategories,
  } = useGetAllCategoriesQuery({
    showID: activeShow,
  });

  useEffect(() => {
    if (allCategories) {
      setCategories(allCategories.data.teams);
    }
  }, [allCategories]);

  const {
    data: allcontestants,
    isLoading: isContestantsLoading,
    refetch: refetchContestants,
  } = useGetSeasonContestantsQuery({
    showID: activeShow,
    seasonID: season,
  });
  const {
    data: generalVoteCounts,
    isLoading: isGeneralVotesLoading,
    refetch: refetchGeneralVotes,
    isFetching,
  } = useGetGeneralVotesQuery({
    showID: activeShow,
    seasonID: season,
    ...(selectedCategory !== undefined &&
      selectedCategory !== "" && { teamID: selectedCategory?.id }),
  });
  const {
    data: viewersVoteCounts,
    isLoading: isViewersVotesLoading,
    refetch: refetchViewersVotes,
  } = useGetViewersVotesQuery({
    showID: activeShow,
    seasonID: season,
    ...(selectedCategory !== undefined &&
      selectedCategory !== "" && { teamID: selectedCategory?.id }),
  });
  const { data: activeWeek, isLoading: isWeekLoading } = useGetWeekQuery({
    showID: activeShow,
    seasonID: season,
  });

  const {
    data: allscores,
    isLoading: isScoreLoading,
    refetch: refetchScores,
  } = useGetScoresQuery(
    {
      showID: activeShow,
      seasonID: season,
      weekID: week?.id,
    },
    {
      skip: !week,
    }
  );

  useEffect(() => {
    if (allscores) {
      setScores(allscores.data.scores);
    }
  }, [allscores]);

  useEffect(() => {
    const fetchData = async () => {
      if (week) {
        try {
          await refetchScores({
            showID: activeShow,
            seasonID: season,
            weekID: week,
          });
        } catch (error) {
          console.error("Error occurred while refetching regions:", error);
        }
      }
    };

    fetchData();
  }, [week]);
  useEffect(() => {
    if (allcontestants) {
      setContestants(allcontestants.data.contestants);
    }
  }, [allcontestants]);

  useEffect(() => {
    if (activeWeek) {
      setWeek(activeWeek.data.weeks[0]);
    }
  }, [activeWeek]);
  useEffect(() => {
    if (generalVoteCounts) {
      setGeneralVotes(generalVoteCounts.data.votes_count);
    }
  }, [generalVoteCounts]);
  useEffect(() => {
    if (viewersVoteCounts) {
      setViewersVotes(viewersVoteCounts.data.votes_count);
    }
  }, [viewersVoteCounts]);

  const filteredList = generalVotes?.filter((item) => {
    if (search === "") {
      return item;
    } else if (
      item?.cont?.stage?.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    }
  });
  const filteredViewersList = viewersVotes?.filter((item) => {
    if (search === "") {
      return item;
    } else if (
      item?.cont?.stage?.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    }
  });

  const filteredScoresList = scores?.filter((item) => {
    if (search === "") {
      return item;
    } else if (
      item?.contestant?.stage?.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    }
  });
  const updatePage = (id) => {
    setCurrentScreen(id);
  };

  const [
    createNewVoteWeek,
    { data, isSuccess, isError, error, isLoading: isCreating },
  ] = useCreateNewVoteWeekMutation();

  const handleCreateNewWeek = async (e) => {
    e.preventDefault();

    Swal.fire({
      title:
        "Are you sure you want to create new week?. This action creates new week and deactivates the current one",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createNewVoteWeek({
            show_id: activeShow,
            season_id: season,
          });
        } catch (error) {
          console.error("Error creating new week:", error);
        }
      }
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 1202,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsReloadGeneralVotes(true);
        refetchGeneralVotes().then(() => {
          setIsReloadGeneralVotes(false);
        });
        setIsReloadViewersVotes(true);
        refetchViewersVotes().then(() => {
          setIsReloadViewersVotes(false);
        });

        if (week) {
          try {
            setIsReloadScores(true);
            await refetchScores({
              showID: activeShow,
              seasonID: season,
              weekID: week,
            });
            setIsReloadScores(false);
          } catch (error) {
            console.error("Error occurred while refetching scores:", error);
          }
        }
      }
    };

    fetchData();
  }, [isSuccess, week]);

  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);
  const onCategoryChange = (currentCategory) => {
    if (currentCategory === null) {
      setSearchCategory("");
      setSelectedCategory({});
    }
    setSelectedCategory(currentCategory);
    setSearchCategory(currentCategory?.name);
  };

  const filteredCategoryList = categories?.filter((item) => {
    if (searchCategory === "") {
      return item;
    } else {
      return item?.name?.toLowerCase().includes(searchCategory?.toLowerCase());
    }
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target)
      ) {
        setIsCategoryActive(false);

        if (selectedCategory) {
          setSearchCategory(selectedCategory.name);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchCategory]);

  const handleSeasonClick = () => {
    if (code === "sdg") {
      navigate(
        `/reality-show/${code}/${season_code}/categories?show=${activeShow}&season=${season}`
      );
    } else {
      navigate(
        `/reality-show/${code}/${season_code}?show=${activeShow}&season=${season}`
      );
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />
      <div className="container body-width">
        <div className="col-md-12">
          <div className="indication1">
            <div
              className="d-flex justify-content-between cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <div className="">
                <img src="/assets/img/home.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Home</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate("/reality-shows")}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Reality Shows</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() =>
                navigate(`/reality-show/${code}?show=${activeShow}`)
              }
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">{convertSnakeCaseToTitleCase(code)}</h4>
              </div>
            </div>

            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={handleSeasonClick}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1 text-capitalize">{convertSnakeCaseToTitleCase(season_code)}</h4>
              </div>
            </div>
            {code !== "sdg" && (
              <div className="d-flex justify-content-between ms-2">
                <div className="">
                  <img
                    src="/assets/img/arr-right.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1 disabled-color text-capitalize">
                    Contestants
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="row  mt-5 justify-content-between align-items-center">
            <div className="col-md-3">
              <div className="row title-font mt-5 mt-md-0">
                <h4 className="text-capitalize">Leader Board</h4>
              </div>
            </div>
            <div className="col-md-9">
              <div className="d-flex align-items-center justify-content-end w-100">
                {code === "sdg" && (
                  <label
                    htmlFor=""
                    className="label-width d-flex align-items-center"
                  >
                    Categories
                    <div className="region-dropdown-cover w-100 ms-2 me-3">
                      <div
                        className="dropdown"
                        onClick={() => setIsCategoryActive((prev) => !prev)}
                      >
                        <div className="dropdown-btn dropdown-btn-mobile">
                          <input
                            type="text"
                            placeholder="-- Select --"
                            className="form-control cover-b"
                            value={searchCategory}
                            isGeneralVotesLoading={isFetching}
                            onChange={(event) =>
                              setSearchCategory(event.target.value)
                            }
                            onFocus={() => setSearchCategory("")}
                          />
                          <img
                            src="/assets/img/arrowDown1.svg"
                            alt=""
                            className="ms-2 arrow-w"
                          />
                        </div>
                        {isCategoryActive && (
                          <div
                            className="dropdown-content-cover dropdown-btn-mobile1"
                            ref={categoryDropdownRef}
                          >
                            <div className="dropdown-content dropdown-content-mobile">
                              {}
                              {filteredCategoryList?.length > 0 ? (
                                <>
                                  <div
                                    className="dropdown-item"
                                    name="no_value"
                                    onClick={() => {
                                      onCategoryChange(null);
                                    }}
                                  >
                                    -- Select --
                                  </div>
                                  {filteredCategoryList?.map((category, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="dropdown-item"
                                        onClick={() =>
                                          onCategoryChange(category)
                                        }
                                      >
                                        {category?.name}
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="no-data-found">
                                  <h4>No Constestant Found</h4>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                )}

                {isCanCreateResultsIncluded && (
                  <button
                    className="btn btn-secondary me-2 mt-3 mt-md-0 px-3"
                    onClick={handleCreateNewWeek}
                  >
                    {isCreating ? "Creating..." : "Create New Week"}
                  </button>
                )}
                <div
                  className={
                    expandSearch === true
                      ? "search-active mt-3 mt-md-0"
                      : "search mt-3 mt-md-0"
                  }
                >
                  <div
                    className="icon"
                    onClick={() => setExpandSearch((prev) => !prev)}
                  ></div>
                  <div className="input">
                    <input
                      type="text"
                      id="seacrh-box"
                      placeholder={expandSearch === true ? "Search..." : " "}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="d-flex mt-3">
            <div className="me-md-4">
              <div
                onClick={() => setCurrentScreen(1)}
                className={
                  currentScreen === 1
                    ? "active-btn1 cursor-pointer"
                    : "inactive1 cursor-pointer"
                }
              >
                <h4>General Votes</h4>
              </div>
            </div>
            <div className="me-md-4 float-end">
              <div
                onClick={() => updatePage(2)}
                className={
                  currentScreen === 2
                    ? "active-btn1 cursor-pointer"
                    : "inactive1 cursor-pointer"
                }
              >
                <h4>Viewers Choice</h4>
              </div>
            </div>
            <div className="me-md-4 float-end">
              <div
                onClick={() => updatePage(3)}
                className={
                  currentScreen === 3
                    ? "active-btn1 cursor-pointer"
                    : "inactive1 cursor-pointer"
                }
              >
                <h4>Judges Scores</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="leader-card mt-3">
            <div className="card-body booking_card">
              {currentScreen === 1 && (
                <Fade>
                  {!isGeneralVotesLoading &&
                  !isReloadGeneralVotes &&
                  !isFetching ? (
                    <>
                      {filteredList?.length === 0 ? (
                        <div className="no-cover">
                          <div className="no-data">
                            <h1> No Score Available</h1>
                          </div>
                        </div>
                      ) : (
                        <>
                          {filteredList?.map((contestant, i) => {
                            return (
                              <>
                                <div
                                  className="d-block d-md-flex justify-content-between w-100 p-2 scores-font"
                                  key={i}
                                >
                                  <div className="d-flex align-items-center mobile-width">
                                    <div>
                                      <div className="const-img-cover">
                                        <img
                                          src={contestant?.cont?.cover_pic}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="d-block my-auto ms-md-4 ms-2 name-region">
                                      <h4 className="my-auto">
                                        {contestant?.cont?.stage}
                                      </h4>
                                      <h5 className="my-auto">
                                        {contestant?.cont?.town}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="judge-box-cover1 my-auto">
                                    <h1 className="float-end">
                                      {parseInt(
                                        contestant?.votes_count
                                      ).toLocaleString()}
                                    </h1>
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}

                          {contestants
                            ?.filter(
                              (contestant) =>
                                contestant?.status?.toLowerCase() === "evicted"
                            )
                            .map((contestant, i) => {
                              return (
                                <>
                                  <div
                                    className="d-block d-md-flex align-items-center justify-content-between my-3 w-100 p-2 evicted-bg"
                                    key={i}
                                  >
                                    <div className="d-flex align-items-center mobile-width">
                                      <div>
                                        <div className="const-img-cover">
                                          <img
                                            src={contestant?.cover_pic}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="d-block my-auto ms-md-4 ms-2 name-region">
                                        <h4 className="my-auto">
                                          {contestant?.stage}
                                        </h4>
                                        <h5 className="my-auto evicted-color">
                                          {contestant?.town}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="judge-box-cover1 my-auto">
                                      <h1 className="float-end evicted-color">
                                        Evicted
                                      </h1>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              );
                            })}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="no-data">
                      <LoadingButton />
                    </div>
                  )}
                </Fade>
              )}
              {currentScreen === 2 && (
                <Fade>
                  {!isViewersVotesLoading && !isReloadViewersVotes ? (
                    <>
                      {filteredViewersList?.length === 0 ? (
                        <div className="no-cover">
                          <div className="no-data">
                            <h1> No Score Available</h1>
                          </div>
                        </div>
                      ) : (
                        <>
                          {filteredViewersList?.map((contestant, i) => {
                            return (
                              <>
                                <div
                                  className="d-block d-md-flex justify-content-between w-100 p-2 scores-font"
                                  key={i}
                                >
                                  <div className="d-flex align-items-center mobile-width">
                                    <div>
                                      <div className="const-img-cover">
                                        <img
                                          src={contestant?.cont?.cover_pic}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="d-block my-auto ms-md-4 ms-2 name-region">
                                      <h4 className="my-auto">
                                        {contestant?.cont?.stage}
                                      </h4>
                                      <h5 className="my-auto">
                                        {contestant?.cont?.town}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="judge-box-cover1 my-auto">
                                    <h1 className="float-end">
                                      {parseInt(
                                        contestant?.viewers_count
                                      ).toLocaleString()}
                                    </h1>
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}

                          {contestants
                            ?.filter(
                              (contestant) =>
                                contestant?.status?.toLowerCase() === "evicted"
                            )
                            .map((contestant, i) => {
                              return (
                                <>
                                  <div
                                    className="d-block d-md-flex align-items-center justify-content-between my-3 w-100 p-2 evicted-bg"
                                    key={i}
                                  >
                                    <div className="d-flex align-items-center mobile-width">
                                      <div>
                                        <div className="const-img-cover">
                                          <img
                                            src={contestant?.cover_pic}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="d-block my-auto ms-md-4 ms-2 name-region">
                                        <h4 className="my-auto">
                                          {contestant?.stage}
                                        </h4>
                                        <h5 className="my-auto evicted-color">
                                          {contestant?.town}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="judge-box-cover1 my-auto">
                                      <h1 className="float-end evicted-color">
                                        Evicted
                                      </h1>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              );
                            })}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="no-data">
                      <LoadingButton />
                    </div>
                  )}
                </Fade>
              )}
              {currentScreen === 3 && (
                <Fade>
                  {!isScoreLoading && !isReloadScores ? (
                    <>
                      {filteredScoresList?.length === 0 ? (
                        <div className="no-cover">
                          <div className="no-data">
                            <h1> No Score Available</h1>
                          </div>
                        </div>
                      ) : (
                        <>
                          {filteredScoresList?.map((contestant, i) => {
                            return (
                              <>
                                <div
                                  className="d-block d-md-flex justify-content-between w-100 p-2 scores-font w-100"
                                  key={i}
                                >
                                  <div className="d-flex align-items-center mobile-width">
                                    <div>
                                      <div className="const-img-cover">
                                        <img
                                          src={
                                            contestant?.contestant?.cover_pic
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="d-block my-auto ms-md-4 ms-2 name-region">
                                      <h4 className="my-auto">
                                        {contestant?.contestant?.stage}
                                      </h4>
                                      <h5 className="my-auto">
                                        {contestant?.contestant?.town}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="judge-box-cover1 my-auto">
                                    <h1 className="float-end">
                                      {parseInt(
                                        contestant?.score_value
                                      ).toLocaleString()}
                                    </h1>
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}

                          {contestants
                            ?.filter(
                              (contestant) =>
                                contestant?.status?.toLowerCase() === "evicted"
                            )
                            .map((contestant, i) => {
                              return (
                                <>
                                  <div
                                    className="d-block d-md-flex align-items-center justify-content-between my-3 w-100 p-2 evicted-bg"
                                    key={i}
                                  >
                                    <div className="d-flex align-items-center mobile-width">
                                      <div>
                                        <div className="const-img-cover">
                                          <img
                                            src={contestant?.cover_pic}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="d-block my-auto ms-4 name-region">
                                        <h4 className="my-auto">
                                          {contestant?.stage}
                                        </h4>
                                        <h5 className="my-auto evicted-color">
                                          {contestant?.town}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="judge-box-cover1 my-auto">
                                      <h1 className="float-end evicted-color">
                                        Evicted
                                      </h1>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              );
                            })}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="no-data">
                      <LoadingButton />
                    </div>
                  )}
                </Fade>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default LeaderBoard;
