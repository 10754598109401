import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useResetPasswordMutation } from "../app/apiSlice";
import { logout } from "../app/authSlice";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
const FirstTimePasswordResetPage = () => {
  const email = JSON?.parse(secureLocalStorage?.getItem("user"))?.user?.email;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [resetPassword, { data, isSuccess, isError, error, isLoading }] =
    useResetPasswordMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, password_confirmation } = userInput;
    if (password && password_confirmation) {
      await resetPassword({ email, password, password_confirmation });
    } else {
      toast.error("Password is required", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(logout());
      navigate("/");
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      let errorMessages = error?.data?.message;
      let errorMessageString = "";

      for (const key in errorMessages) {
        if (errorMessages.hasOwnProperty(key)) {
          errorMessageString += errorMessages[key].join(", ") + ", ";
        }
      }
      errorMessageString = errorMessageString.slice(0, -2);
      toast.error(errorMessageString || error?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="login-bg-cover">
        <img src="/assets/img/login-bg.png" className="login-bg" alt="" />
      </div>
      <div className="content-width-1">
        <div className="d-md-flex card-container">
          <div className="card-left-width">
            <img
              src="/assets/img/loginImage.png"
              alt=""
              className="img-fluid left-i"
            />
          </div>
          <div className="card-right-width">
            <form action="" className="w-100" onSubmit={handleSubmit}>
              <div className="tv3-cover">
                <img
                  src="/assets/img/logo1.svg"
                  alt=""
                  className="img-fluid logo1-size"
                />
              </div>
              <div className="login-text">
                <h4>Reset Password</h4>
              </div>
              <div className="create-btn-cover w-100">
                <div className="mar-t-1">
                  <div className="text-box-cover cover-marg3">
                    <img
                      src="/assets/img/email.png"
                      alt=""
                      className="text-box-key"
                    />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="text-box-width12"
                      value={email}
                      onChange={handleUserInput}
                      readOnly
                    />
                  </div>
                  <div className="text-box-cover cover-marg3">
                    <img
                      src="/assets/img/key.png"
                      alt=""
                      className="text-box-key"
                    />
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="text-box-width12"
                      value={userInput?.password}
                      onChange={handleUserInput}
                      placeholder="Password"
                      required
                    />
                  </div>
                  <div className="text-box-cover cover-marg3">
                    <img
                      src="/assets/img/key.png"
                      alt=""
                      className="text-box-key"
                    />
                    <input
                      type="password"
                      name="password_confirmation"
                      id="password_confirmation"
                      className="text-box-width12"
                      value={userInput?.password_confirmation}
                      onChange={handleUserInput}
                      placeholder="Confirm Password"
                      required
                    />
                  </div>
                  {!isLoading && (
                    <button type="submit" className="btn btn-warning w-100">
                      Submit
                    </button>
                  )}
                  {isLoading && (
                    <button className="btn btn-warning w-100" disabled>
                      Submitting...
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default FirstTimePasswordResetPage;
