import { useState } from "react";
import {  DotLoader } from "react-spinners";

const LoadingButton = () => {
  let [color, setColor] = useState("#febf4c");
  let [loading, setLoading] = useState(true);
  return (
    <DotLoader
    color={color}
    loading={loading}
    size={50}
    aria-label="Loading Spinner"
    data-testid="loader"
  />
  );
};
export default LoadingButton;
