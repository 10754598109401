import { useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "../../../components/partials/Nav";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AddJudgesScoresModal from "./AddJudgesScoresModal";
import { DotLoader } from "react-spinners";
import EditJudgesScores from "./EditJudgesScores";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import {
  useAddScoresMutation,
  useGetScoresQuery,
  useGetWeeksQuery,
} from "../../../app/apiSlice";
import secureLocalStorage from "react-secure-storage";
const JudgesScorePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code, season_code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [currentWeek, setCurrentWeek] = useState([]);
  const [scores, setScores] = useState([]);
  const [isReloadScore, setIsReloadScore] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [firstWeek, setFirstWeek] = useState({});
  const [isReloadWeek, setIsReloadWeek] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#febf4c");
  const [showAddJudgesScoresModal, setShowAddJudgesScoresModal] =
    useState(false);
  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const isCanCreateResultsIncluded =
    permissions?.includes("can_create_results");
  const isCanUpdateResultsIncluded =
    permissions?.includes("can_update_results");

  const {
    data: allWeeks,
    isLoading: isWeeksLoading,
    refetch: refetchWeeks,
  } = useGetWeeksQuery({
    showID: activeShow,
    seasonID: season,
  });

  const {
    data: allscores,
    isLoading: isScoreLoading,
    refetch: refetchScores,
  } = useGetScoresQuery(
    {
      showID: activeShow,
      seasonID: season,
      weekID: selectedWeek,
    },
    {
      skip: !selectedWeek,
    }
  );
  useEffect(() => {
    if (allscores) {
      setScores(allscores.data);
    }
  }, [allscores]);
  useEffect(() => {
    if (allWeeks) {
      setWeeks(allWeeks.data.weeks);
      setFirstWeek(allWeeks.data.weeks[0]);
    }
  }, [allWeeks]);

  const activeWeek = weeks?.find((week) => week.status === "active");

  const firstElementId = firstWeek ? firstWeek.id : null;
  useEffect(() => {
    if (activeWeek && activeWeek.id !== selectedWeek) {
      setSelectedWeek(activeWeek.id);
      if (activeWeek.id === firstElementId) {
        setShowEditBtn(true);
      } else {
        setShowEditBtn(false);
      }
    }
  }, [weeks, firstElementId]);
  useEffect(() => {
    const currentWeek1 = weeks?.find(
      (week) => week.id === Number(selectedWeek)
    );
    setCurrentWeek(currentWeek1);
  }, [selectedWeek]);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedWeek) {
        setIsReloadScore(true);
        try {
          await refetchScores({
            showID: activeShow,
            seasonID: season,
            weekID: selectedWeek,
          });

          setIsReloadScore(false);
        } catch (error) {
          console.error("Error occurred while refetching regions:", error);
          setIsReloadScore(false);
        }
      }
    };

    fetchData();
  }, [selectedWeek]);

  const handleChange = (event) => {
    const selectedValue = event?.target?.value;
    setSelectedWeek(selectedValue);

    if (Number(selectedValue) === firstElementId) {
      setShowEditBtn(true);
    } else {
      setShowEditBtn(false);
    }
  };
  const [addScores, { data, isSuccess, isError, error, isLoading }] =
    useAddScoresMutation();

  const addNewScores = () => {
    Swal.fire({
      title:
        "Are you sure you want to add new scores?. This action creates new week and deactivates the current one",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await addScores({
            show_id: activeShow,
            season_id: season,
            is_new_score: true,
          });
        } catch (error) {
          console.error("Error occurred while saving scores:", error);
        }
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowAddJudgesScoresModal(false);
      setIsReloadScore(true);
      refetchWeeks().then(() => {
        setIsReloadScore(false);
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  const handleSeasonClick = () => {
    if (code === "sdg") {
      navigate(
        `/reality-show/${code}/${season_code}/categories?show=${activeShow}&season=${season}`
      );
    } else {
      navigate(
        `/reality-show/${code}/${season_code}?show=${activeShow}&season=${season}`
      );
    }
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1, transition: { duration: 1 } }}
      >
        <Nav />
        <div className="container body-width">
          <div className="col-md-12">
            <div className="indication1">
              <div
                className="d-flex justify-content-between cursor-pointer"
                onClick={() => navigate("/dashboard")}
              >
                <div className="">
                  <img src="/assets/img/home.svg" alt="" className="me-2" />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1">Home</h4>
                </div>
              </div>
              <div
                className="d-flex justify-content-between ms-2 cursor-pointer"
                onClick={() => navigate("/reality-shows")}
              >
                <div className="">
                  <img
                    src="/assets/img/arr-right1.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1">Reality Shows</h4>
                </div>
              </div>
              <div
                className="d-flex justify-content-between ms-2 cursor-pointer"
                onClick={() =>
                  navigate(`/reality-show/${code}?show=${activeShow}`)
                }
              >
                <div className="">
                  <img
                    src="/assets/img/arr-right1.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1">{convertSnakeCaseToTitleCase(code)}</h4>
                </div>
              </div>

              <div
                className="d-flex justify-content-between ms-2 cursor-pointer"
                onClick={handleSeasonClick}
              >
                <div className="">
                  <img
                    src="/assets/img/arr-right1.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1 text-capitalize">
                    {convertSnakeCaseToTitleCase(season_code)}
                  </h4>
                </div>
              </div>
              {code !== "sdg" && (
                <div className="d-flex justify-content-between ms-2">
                  <div className="">
                    <img
                      src="/assets/img/arr-right.svg"
                      alt=""
                      className="me-2"
                    />
                  </div>
                  <div className="my-auto">
                    <h4 className="my-auto mt-1 disabled-color text-capitalize">
                      Contestants
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
              <div className="col-md-3">
                <div className="row title-font text-center text-md-start">
                  <h4 className="text-capitalize">Judges Scores</h4>
                </div>
              </div>

              <div className="col-md-9">
                <div className="d-flex float-end mt-3 mt-md-0">
                  {isCanCreateResultsIncluded && (
                    <>
                      {((weeks?.length >= 1 && scores?.scores?.length > 0) ||
                        (currentWeek?.status?.toLowerCase() === "active" &&
                          scores?.scores?.length !== 0)) && (
                        <div className="me-3">
                          <div
                            className="add-cover1 cursor-pointer"
                            data-title="add new score"
                            onClick={() => addNewScores()}
                          >
                            <img src="/assets/img/plus.svg" alt="" />
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="text-box-cover">
                    <select
                      value={selectedWeek}
                      onChange={handleChange}
                      className="select-box-width2 select-height"
                    >
                      {weeks?.map((week) => (
                        <option key={week.id} value={week?.id}>
                          {week.name}
                        </option>
                      ))}
                    </select>
                    <img
                      src="/assets/img/arrow-down.svg"
                      alt=""
                      className="select-box-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {!isWeeksLoading && !isReloadScore ? (
              <>
                {(weeks?.length === 1 && scores?.scores?.length === 0) ||
                (currentWeek?.status?.toLowerCase() === "active" &&
                  scores?.scores?.length === 0) ? (
                  <div className="add-div">
                    <button
                      type="button"
                      className="btn btn-warning bt-w"
                      onClick={() => setShowAddJudgesScoresModal(true)}
                    >
                      Add New Scores
                    </button>
                  </div>
                ) : (
                  <>
                    {selectedWeek && scores && (
                      <EditJudgesScores
                        scores={scores}
                        showEditBtn={showEditBtn}
                        refetchScores={refetchScores}
                        setIsReloadScore={setIsReloadScore}
                        currentWeek={currentWeek}
                        isCanUpdateResultsIncluded={isCanUpdateResultsIncluded}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="loader-h">
                <DotLoader
                  color={color}
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
        {isCanCreateResultsIncluded && (
          <AddJudgesScoresModal
            showAddJudgesScoresModal={showAddJudgesScoresModal}
            setShowAddJudgesScoresModal={setShowAddJudgesScoresModal}
            weeks={weeks}
            currentWeek={currentWeek}
            scores={scores}
            refetchScores={refetchScores}
            setIsReloadScore={setIsReloadScore}
          />
        )}
      </motion.div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};
export default JudgesScorePage;
