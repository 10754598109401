import { useState } from "react";
import { DotLoader } from "react-spinners";

const LoadingPage = () => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#febf4c");
  return (
    <div className="loading-div-active">
      <div className="loader-h">
        <DotLoader
          color={color}
          loading={loading}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};
export default LoadingPage;
