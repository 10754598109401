import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAddShowMutation } from "../../../app/apiSlice";
const AddShowModal = ({
  showModal,
  setShowModal,
  refetchShows,
  setIsReloadShows,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const initialUserInput = {
    show_name: "",
    show_description: "",
  };

  const [userInput, setUserInput] = useState(initialUserInput);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [addShow, { data, isSuccess, isError, error, isLoading }] =
    useAddShowMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { show_name, show_description } = userInput;

    if (show_name && show_description && selectedImage !== null) {
      const formData = new FormData();
      formData.append("name", userInput.show_name);
      formData.append("description", userInput.show_description);
      formData.append("status", "inactive");
      formData.append("logo_url", selectedImage);

      try {
        await addShow(formData);
      } catch (error) {
        console.error("Error adding shows:", error);
      }
    } else {
      toast.error("Fill All Fields", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowModal(false);
      setUserInput(initialUserInput);
      setSelectedImage(null);
      setIsReloadShows(true);
      refetchShows().then(() => {
        setIsReloadShows(false);
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      let errorMessages = error?.data?.errors;
      let errorMessageString = "";

      for (const key in errorMessages) {
        if (errorMessages.hasOwnProperty(key)) {
          errorMessageString += errorMessages[key].join(", ") + ", ";
        }
      }
      errorMessageString = errorMessageString.slice(0, -2);
      toast.error(errorMessageString, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Create New Show</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mrgin-show">
                <label htmlFor="email">
                  <h4>Show Name</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <img
                  src="/assets/img/profile.svg"
                  alt=""
                  className="text-box-img"
                />
                <input
                  type="text"
                  name="show_name"
                  id="email"
                  value={userInput.show_name}
                  onChange={handleUserInput}
                  className="text-box-width12"
                  placeholder="Enter Show Name"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="email">
                  <h4>Show Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="show_description"
                  value={userInput.show_description}
                  onChange={handleUserInput}
                  className="text-area-width"
                  placeholder="Write text here..."
                ></textarea>
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="picture-box-cover">
                <input
                  type="file"
                  id="uploadBtn"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
                {selectedImage ? (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <div className="upload-cover-img">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="uploaded-file"
                          className="show_img"
                        />
                      </div>
                    </div>
                  </label>
                ) : (
                  <div className="pic-section-width">
                    <label htmlFor="uploadBtn">
                      <div className="upload-cover cursor-pointer">
                        <div>
                          <img src="/assets/img/upload.svg" alt="" />
                        </div>
                        <div>
                          <h4>Click to upload image</h4>
                        </div>

                        <div className="browse-cover d-flex">
                          <div className="my-auto">
                            <img
                              src="/assets/img/browse.svg"
                              alt=""
                              className="browse-icon me-2 my-auto"
                            />
                          </div>
                          <h4 className="my-auto"> Browse file</h4>
                        </div>
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block mt-4 mb-4 mb-md-0">
              {!isLoading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Create Show"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isLoading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Creating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddShowModal;
