import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAllShows } from "../app/showsSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoginUserMutation } from "../app/apiSlice";
import { logout, setUser } from "../app/authSlice";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [loginUser, { data, isSuccess, isError, error, isLoading }] =
    useLoginUserMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = userInput;
    if (email && password) {
      await loginUser({ email, password });
    } else {
      toast.error("Credentials Required", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (data?.data?.user?.reset_password === 0) {
        navigate("/dashboard");
      } else {
        navigate("/password_reset");
      }
      const permissionNames = data?.data?.user?.role?.permissions?.map(
        (permission) => permission.name
      );
      dispatch(
        setUser({
          user: data?.data?.user,
          token: data?.data?.token,
          permissions: permissionNames,
        })
      );
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      console.log("error", error.data);
      toast.error("Invalid Credentials", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="login-bg-cover">
        <img src="/assets/img/login-bg.png" className="login-bg" alt="" />
      </div>
      <div className="content-width-1">
        <div className="d-md-flex card-container">
          <div className="card-left-width">
            <img
              src="/assets/img/loginImage.png"
              alt=""
              className="img-fluid left-i"
            />
          </div>
          <div className="card-right-width">
            <form action="" className="w-100" onSubmit={handleSubmit}>
              <div className="tv3-cover">
                <img
                  src="/assets/img/logo1.svg"
                  alt=""
                  className="img-fluid logo1-size"
                />
              </div>
              <div className="login-text">
                <h4>Login</h4>
              </div>
              <div className="d-block">
                <div className="email-font">
                  <label htmlFor="email">
                    <h4>Email Address</h4>
                  </label>
                </div>
                <div className="text-box-cover">
                  <img
                    src="/assets/img/email.png"
                    alt=""
                    className="text-box-key"
                  />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="text-box-width12"
                    value={userInput?.email}
                    onChange={handleUserInput}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div className="d-block mar-t">
                <div className="email-font">
                  <label htmlFor="password">
                    <h4>Password</h4>
                  </label>
                </div>
                <div className="text-box-cover">
                  <img
                    src="/assets/img/key.png"
                    alt=""
                    className="text-box-key"
                  />
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="text-box-width12"
                    placeholder="Password"
                    value={userInput?.password}
                    onChange={handleUserInput}
                    required
                  />
                </div>
              </div>
              <div className="create-btn-cover w-100">
                <div className="mar-t-1">
                  {!isLoading && (
                    <button type="submit" className="btn btn-warning w-100">
                      Login
                    </button>
                  )}
                  {isLoading && (
                    <button className="btn btn-warning w-100" disabled>
                      {/* <LoadingButton /> */}
                      Loading...
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default Login;
