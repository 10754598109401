import React from "react";
import RedirectPage from "./RedirectPage";
import secureLocalStorage from "react-secure-storage";

function ContestantProtectedRoute({ children }) {
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const isCanViewContestantIncluded = permissions?.includes(
    "can_view_contestant"
  );
  function render() {
    if (isCanViewContestantIncluded) {
      return children;
    } else {
      return <RedirectPage />;
    }
  }
  return render();
}

export default ContestantProtectedRoute;
