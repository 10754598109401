import React from "react";
import RedirectPage from "./RedirectPage";
import { useParams } from "react-router";

function CategoryProtectedRoute({ children }) {
  const { code } = useParams();

  function render() {
    if (code === "sdg") {
      return children;
    } else {
      return <RedirectPage />;
    }
  }
  return render();
}

export default CategoryProtectedRoute;
