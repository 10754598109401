import React from "react";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function PrivateRoute() {
  const token = JSON?.parse(secureLocalStorage?.getItem("user"))?.token;

  

  return token ? <Outlet/> : <LoadingToRedirect />;
}

export default PrivateRoute;
