// import {
//   configureStore,
//   combineReducers,
//   getDefaultMiddleware,
// } from "@reduxjs/toolkit";
// import authReducer from "./authSlice";
// import { realityApi } from "./apiSlice";
// import userReducer from "./userSlice";
// import showsReducer from "./showsSlice";

// import storage from "redux-persist/lib/storage";
// import {
//   persistReducer,
//   persistStore,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";
// const persistConfig = {
//   key: "3reality",
//   storage,
// };

// const reducers = combineReducers({
//   shows: showsReducer,
//   user: userReducer,
//   auth: authReducer,
//   [realityApi.reducerPath]: realityApi.reducer,
// });

// const persistedReducer = persistReducer(persistConfig, reducers);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }).concat(realityApi.middleware),
// });

// export const persistor = persistStore(store);

import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import { realityApi } from "./apiSlice";
import showsReducer from "./showsSlice";
import { setupListeners } from "@reduxjs/toolkit/query/react";

const reducers = combineReducers({
  shows: showsReducer,
  auth: authReducer,
  [realityApi.reducerPath]: realityApi.reducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(realityApi.middleware),
});

setupListeners(store.dispatch);



