import React, { useEffect, useState } from "react";
import RedirectPage from "./RedirectPage";
import { useGetAllShowsQuery } from "../app/apiSlice";
import { useParams } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import secureLocalStorage from "react-secure-storage";

function ShowProtectedRoute({ children }) {
  const { code } = useParams();
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;

  const isCanViewShowIncluded = permissions.includes("can_view_show");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    data: allShows,
    isLoading: showsLoading,
    error: showsError,
    refetch: refetchRoles,
  } = useGetAllShowsQuery();

  const [isShowIncluded, setIsShowIncluded] = useState(false);

  useEffect(() => {
    setIsLoading(showsLoading);
    setError(showsError);
  }, [showsLoading, showsError]);

  useEffect(() => {
    if (allShows?.data?.shows) {
      const showCodes = allShows.data.shows.map((show) => show.code);
      setIsShowIncluded(showCodes.includes(code));
    }
  }, [allShows, code]);

  if (error) {
    return <RedirectPage />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return isCanViewShowIncluded && isShowIncluded ? children : <RedirectPage />;
}

export default ShowProtectedRoute;
