import React, { useEffect, useState } from "react";
import {
  useDeleteRoleMutation,
  useGetAllRolesQuery,
} from "../../../../app/apiSlice";
import LoadingButton from "../../../../components/LoadingButton";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AddRole from "./AddRole";
import EditRole from "./EditRole";

function RolesPage({
  setShowRoleModal,
  showRoleModal,
  setShowEditRoleModal,
  showEditRoleModal,
}) {
  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const [roles, setRoles] = useState([]);
  const [isRoleReload, setIsRoleReload] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [selectedRole, setSelectedRole] = useState({});

  const {
    data: allRoles,
    isLoading,
    refetch: refetchRoles,
  } = useGetAllRolesQuery();
  useEffect(() => {
    if (allRoles) {
      setRoles(allRoles?.data);
    }
  }, [allRoles]);

  const [
    deleteRole,
    { data, isSuccess: isDeleteSuccess, isLoading: isDeleting },
  ] = useDeleteRoleMutation();

  const handleClickDelete = async (role, i) => {
    setDeletingIndex(i);

    const result = await Swal.fire({
      title: "Are you sure you want to delete this role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    });

    if (result.isConfirmed) {
      await deleteRole({ id: role?.id });
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success(data.msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsRoleReload(true);
      refetchRoles().then(() => {
        setIsRoleReload(false);
      });
    }
  }, [isDeleteSuccess]);

  const handleEditRoleClick = (role) => {
    setShowEditRoleModal(true);
    setSelectedRole(role);
  };
  return (
    <>
      <div className="table-responsive">
        <table className="datatable table table-stripped table table-hover table-center mb-0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <>
              {!isLoading && !isRoleReload ? (
                <>
                  {roles?.length === 0 ? (
                    <div className="no-data">
                      <h1> No Role Available</h1>
                    </div>
                  ) : (
                    roles?.map((role, i) => {
                      // const rolePermissions = role?.permissions
                      //   ?.map((rolePermission) => rolePermission.name)
                      //   .join(", ");
                      return (
                        <tr key={i}>
                          <td>{role.id}</td>
                          <td className="text-capitalize">{role?.title}</td>
                          {/* <td>
                            {convertSnakeCaseToTitleCase(rolePermissions)}
                          </td> */}
                          <td>{role?.state}</td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn btn-warning2"
                                onClick={() => handleEditRoleClick(role)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-danger2 ms-4"
                                disabled={isDeleting && deletingIndex === i}
                                onClick={() => handleClickDelete(role, i)}
                              >
                                {isDeleting && deletingIndex === i
                                  ? "Deleting..."
                                  : "Delete"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              ) : (
                <div className="no-data">
                  <LoadingButton />
                </div>
              )}
            </>
          </tbody>
        </table>
      </div>
      <AddRole
        showRoleModal={showRoleModal}
        setShowRoleModal={setShowRoleModal}
        refetchRoles={refetchRoles}
        setIsRoleReload={setIsRoleReload}
        convertSnakeCaseToTitleCase={convertSnakeCaseToTitleCase}
      />
      <EditRole
        showEditRoleModal={showEditRoleModal}
        setShowEditRoleModal={setShowEditRoleModal}
        refetchRoles={refetchRoles}
        setIsRoleReload={setIsRoleReload}
        selectedRole={selectedRole}
        convertSnakeCaseToTitleCase={convertSnakeCaseToTitleCase}
      />
    </>
  );
}

export default RolesPage;
