import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  useAddScoresMutation,
  useGetSeasonContestantsQuery,
} from "../../../app/apiSlice";
import { useLocation } from "react-router-dom";

const AddJudgesScoresModal = ({
  showAddJudgesScoresModal,
  setShowAddJudgesScoresModal,
  weeks,
  currentWeek,
  scores,
  refetchScores,
  setIsReloadScore,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");
  const [contestants, setContestants] = useState([]);

  const [userInput, setUserInput] = useState([]);
  const formRef = useRef();

  const {
    data: allcontestants,
    isLoading: isContestantsLoading,
    refetch: refetchContestants,
  } = useGetSeasonContestantsQuery({
    showID: activeShow,
    seasonID: season,
  });

  useEffect(() => {
    if (allcontestants) {
      setContestants(allcontestants.data.contestants);
    }
  }, [allcontestants]);

  const handleUserInput = (event, itemId) => {
    let updatedItems = [...userInput];

    const existingItemIndex = updatedItems.findIndex(
      (item) => item.cont_id === itemId
    );

    if (existingItemIndex !== -1) {
      updatedItems[existingItemIndex] = {
        ...updatedItems[existingItemIndex],
        score_value: Number(event.target.value),
        score_type: "judges_score",
      };
    } else {
      updatedItems.push({
        cont_id: itemId,
        score_value: Number(event.target.value),
        score_type: "judges_score",
      });
    }
    setUserInput(updatedItems);
  };

  const [addScores, { data, isSuccess, isError, error, isLoading }] =
    useAddScoresMutation();

  const saveScore = async (e) => {
    try {
      e.preventDefault();
      let is_new_score;
      if (
        (weeks?.length === 1 && scores?.scores?.length === 0) ||
        (currentWeek?.status?.toLowerCase() === "active" &&
          scores?.scores?.length === 0) ||
        (currentWeek?.status?.toLowerCase() === "inactive" &&
          scores?.scores?.length === 0)
      ) {
        is_new_score = false;
      } else {
        is_new_score = true;
      }

      await addScores({
        show_id: activeShow,
        season_id: season,
        scores: userInput,
        is_new_score: is_new_score,
      });
    } catch (error) {
      console.error("Error occurred while saving scores:", error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowAddJudgesScoresModal(false);
      setIsReloadScore(true);
      refetchScores().then(() => {
        setIsReloadScore(false);
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      console.log("error", error);
      toast.error(error?.data?.error_msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);

  const inputScoreRefs = useRef([]);

  useEffect(() => {
    inputScoreRefs.current = Array.from(
      { length: contestants.length },
      (_, i) => inputScoreRefs.current[i] || React.createRef()
    );
  }, [contestants.length]);

  useEffect(() => {
    const handleWheel = (event) => {
      if (
        inputScoreRefs.current.some(
          (ref) => ref.current && ref.current.contains(document.activeElement)
        )
      ) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (
        inputScoreRefs.current.some(
          (ref) => ref.current && ref.current.contains(document.activeElement)
        )
      ) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Modal
        show={showAddJudgesScoresModal}
        onHide={() => setShowAddJudgesScoresModal(false)}
        dialogClassName="score-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Enter Judges Score</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <form onSubmit={saveScore} ref={formRef}>
            {contestants?.length > 0 && (
              <div className="judge my-auto px-md-2">
                <div className="save-pos save-pos1">
                  {!isLoading && (
                    <button type="submit" className="btn btn-orange btn-size">
                      Save
                    </button>
                  )}
                  {isLoading && (
                    <button className="btn btn-orange" disabled>
                      {/* <LoadingButton /> */}
                      Saving...
                    </button>
                  )}
                </div>
              </div>
            )}
            <div className="card-body booking_card card-body-pad">
              {contestants?.length === 0 ? (
                ""
              ) : (
                <>
                  {contestants
                    ?.filter(
                      (contestant) =>
                        contestant?.status?.toLowerCase() !== "evicted"
                    )
                    .map((contestant, i) => {
                      return (
                        <>
                          <div
                            className="d-md-flex d-block justify-content-between w-100 p-2"
                            key={i}
                          >
                            <div className="d-flex align-items-center mobile-width">
                              <div>
                                <div className="const-img-cover">
                                  <img src={contestant?.cover_pic} alt="" />
                                </div>
                              </div>
                              <div className="d-block my-auto ms-4 name-region">
                                <h4 className="my-auto">{contestant?.stage}</h4>
                                <h5 className="my-auto">{contestant?.town}</h5>
                              </div>
                            </div>
                            <div className="judge-box-cover1 my-md-auto">
                              <input
                                required
                                type="number"
                                key={contestant?.id}
                                name="score_value"
                                id="score_value"
                                value={userInput.score_value}
                                onChange={(event) =>
                                  handleUserInput(event, contestant?.id)
                                }
                                className="judge-box-width1"
                                placeholder="Enter Judges Score"
                                ref={inputScoreRefs.current[i]}
                              />
                            </div>
                          </div>
                          <hr />
                        </>
                      );
                    })}

                  {/* evicted */}
                  {contestants
                    ?.filter(
                      (contestant) =>
                        contestant?.status?.toLowerCase() === "evicted"
                    )
                    .map((contestant, i) => {
                      return (
                        <>
                          <div
                            className="d-block d-md-flex justify-content-between my-3 w-100 p-2 evicted-bg"
                            key={i}
                          >
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="const-img-cover">
                                  <img src={contestant?.cover_pic} alt="" />
                                </div>
                              </div>
                              <div className="d-block my-auto ms-4 name-region">
                                <h4 className="my-auto">{contestant?.stage}</h4>
                                <h5 className="my-auto evicted-color">
                                  {contestant?.town}
                                </h5>
                              </div>
                            </div>
                            <div className="judge-box-cover1 my-md-auto">
                              <input
                                type="text"
                                className="judge-box-width-evicted1"
                                placeholder="Evicted"
                                readOnly
                              />
                            </div>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                </>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default AddJudgesScoresModal;
