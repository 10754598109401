import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function LoadingToRedirect() {
  const navigate = useNavigate();
  const [count, setCount] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => currentCount - 1);
    }, 1000);
    count === 0 && navigate("/");
    return () => clearInterval(interval);
  }, [count, navigate]);

  return (
    <div className="div-cover">
      <div className="div-inner-cover">
        <h4>Redirecting you in {count} secs</h4>
      </div>
    </div>
  );
}

export default LoadingToRedirect;
