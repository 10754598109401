import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const UpdateVideo = ({
  setShowUpdateVidsModal,
  showUpdateVidsModal,
  selectedVideo,
  refetchVideos,
  setIsVideosReload,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeGalleryShow = queryParams.get("show");
  const selectedGallerySeason = queryParams.get("season");
  const activeAlbum = queryParams.get("album");
  

  const [userInput, setUserInput] = useState([]);

  useEffect(() => {
    setUserInput(selectedVideo);
  }, [selectedVideo]);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [isloading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const youtubeUrlRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|shorts\/)?([a-zA-Z0-9_-]+)/;
    const data = new FormData();
    data.append("video_url", userInput.video_url);
    data.append("video_caption", userInput.video_caption);
    data.append("media_type", "episode");
    data.append("desc", userInput?.video_description);
    data.append("show_id", activeGalleryShow);
    data.append("season_id", selectedGallerySeason);
    data.append("album_id", activeAlbum);
    data.append("_method", "put");
    {
      selectedImage && data.append("thumbnail", selectedImage);
    }
    if (youtubeUrlRegex.test(userInput.video_url)) {
      axios
        .post(`/api/v3/videos/${selectedVideo?.id}`, data)
        .then((res) => {
          setLoading(false);
          let msg = res.data.message;
          toast.success(msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setShowUpdateVidsModal(false);
          setSelectedImage(null);
          setIsVideosReload(true);
          refetchVideos().then(() => {
            setIsVideosReload(false);
          });
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);

          if (err?.response?.status === 401) {
            toast.error(err?.response?.data?.errors?.name[0], {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } else {
      setLoading(false);
      toast.error("Invalid Youtube URL", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <>
      <Modal
        show={showUpdateVidsModal}
        onHide={setShowUpdateVidsModal}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Update Video</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4 mt-md-0">
              <div className="email-font">
                <label htmlFor="video_url">
                  <h4>Video URL</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="text"
                  name="video_url"
                  id="video_url"
                  value={userInput?.video_url}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter Youtube URL"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="video_caption">
                  <h4>Video Caption</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <input
                  type="text"
                  name="video_caption"
                  id="video_caption"
                  value={userInput?.video_caption}
                  onChange={handleUserInput}
                  className="text-box-width1"
                  placeholder="Enter video caption"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="video_description">
                  <h4>Video Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="video_description"
                  id="video_description"
                  value={userInput.video_description}
                  onChange={handleUserInput}
                  className="text-area-width1"
                  placeholder="Enter video description"
                  required
                ></textarea>
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="picture-box-cover">
                <input
                  type="file"
                  id="uploadBtn"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
                {selectedImage ? (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <div className="upload-cover-img">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="uploaded-file"
                          className="show_img"
                        />
                      </div>
                    </div>
                  </label>
                ) : (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <div className="upload-cover-img">
                        <img
                          src={userInput?.thumbnail}
                          alt="uploaded-file"
                          className="show_img"
                        />
                      </div>
                    </div>
                  </label>
                )}
              </div>
            </div>
            <div className="d-block mt-4">
              {!isloading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Update Video"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1"
                  />
                </div>
              )}
              {isloading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Updating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default UpdateVideo;
