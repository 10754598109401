import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const UpdateAlbum = ({
  editAlbumModal,
  setEditAlbumModal,
  selectedAlbum,
  refetchAlbums,
  setIsReloadAlbum,
}) => {
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [userInput, setUserInput] = useState([]);

  useEffect(() => {
    if (selectedAlbum) setUserInput(selectedAlbum);
  }, [selectedAlbum]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("album_type", userInput.album_type);
    data.append("name", userInput.name);
    data.append("_method", "put");
    data.append("desc", userInput?.description);
    {
      selectedImage && data.append("thumbnail", selectedImage);
    }
    axios
      .post(`/api/v3/albums/${userInput?.id}`, data)
      .then((res) => {
        setLoading(false);
        let msg = res.data.message;
        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setEditAlbumModal(false);
        setIsReloadAlbum(true);
        refetchAlbums().then(() => {
          setIsReloadAlbum(false);
        });
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);

        if (err?.response?.status === 401) {
          toast.error(err?.response?.data?.errors?.name[0], {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  return (
    <>
      <Modal
        show={editAlbumModal}
        onHide={() => setEditAlbumModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Update Album</h4>
            </div>
            <div></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-block mt-4">
              <div className="email-font mt-4 mt-md-0">
                <label htmlFor="email">
                  <h4>Album Name</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <img
                  src="/assets/img/profile.svg"
                  alt=""
                  className="text-box-img"
                />
                <input
                  type="text"
                  name="name"
                  id="email"
                  value={userInput?.name}
                  onChange={handleUserInput}
                  className="text-box-width12"
                  placeholder="Enter Album Name"
                  required
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="email">
                  <h4>Album Description</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <textarea
                  name="description"
                  value={userInput?.description}
                  onChange={handleUserInput}
                  className="text-area-width"
                  placeholder="Write text here..."
                ></textarea>
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="email-font">
                <label htmlFor="album_type">
                  <h4>Select Album Type</h4>
                </label>
              </div>
              <div className="text-box-cover">
                <select
                  name="album_type"
                  id="album_type"
                  className="select-box-width"
                  value={userInput?.album_type}
                  onChange={handleUserInput}
                  required
                >
                  <option value="">-- select --</option>
                  <option value="gallery">Gallery</option>
                  <option value="videos">Videos</option>
                </select>
                <img
                  src="/assets/img/arrow-down.svg"
                  alt=""
                  className="select-box-img"
                />
              </div>
            </div>
            <div className="d-block mt-4">
              <div className="picture-box-cover">
                <input
                  type="file"
                  id="uploadBtn"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
                {selectedImage ? (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <div className="upload-cover-img">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="uploaded-file"
                          className="show_img"
                        />
                      </div>
                    </div>
                  </label>
                ) : (
                  <label htmlFor="uploadBtn">
                    <div className="pic-section-width cursor-pointer">
                      <div className="upload-cover-img">
                        <img
                          src={userInput?.thumbnail}
                          alt="uploaded-file"
                          className="show_img"
                        />
                      </div>
                    </div>
                  </label>
                )}
              </div>
            </div>
            <div className="d-block mt-4">
              {!isloading && (
                <div className="create-btn-cover">
                  <input
                    type="submit"
                    className="btn btn-warning create-font"
                    name=""
                    value="Update Album"
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
              {isloading && (
                <div className="create-btn-cover">
                  {/* <LoadingButton /> */}

                  <input
                    className="btn btn-warning create-font"
                    disabled
                    name=""
                    value="Updating..."
                  />
                  <img
                    src="/assets/img/arrow-right.svg"
                    alt=""
                    className="create-btn-img1 d-none d-md-block"
                  />
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default UpdateAlbum;
