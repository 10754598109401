import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Nav from "../components/partials/Nav";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllShowsQuery } from "../app/apiSlice";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [allShows, setAllShows] = useState([]);
  const navigate = useNavigate();
  const { data: shows, isError, error } = useGetAllShowsQuery();
  const profile = JSON?.parse(secureLocalStorage?.getItem("user"))?.user;

  useEffect(() => {
    if (shows) {
      setAllShows(shows.data.shows);
    }
  }, [shows]);

  const moveToShowsPage = () => {
    navigate("/reality-shows");
  };
  const moveToGalleryPage = () => {
    navigate("/gallery");
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1, transition: { duration: 1 } }}
      >
        <Nav />
        <>
          <div className="container body-width">
            <div className="col-md-12">
              <div className="indication">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <img src="/assets/img/Vector.svg" alt="" className="me-2" />
                  </div>
                  <div className="my-auto">
                    <h4 className="my-auto mt-1">Home</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="name-section-div">
                <div className="name-cover">
                  <h4>Hello, {profile?.full_name}</h4>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="name-section-div">
                <div className="welcome-text-font">
                  <h5>Welcome to the Reality Show Dashboard</h5>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row  mt-3">
                <div className="col-md-6 col-12">
                  <div className="name-section-div" onClick={moveToShowsPage}>
                    <div className="show-background">
                      <div className="inner-background">
                        <div className="d-flex justify-content-between">
                          <div className="current-background">
                            <h4>Shows Running Currently</h4>
                          </div>
                          <div className="number-cover">
                            <h4>{allShows?.length}</h4>
                          </div>
                        </div>
                        <div className="reality-text">
                          <h5 className="text-start">Reality Shows</h5>
                        </div>
                      </div>
                      <div className="reality-animation">
                        <div className="inner-animation-text">
                          <h1>Reality</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="name-section-div" onClick={moveToGalleryPage}>
                    <div className="show-background1">
                      <div className="inner-background">
                        <div className="d-flex justify-content-between">
                          <div className="current-background">
                            <h4>Pictures and videos</h4>
                          </div>
                          <div className="number-cover1"></div>
                        </div>
                        <div className="reality-text">
                          <h5 className="text-start">Gallery</h5>
                        </div>
                      </div>
                      <div className="reality-animation">
                        <div className="inner-animation-text1">
                          <h1>Gallery</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </motion.div>
    </>
  );
};
export default Dashboard;
