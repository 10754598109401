import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import LoadingButton from "../../../components/LoadingButton";
import { useLocation } from "react-router-dom";
import { useGetCategoryQuery } from "../../../app/apiSlice";
const EditContestantModal = ({
  setContestantEditModal,
  contestantEditModal,
  selectedContestant,
  setIsReloadContestants,
  refetchContestants,
  activeContestant,
  setIsReloadContestant,
  refetchContestant,
  isReloadContestant,
  teamID,
  team_name,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");

  const [currentScreen, setCurrentScreen] = useState(1);
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdatingSocial, setUpdatingSocial] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedSocialIndex, setSelectedSocialIndex] = useState(null);
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [isDeleting, setDeleting] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [update, setUpdate] = useState(false);
  const [isLength1, setLength1] = useState(false);
  const [isSocialLength1, setSocialLength1] = useState(false);
  const [updateSocial, setUpdateSocial] = useState(false);
  const [newNumber, setNewNumber] = useState(false);
  const [newSocial, setNewSocial] = useState(false);
  const [isloading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState([]);

  const { data: category } = useGetCategoryQuery(
    {
      teamID: teamID,
    },
    {
      skip: !teamID || teamID === null || teamID === "",
    }
  );

  const inputHeightRef = useRef(null);
  const inputWeightRef = useRef(null);
  const inputAgeRef = useRef(null);
  const inputHubtelIDRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      if (
        document.activeElement === inputHeightRef.current ||
        document.activeElement === inputWeightRef.current ||
        document.activeElement === inputAgeRef.current ||
        document.activeElement === inputHubtelIDRef.current
      ) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (document.activeElement === inputHeightRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
      if (document.activeElement === inputWeightRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
      if (document.activeElement === inputAgeRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
      if (document.activeElement === inputHubtelIDRef.current) {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  useEffect(() => {
    if (category) {
      setCategorySelected(category?.data?.team);
    }
  }, [category]);

  useEffect(() => {
    if (activeContestant) {
      setUserInput(activeContestant);
    }
  }, [activeContestant]);

  useEffect(() => {
    if (userInput) {
      if (userInput?.phone_numbers?.length > 0) {
        setPhoneNumbers(userInput.phone_numbers);
      } else {
        setPhoneNumbers([{ network: "", number: "" }]);
      }

      if (userInput?.social_accounts?.length > 0) {
        setSocialAccounts(userInput.social_accounts);
      } else {
        setSocialAccounts([{ socialType: "", socialHandle: "" }]);
      }
    }
  }, [userInput]);
  const hobbiesString = userInput?.hobbies?.split(",") || [];
  const [tags, setTags] = useState([]);
  const updatePage = (id) => {
    setCurrentScreen(id);
  };

  useEffect(() => {
    if (hobbiesString) {
      setTags(hobbiesString);
    }
  }, [userInput?.hobbies]);

  let spacebarCount = 0;
  const addTags = (e) => {
    if (e.key === " ") {
      spacebarCount++;

      if (spacebarCount === 1) {
        setTimeout(() => {
          spacebarCount = 0;
        }, 300);
      } else if (spacebarCount === 2) {
        setTags([...tags, e.target.value]);
        e.target.value = "";

        spacebarCount = 0;
      }
    }
  };

  const removeTag = (i) => {
    setTags(tags.filter((_, index) => index != i));
  };
  const handleSocialsDelete = (socialID, i) => {
    if (socialID) {
      if (userInput?.social_accounts?.length === 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Contestant currently has just one social account!. This can't be deleted",
        });
      } else {
        Swal.fire({
          title: "Are you sure you want to delete this social account?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#febf4c",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I'm sure",
          cancelButtonText: "No, cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            const data = new FormData();
            data.append("cont_id", userInput?.id);
            setDeleting(false);
            axios
              .post(
                `/api/v1/contestants/social/${socialID}?_method=delete`,
                data
              )
              .then((res) => {
                let msg = res.data.message;
                setDeleting(true);
                toast.success(msg, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                setIsReloadContestants(true);
                refetchContestants().then(() => {
                  setIsReloadContestants(false);
                });
                setIsReloadContestant(true);
                refetchContestant().then(() => {
                  setIsReloadContestant(false);
                });
              })
              .catch((err) => {
                setDeleting(false);
                console.log(err.response);
              });
          }
        });
      }
    } else {
      const list = [...socialAccounts];
      list.splice(i, 1);
      setSocialAccounts(list);
    }
  };

  const handleNumbersDelete = (phoneID, i) => {
    if (phoneID) {
      if (userInput?.phone_numbers?.length === 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Contestant currently has just one phone number!. This can't be deleted",
        });
      } else {
        Swal.fire({
          title: "Are you sure you want to delete this phone number?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#febf4c",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I'm sure",
          cancelButtonText: "No, cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            const data = new FormData();
            data.append("cont_id", userInput?.id);
            setDeleting(false);
            axios
              .post(`/api/v1/contestants/phone/${phoneID}?_method=delete`, data)
              .then((res) => {
                let msg = res.data.message;
                setDeleting(true);
                toast.success(msg, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                setIsReloadContestants(true);
                refetchContestants().then(() => {
                  setIsReloadContestants(false);
                });

                setIsReloadContestant(true);
                refetchContestant().then(() => {
                  setIsReloadContestant(false);
                });
              })
              .catch((err) => {
                setDeleting(false);
                console.log(err.response);
              });
          }
        });
      }
    } else {
      const list = [...phoneNumbers];
      list.splice(i, 1);
      setPhoneNumbers(list);
    }
  };

  const handlePhoneChange = (e, index, phoneID) => {
    setSelectedIndex(index);
    const { name, value } = e.target;
    if (phoneID) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
    const updatedPhoneNumbers = [...phoneNumbers];
    if (updatedPhoneNumbers?.length === 1) {
      setLength1(true);
    } else {
      setLength1(false);
    }
    updatedPhoneNumbers[index] = {
      ...updatedPhoneNumbers[index],
      [name]: value,
      errorType: "",
      errorHandle: "",
    };

    const isNetworkNotEmpty = updatedPhoneNumbers[index].network !== "";
    const isNumberNotEmpty = updatedPhoneNumbers[index].number !== "";
    setNewNumber(isNetworkNotEmpty || isNumberNotEmpty);
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const handleSocialChange = (e, index, socialID) => {
    setSelectedSocialIndex(index);
    const { name, value } = e.target;
    if (socialID) {
      setUpdateSocial(true);
    } else {
      setUpdateSocial(false);
    }
    const updatedSocialAccounts = [...socialAccounts];
    if (updatedSocialAccounts?.length === 1) {
      setSocialLength1(true);
    } else {
      setSocialLength1(false);
    }
    updatedSocialAccounts[index] = {
      ...updatedSocialAccounts[index],
      [name]: value,
      errorType: "",
      errorHandle: "",
    };

    const isTypeNotEmpty = updatedSocialAccounts[index].social_type !== "";
    const isHandleNotEmpty = updatedSocialAccounts[index].social_handle !== "";
    setNewSocial(isTypeNotEmpty || isHandleNotEmpty);
    setSocialAccounts(updatedSocialAccounts);
  };
  const handleUpdateNumber = (e, phone) => {
    e.preventDefault();
    if (phone?.id) {
      Swal.fire({
        title: "Are you sure you want to update this social account?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#febf4c",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I'm sure",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          setUpdating(true);
          const data = new FormData();
          data.append("cont_id", userInput?.id);
          data.append("network", phone.network);
          data.append("number", phone.number);
          axios
            .post(`/api/v1/contestants/phone/${phone?.id}?_method=put`, data)
            .then((res) => {
              setUpdating(false);
              let msg = res.data.message;
              toast.success(msg, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setUpdate(false);
              setNewNumber(false);
              setLength1(false);
              setSocialLength1(false);
              setIsReloadContestants(true);
              refetchContestants().then(() => {
                setIsReloadContestants(false);
              });

              setIsReloadContestant(true);
              refetchContestant().then(() => {
                setIsReloadContestant(false);
              });
            })
            .catch((err) => {
              setUpdating(false);
              console.log(err.response);
              if (err?.response?.status === 422) {
                toast.error("The number format is invalid.", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            });
        }
      });
    } else {
      setUpdating(true);
      const data = new FormData();
      data.append("cont_id", userInput?.id);
      data.append("network", phone.network);
      data.append("number", phone.number);
      axios
        .post("/api/v1/contestants/phone", data)
        .then((res) => {
          setUpdating(false);
          let msg = res.data.message;
          toast.success(msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setUpdate(false);
          setNewNumber(false);
          setIsReloadContestants(true);
          refetchContestants().then(() => {
            setIsReloadContestants(false);
          });

          setIsReloadContestant(true);
          refetchContestant().then(() => {
            setIsReloadContestant(false);
          });
        })
        .catch((err) => {
          setUpdating(false);
          console.log(err.response);
          if (err?.response?.status === 422) {
            toast.error("The number format is invalid.", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    }
  };

  const handleUpdateSocial = (e, social) => {
    e.preventDefault();
    if (social?.id) {
      Swal.fire({
        title: "Are you sure you want to update this social account?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#febf4c",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I'm sure",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          setUpdatingSocial(true);
          const data = new FormData();
          data.append("cont_id", userInput?.id);
          data.append("social_type", social.social_type);
          data.append("social_handle", social.social_handle);
          data.append("social_desc", null);
          axios
            .post(`/api/v1/contestants/social/${social?.id}?_method=put`, data)
            .then((res) => {
              setUpdatingSocial(false);
              let msg = res.data.message;
              toast.success(msg, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setUpdateSocial(false);
              setNewSocial(false);
              setIsReloadContestants(true);
              refetchContestants().then(() => {
                setIsReloadContestants(false);
              });

              setIsReloadContestant(true);
              refetchContestant().then(() => {
                setIsReloadContestant(false);
              });
            })
            .catch((err) => {
              setUpdatingSocial(false);
              console.log(err.response);
              // if (err?.response?.status === 422) {
              //   toast.error("The number format is invalid.", {
              //     position: "top-right",
              //     autoClose: 1000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     theme: "colored",
              //   });
              // }
            });
        }
      });
    } else {
      setUpdatingSocial(true);
      const data = new FormData();
      data.append("cont_id", userInput?.id);
      data.append("social_type", social.social_type);
      data.append("social_handle", social.social_handle);
      data.append("social_desc", null);
      axios
        .post("/api/v1/contestants/social", data)
        .then((res) => {
          setUpdatingSocial(false);
          let msg = res.data.message;
          toast.success(msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setUpdateSocial(false);
          setNewSocial(false);
          setIsReloadContestants(true);
          refetchContestants().then(() => {
            setIsReloadContestants(false);
          });

          setIsReloadContestant(true);
          refetchContestant().then(() => {
            setIsReloadContestant(false);
          });
        })
        .catch((err) => {
          setUpdatingSocial(false);
          console.log(err.response);
          // if (err?.response?.status === 422) {
          //   toast.error("The number format is invalid.", {
          //     position: "top-right",
          //     autoClose: 1000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "colored",
          //   });
          // }
        });
    }
  };
  const handleInputs = (event) => {
    const selectedName = event.target.name;
    const selectedValue = event.target.value;
    setUserInput((userInput) => ({
      ...userInput,
      [selectedName]: selectedValue,
    }));
  };

  const handleAddPhoneNumber = () => {
    setLength1(false);
    const newPhoneNumber = {
      network: "",
      number: "",
    };
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers.push(newPhoneNumber);
    setPhoneNumbers(updatedPhoneNumbers);
  };
  const handleAddSocialAccount = () => {
    setSocialLength1(false);
    const newSocialAccount = {
      social_type: "",
      social_handle: "",
    };
    const updatedSocialAccounts = [...socialAccounts];
    updatedSocialAccounts.push(newSocialAccount);
    setSocialAccounts(updatedSocialAccounts);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const birthDate = new Date(userInput?.dob);
    const dateDiff = Date.now() - birthDate.getTime();
    const ageYear = new Date(dateDiff);

    const age = Math.abs(ageYear.getUTCFullYear() - 1970);

    const data = new FormData();
    data.append("first_name", userInput.first_name);
    data.append("region", userInput?.region || "");
    if (categorySelected?.id !== undefined && categorySelected?.id !== null) {
      data.append("team_id", categorySelected?.id);
    }
    data.append("residence", userInput.residence || "");
    if (userInput.age !== undefined && userInput.age !== null) {
      data.append("age", parseInt(userInput.age));
    }
    data.append("stage_name", userInput.stage);
    data.append("best_performer", parseInt(userInput.best_performer));
    data.append("school_name", userInput.school || "");
    userInput.height && data.append("height", parseFloat(userInput?.height));
    if (userInput.weight !== undefined && userInput.weight !== null) {
      data.append("weight", parseFloat(userInput.weight));
    }
    if (userInput.hubtel_id !== undefined && userInput.hubtel_id !== null) {
      data.append("hubtel_id", parseInt(userInput.hubtel_id));
    }

    data.append("last_name", userInput.last_name);
    data.append("other_names", userInput.other_names || "");
    data.append("gender", userInput.gender || "");
    data.append("town_name", userInput.town || "");
    data.append("show_id", activeShow);
    data.append("season_id", season);
    data.append("status", userInput.status);
    data.append("hobbies", tags.join(", ") || "");
    data.append("biography", userInput?.bio || "");

    const picturesData = [
      {
        img_url: selectedImage,
        img_desc: "this is the profile image",
        img_title: "Profile Pic",
        img_type: "profile_pic",
      },
    ];
    picturesData.forEach((picture, index) => {
      const pictureKeys = Object.keys(picture);

      pictureKeys.forEach((key) => {
        selectedImage &&
          data.append(`pictures[${index}][${key}]`, picture[key]);
      });
    });

    if (selectedImage < 1) {
      setLoading(false);
      toast.error("Contestant image is required", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else
      axios
        .post(`/api/v3/contestants/${userInput?.id}?_method=put`, data)
        .then((res) => {
          setLoading(false);
          let msg = res.data.message;

          toast.success(msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setContestantEditModal(false);
          setIsReloadContestants(true);
          refetchContestants().then(() => {
            setIsReloadContestants(false);
          });
          setIsReloadContestant(true);
          refetchContestant().then(() => {
            setIsReloadContestant(false);
          });
        })
        .catch((err) => {
          console.log(err.response.data);
          setLoading(false);
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data?.message?.name, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          if (err?.response?.status === 422) {
            toast.error("Phone number(s) already been taken", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
  };
  const updatePicture = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("img_desc", "this is the profile image");
    data.append("img_title", "Profile Pic");
    data.append("img_type", "profile_pic");
    selectedImage && data.append("img_url", selectedImage);

    axios
      .post(
        `/api/v4/contestants/picture/${userInput?.profile_pic?.id}?_method=put`,
        data
      )
      .then((res) => {
        setLoading(false);
        let msg = res.data.message;

        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setContestantEditModal(false);
        setIsReloadContestants(true);
        refetchContestants().then(() => {
          setIsReloadContestants(false);
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
        if (err?.response?.status === 400) {
          toast.error(err?.response?.data?.message?.name, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (err?.response?.status === 422) {
          toast.error("Phone number(s) already been taken", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  const closeModal = () => {
    setContestantEditModal(false);
    setCurrentScreen(1);
  };
  return (
    <>
      <Modal
        show={contestantEditModal}
        onHide={closeModal}
        dialogClassName={currentScreen === 3 ? "my-modal" : "contestant-modal"}
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <div className="modal-h-text">
              <h4>Update Contestant</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 mt-4">
            <div className="d-flex mrg-small">
              <div className="me-md-4">
                <div
                  onClick={() => setCurrentScreen(1)}
                  className={
                    currentScreen === 1
                      ? "active-btn1 cursor-pointer"
                      : "inactive1 cursor-pointer"
                  }
                >
                  <h4>Personal Info</h4>
                </div>
              </div>
              <div className="me-md-4 float-end">
                <div
                  onClick={() => updatePage(2)}
                  className={
                    currentScreen === 2
                      ? "active-btn1 cursor-pointer"
                      : "inactive1 cursor-pointer"
                  }
                >
                  <h4>Contact Info</h4>
                </div>
              </div>
              <div className="me-md-4 float-end">
                <div
                  onClick={() => updatePage(3)}
                  className={
                    currentScreen === 3
                      ? "active-btn1 cursor-pointer"
                      : "inactive1 cursor-pointer"
                  }
                >
                  <h4>Profile Picture</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="underl"></div>
          </div>
          {!isReloadContestant ? (
            <>
              {currentScreen === 1 && (
                <Fade>
                  <form onSubmit={handleSubmit}>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-block">
                            <div className="email-font">
                              <label htmlFor="first_name">
                                <h4>First Name</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <img
                                src="/assets/img/profile.svg"
                                alt=""
                                className="text-box-img"
                              />
                              <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                value={userInput?.first_name}
                                onChange={handleInputs}
                                className="text-box-width"
                                placeholder="Enter First Name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4 mt-md-0">
                            <div className="email-font">
                              <label htmlFor="last_name">
                                <h4>Last Name</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <img
                                src="/assets/img/profile.svg"
                                alt=""
                                className="text-box-img"
                              />
                              <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                value={userInput?.last_name}
                                onChange={handleInputs}
                                className="text-box-width"
                                placeholder="Enter Last Name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="other_names">
                                <h4>Other Names</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <img
                                src="/assets/img/profile.svg"
                                alt=""
                                className="text-box-img"
                              />
                              <input
                                type="text"
                                name="other_names"
                                id="other_names"
                                value={userInput?.other_names}
                                onChange={handleInputs}
                                className="text-box-width"
                                placeholder="Enter Other Names"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="stage">
                                <h4>Stage Name</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <img
                                src="/assets/img/profile.svg"
                                alt=""
                                className="text-box-img"
                              />
                              <input
                                type="text"
                                name="stage"
                                id="stage"
                                value={userInput?.stage}
                                onChange={handleInputs}
                                className="text-box-width"
                                placeholder="Enter Stage Name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="height">
                                <h4>Height</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <input
                                type="number"
                                name="height"
                                id="height"
                                value={userInput?.height}
                                onChange={handleInputs}
                                className="text-box-width1"
                                placeholder="Height"
                                ref={inputHeightRef}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="Weight">
                                <h4>Weight</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <input
                                type="number"
                                name="weight"
                                id="weight"
                                value={userInput?.weight ?? ""}
                                onChange={handleInputs}
                                className="text-box-width1"
                                placeholder="Weight"
                                ref={inputWeightRef}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="gender">
                                <h4>Gender</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <select
                                name="gender"
                                id="gender"
                                className="select-box-width"
                                value={userInput?.gender?.toLowerCase()}
                                onChange={handleInputs}
                              >
                                <option value="">-- select --</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <img
                                src="/assets/img/arrow-down.svg"
                                alt=""
                                className="select-box-img"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="age">
                                <h4>Age</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <input
                                type="number"
                                name="age"
                                id="age"
                                value={userInput?.age}
                                onChange={handleInputs}
                                className="text-box-width1"
                                ref={inputAgeRef}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="hubtel_id">
                                <h4>Hubtel ID</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <input
                                type="number"
                                name="hubtel_id"
                                id="hubtel_id"
                                value={userInput?.hubtel_id}
                                onChange={handleInputs}
                                className="text-box-width1"
                                ref={inputHubtelIDRef}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="town">
                                <h4>Hometown</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <input
                                type="text"
                                name="town"
                                id="town"
                                value={userInput?.town}
                                onChange={handleInputs}
                                className="text-box-width1"
                                placeholder="Enter Town's Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="region">
                                <h4>Region</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <select
                                name="region"
                                id="region"
                                className="select-box-width"
                                value={userInput.region}
                                onChange={handleInputs}
                              >
                                <option value="">-- Select --</option>
                                <option value="Asanti">Ashanti</option>
                                <option value="Bono">Bono</option>
                                <option value="Bono East">Bono East</option>
                                <option value="Ahafo">Ahafo</option>
                                <option value="Central">Central</option>
                                <option value="Eastern">Eastern</option>
                                <option value="Greater Accra">
                                  Greater Accra
                                </option>
                                <option value="Northern">Northern</option>
                                <option value="Savannah">Savannah</option>
                                <option value="North East">North East</option>
                                <option value="Upper East">Upper East</option>
                                <option value="Upper West">Upper West</option>
                                <option value="Volta">Volta</option>
                                <option value="Oti">Oti</option>
                                <option value="Western">Western</option>
                                <option value="Western North">
                                  Western North
                                </option>
                              </select>
                              <img
                                src="/assets/img/arrow-down.svg"
                                alt=""
                                className="select-box-img"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="school">
                                <h4>Last School Attended</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <input
                                type="text"
                                name="school"
                                id="school"
                                value={userInput?.school}
                                onChange={handleInputs}
                                className="text-box-width1"
                                placeholder="Enter School's Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="residence">
                                <h4>Residence</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <input
                                type="text"
                                name="residence"
                                id="residence"
                                value={userInput?.residence}
                                onChange={handleInputs}
                                className="text-box-width1"
                                placeholder="Enter Residence"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="gender">
                                <h4>Status</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <select
                                name="status"
                                id="status"
                                className="select-box-width"
                                value={userInput?.status}
                                onChange={handleInputs}
                                required
                              >
                                <option value="">-- Select --</option>
                                <option value="active">Active</option>
                                <option value="evicted">Evicted</option>
                                <option value="winner">Winner</option>
                                <option value="1st_runner_up">
                                  1st Runner Up
                                </option>
                                <option value="2nd_runner_up">
                                  2nd Runner Up
                                </option>
                              </select>
                              <img
                                src="/assets/img/arrow-down.svg"
                                alt=""
                                className="select-box-img"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="gender">
                                <h4>Best Performer</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <select
                                name="best_performer"
                                id="best_performer"
                                className="select-box-width"
                                value={userInput?.best_performer}
                                onChange={handleInputs}
                                required
                              >
                                <option value="">-- Select --</option>
                                <option value="1">True</option>
                                <option value="0">False</option>
                              </select>
                              <img
                                src="/assets/img/arrow-down.svg"
                                alt=""
                                className="select-box-img"
                              />
                            </div>
                          </div>
                        </div>
                        {team_name && (
                          <div className="col-md-12">
                            <div className="d-block mt-4">
                              <div className="email-font">
                                <label htmlFor="gender">
                                  <h4>Category</h4>
                                </label>
                              </div>
                              <div className="text-box-cover">
                                <input
                                  type="text"
                                  value={categorySelected?.name}
                                  readOnly
                                  className="text-box-width1"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <div className="d-block mt-4">
                            <div className="text-box-cover">
                              <textarea
                                name="bio"
                                id="bio"
                                value={userInput?.bio}
                                onChange={handleInputs}
                                className="text-area-width1"
                                placeholder="Enter Contestant's Bio"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="d-block mt-4">
                            <div className="email-font">
                              <label htmlFor="hobbies">
                                <h4>Hobbies</h4>
                              </label>
                            </div>
                            <div className="text-box-cover">
                              <div className="tags-input">
                                <ul className="tag-ul">
                                  {tags?.map((tag, index) => {
                                    return (
                                      <li key={index}>
                                        <span>{tag}</span>
                                        <img
                                          src="/assets/img/close1.svg"
                                          alt=""
                                          className="cursor-pointer"
                                          onClick={() => removeTag(index)}
                                        />
                                      </li>
                                    );
                                  })}
                                </ul>
                                <input
                                  type="text"
                                  name="hobbies"
                                  id="hobbies"
                                  onKeyUp={(e) =>
                                    e.key === " " ? addTags(e) : null
                                  }
                                  className="text-box-tag"
                                  placeholder="Type and double press space to add tags"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-block mt-4">
                      {!isloading && (
                        <div className="create-btn-cover">
                          <input
                            type="submit"
                            className="btn btn-warning create-font"
                            name=""
                            value="Update Contestant"
                          />
                          <img
                            src="/assets/img/arrow-right.svg"
                            alt=""
                            className="create-btn-img1 d-none d-md-block"
                          />
                        </div>
                      )}
                      {isloading && (
                        <div className="create-btn-cover">
                          {/* <LoadingButton /> */}

                          <input
                            className="btn btn-warning create-font"
                            disabled
                            name=""
                            value="Updating..."
                          />
                          <img
                            src="/assets/img/arrow-right.svg"
                            alt=""
                            className="create-btn-img1 d-none d-md-block"
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </Fade>
              )}

              {currentScreen === 2 && (
                <Fade>
                  <div className="col-md-12">
                    {/* {isDeleting ? ( */}
                    <>
                      {socialAccounts.map((social, i) => (
                        <div className="col-md-12">
                          <form
                            className="row d-flex align-items-center "
                            onSubmit={(e) => handleUpdateSocial(e, social)}
                          >
                            <div
                              className={
                                (selectedSocialIndex === i && updateSocial) ||
                                (selectedSocialIndex === i && newSocial)
                                  ? "col-md-5"
                                  : "col-md-6"
                              }
                            >
                              <div className="d-block mt-4">
                                <div className="email-font">
                                  <label htmlFor={`social_type${i}`}>
                                    <h4>Social Media Type</h4>
                                  </label>
                                </div>
                                <div className="text-box-cover">
                                  <select
                                    name="social_type"
                                    id={`social_type${i}`}
                                    className="select-box-width"
                                    value={social?.social_type}
                                    onChange={(e) =>
                                      handleSocialChange(e, i, social?.id)
                                    }
                                    required
                                  >
                                    <option value="">-- select --</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="twitter">Twitter</option>
                                    <option value="instagram">Instagram</option>
                                    <option value="tiktok">Tiktok</option>
                                  </select>
                                  <img
                                    src="/assets/img/arrow-down.svg"
                                    alt=""
                                    className="select-box-img"
                                  />
                                </div>
                                {social.errorType && (
                                  <div className="text-danger">
                                    {social.errorType}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                !isSocialLength1 && socialAccounts.length === 1
                                  ? "col-md-6"
                                  : "col-md-5"
                              }
                            >
                              <div className="d-block mt-4">
                                <div className="email-font">
                                  <label htmlFor={`social_handle${i}`}>
                                    <h4>Social Handle</h4>
                                  </label>
                                </div>
                                <div className="text-box-cover">
                                  <input
                                    type="text"
                                    name="social_handle"
                                    id={`social_handle${i}`}
                                    value={social?.social_handle}
                                    onChange={(e) =>
                                      handleSocialChange(e, i, social?.id)
                                    }
                                    required
                                    className="text-box-width1"
                                    placeholder="Enter Phone Number"
                                  />
                                </div>
                              </div>
                              {social.errorHandle && (
                                <div className="text-danger">
                                  {social.errorHandle}
                                </div>
                              )}
                            </div>
                            {socialAccounts.length !== 1 && (
                              <div
                                className={
                                  (selectedSocialIndex === i && updateSocial) ||
                                  (selectedSocialIndex === i && newSocial)
                                    ? "col-md-2"
                                    : "col-md-1"
                                }
                              >
                                <div className="d-block mt-4 cursor-pointer">
                                  <div className="email-font dis-none">
                                    <label>
                                      <h4></h4>
                                    </label>
                                  </div>
                                  {selectedSocialIndex === i &&
                                  updateSocial &&
                                  social?.id ? (
                                    <>
                                      {!isUpdatingSocial && (
                                        <div className="text-box-cover">
                                          <button
                                            type="sumbit"
                                            className="btn btn-warning w-100"
                                          >
                                            update
                                          </button>
                                        </div>
                                      )}
                                      {isUpdatingSocial && (
                                        <div className="text-box-cover">
                                          <button
                                            className="btn btn-warning w-100"
                                            disabled
                                          >
                                            updating...
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ) : selectedSocialIndex === i &&
                                    newSocial &&
                                    !social?.id ? (
                                    <>
                                      {!isUpdatingSocial && (
                                        <div className="text-box-cover">
                                          <button
                                            type="sumbit"
                                            className="btn btn-warning w-100"
                                          >
                                            save
                                          </button>
                                        </div>
                                      )}
                                      {isUpdatingSocial && (
                                        <div className="text-box-cover">
                                          <button
                                            className="btn btn-warning w-100"
                                            disabled
                                          >
                                            saving...
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="text-box-cover">
                                      <div
                                        className="add-cover"
                                        onClick={() =>
                                          handleSocialsDelete(social?.id, i)
                                        }
                                      >
                                        <div className="minus"></div>
                                        <button
                                          type="button"
                                          className="btn btn-dark rounded-pill w-100 btn-w-font"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {isSocialLength1 &&
                              socialAccounts?.length === 1 && (
                                <div
                                  className={
                                    (selectedSocialIndex === i &&
                                      updateSocial) ||
                                    (selectedSocialIndex === i && newSocial)
                                      ? "col-md-2"
                                      : "col-md-1"
                                  }
                                >
                                  <div className="d-block mt-4 cursor-pointer">
                                    <div className="email-font">
                                      <label>
                                        <h4></h4>
                                      </label>
                                    </div>
                                    <>
                                      {!isUpdatingSocial && (
                                        <div className="text-box-cover">
                                          <button
                                            type="sumbit"
                                            className="btn btn-warning w-100"
                                          >
                                            update
                                          </button>
                                        </div>
                                      )}
                                      {isUpdatingSocial && (
                                        <div className="text-box-cover">
                                          <button
                                            className="btn btn-warning w-100"
                                            disabled
                                          >
                                            updating...
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  </div>
                                </div>
                              )}
                            {socialAccounts.length - 1 === i && (
                              <div className="col-md-12">
                                <div className="d-block mt-4">
                                  <button
                                    className="btn w-100 btn-secondary d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={(e) =>
                                      handleAddSocialAccount(e, i)
                                    }
                                    disabled={
                                      socialAccounts.length - 1 === i &&
                                      socialAccounts.length < 5
                                        ? false
                                        : true
                                    }
                                  >
                                    <img
                                      src="/assets/img/plus1.svg"
                                      alt=""
                                      className="me-2 add-s"
                                    />
                                    Add New Social Account
                                  </button>
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      ))}

                      {phoneNumbers.map((phone, i) => (
                        <div className="col-md-12">
                          <form
                            className="row d-flex align-items-center "
                            onSubmit={(e) => handleUpdateNumber(e, phone)}
                          >
                            <div
                              className={
                                (selectedIndex === i && update) ||
                                (selectedIndex === i && newNumber)
                                  ? "col-md-5"
                                  : "col-md-6"
                              }
                            >
                              <div className="d-block mt-4">
                                <div className="email-font">
                                  <label htmlFor={`network${i}`}>
                                    <h4>Mobile Network</h4>
                                  </label>
                                </div>
                                <div className="text-box-cover">
                                  <select
                                    name="network"
                                    id={`network${i}`}
                                    className="select-box-width"
                                    value={phone?.network}
                                    onChange={(e) =>
                                      handlePhoneChange(e, i, phone?.id)
                                    }
                                    required
                                  >
                                    <option value="">-- select --</option>
                                    <option value="MTN">MTN</option>
                                    <option value="VODAFONE">Vodafone</option>
                                    <option value={"AIRTEL_TIGO"}>
                                      AirtelTigo
                                    </option>
                                    <option value="GLO">GLO</option>
                                  </select>
                                  <img
                                    src="/assets/img/arrow-down.svg"
                                    alt=""
                                    className="select-box-img"
                                  />
                                </div>
                                {phone.errorType && (
                                  <div className="text-danger">
                                    {phone.errorType}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                !isLength1 && phoneNumbers?.length === 1
                                  ? "col-md-6"
                                  : "col-md-5"
                              }
                            >
                              <div className="d-block mt-4">
                                <div className="email-font">
                                  <label htmlFor={`number${i}`}>
                                    <h4>Number</h4>
                                  </label>
                                </div>
                                <div className="text-box-cover">
                                  <input
                                    type="text"
                                    name="number"
                                    id={`number${i}`}
                                    value={phone?.number}
                                    onChange={(e) =>
                                      handlePhoneChange(e, i, phone?.id)
                                    }
                                    required
                                    className="text-box-width1"
                                    placeholder="Enter Phone Number"
                                  />
                                </div>
                              </div>
                              {phone.errorHandle && (
                                <div className="text-danger">
                                  {phone.errorHandle}
                                </div>
                              )}
                            </div>
                            {phoneNumbers.length !== 1 && (
                              <div
                                className={
                                  (selectedIndex === i && update) ||
                                  (selectedIndex === i && newNumber)
                                    ? "col-md-2"
                                    : "col-md-1"
                                }
                              >
                                <div className="d-block mt-4 cursor-pointer">
                                  <div className="email-font dis-none">
                                    <label>
                                      <h4></h4>
                                    </label>
                                  </div>
                                  {selectedIndex === i &&
                                  update &&
                                  phone?.id ? (
                                    <>
                                      {!isUpdating && (
                                        <div className="text-box-cover">
                                          <button
                                            type="sumbit"
                                            className="btn btn-warning w-100"
                                          >
                                            update
                                          </button>
                                        </div>
                                      )}
                                      {isUpdating && (
                                        <div className="text-box-cover">
                                          <button
                                            className="btn btn-warning w-100"
                                            disabled
                                          >
                                            updating...
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ) : selectedIndex === i &&
                                    newNumber &&
                                    !phone?.id ? (
                                    <>
                                      {!isUpdating && (
                                        <div className="text-box-cover">
                                          <button
                                            type="sumbit"
                                            className="btn btn-warning w-100"
                                          >
                                            save
                                          </button>
                                        </div>
                                      )}
                                      {isUpdating && (
                                        <div className="text-box-cover">
                                          <button
                                            className="btn btn-warning w-100"
                                            disabled
                                          >
                                            saving...
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="text-box-cover">
                                      <div
                                        className="add-cover"
                                        onClick={() =>
                                          handleNumbersDelete(phone?.id, i)
                                        }
                                      >
                                        <div className="minus"></div>
                                        <button
                                          type="button"
                                          className="btn btn-dark rounded-pill w-100 btn-w-font"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {isLength1 && phoneNumbers?.length === 1 && (
                              <div
                                className={
                                  (selectedIndex === i && update) ||
                                  (selectedIndex === i && newNumber)
                                    ? "col-md-2"
                                    : "col-md-1"
                                }
                              >
                                <div className="d-block mt-4 cursor-pointer">
                                  <div className="email-font">
                                    <label>
                                      <h4></h4>
                                    </label>
                                  </div>
                                  <>
                                    {!isUpdating && (
                                      <div className="text-box-cover">
                                        <button
                                          type="sumbit"
                                          className="btn btn-warning w-100"
                                        >
                                          update
                                        </button>
                                      </div>
                                    )}
                                    {isUpdating && (
                                      <div className="text-box-cover">
                                        <button
                                          className="btn btn-warning w-100"
                                          disabled
                                        >
                                          updating...
                                        </button>
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                            {phoneNumbers.length - 1 === i && (
                              <div className="col-md-12">
                                <div className="d-block mt-4">
                                  <button
                                    className="btn w-100 btn-secondary d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={(e) => handleAddPhoneNumber(e, i)}
                                    disabled={
                                      phoneNumbers.length - 1 === i &&
                                      phoneNumbers.length < 5
                                        ? false
                                        : true
                                    }
                                  >
                                    <img
                                      src="/assets/img/plus1.svg"
                                      alt=""
                                      className="me-2 add-s"
                                    />
                                    Add New Phone Number
                                  </button>
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      ))}

                      <div className="d-block mt-4">
                        <div className="create-btn-cover">
                          <input
                            type="button"
                            className="btn btn-warning create-font"
                            name=""
                            value="Done"
                            onClick={() => setContestantEditModal(false)}
                          />
                        </div>
                      </div>
                    </>
                    {/* ) : (
                   ""
                   )} */}
                  </div>
                </Fade>
              )}
              {currentScreen === 3 && (
                <Fade>
                  <div className="col-md-12">
                    <div className="d-block mt-4">
                      <div className="picture-box-cover">
                        <input
                          type="file"
                          id="uploadBtn"
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                        {selectedImage ? (
                          <label htmlFor="uploadBtn">
                            <div className="pic-section-width cursor-pointer">
                              <div className="upload-cover-img">
                                <img
                                  src={URL.createObjectURL(selectedImage)}
                                  alt="uploaded-file"
                                  className="show_img"
                                />
                              </div>
                            </div>
                          </label>
                        ) : (
                          <label htmlFor="uploadBtn">
                            <div className="pic-section-width cursor-pointer">
                              <div className="upload-cover-img">
                                <img
                                  src={userInput?.profile_pic?.img_url}
                                  alt="uploaded-file"
                                  className="show_img"
                                />
                              </div>
                            </div>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-block mt-4">
                      {!isloading && (
                        <div className="create-btn-cover">
                          <input
                            type="submit"
                            className="btn btn-warning create-font"
                            name=""
                            value="Update Picture"
                            onClick={updatePicture}
                          />
                          <img
                            src="/assets/img/arrow-right.svg"
                            alt=""
                            className="create-btn-img1 d-none d-md-block"
                          />
                        </div>
                      )}
                      {isloading && (
                        <div className="create-btn-cover">
                          {/* <LoadingButton /> */}

                          <input
                            className="btn btn-warning create-font"
                            disabled
                            name=""
                            value="Updating..."
                          />
                          <img
                            src="/assets/img/arrow-right.svg"
                            alt=""
                            className="create-btn-img1 d-none d-md-block"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Fade>
              )}
            </>
          ) : (
            <div className="loading-c">
              <LoadingButton />
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default EditContestantModal;
