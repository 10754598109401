import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import AnimatedRoute from "./components/AnimatedRoute";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./app/authSlice";
import secureLocalStorage from "react-secure-storage";

function App() {
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  // axios.defaults.baseURL = "https://realityshowsapi-staging.3news.com";
  axios.defaults.baseURL = "https://realityshowsapi.3news.com";
  const token = JSON?.parse(secureLocalStorage?.getItem("user"))?.token;

  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  const dispatch = useDispatch();
  const user = JSON?.parse(secureLocalStorage?.getItem("user") || "{}");
  useEffect(() => {
    dispatch(setUser(user));
  }, []);

  return (
    <BrowserRouter>
      <AnimatedRoute />
    </BrowserRouter>
  );
}

export default App;
