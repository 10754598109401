import React from "react";
import RedirectPage from "./RedirectPage";
import secureLocalStorage from "react-secure-storage";

function SettingsProtectedRoute({ children }) {
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;

  const isCanViewUserIncluded = permissions?.includes("can_view_user");

  return isCanViewUserIncluded ? children : <RedirectPage />;
}

export default SettingsProtectedRoute;
