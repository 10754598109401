import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "../../components/partials/Nav";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { DotLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { useGetShowSeasonQuery } from "../../app/apiSlice";
import { setSelectedGallerySeason } from "../../app/showsSlice";

const GallerySeasonPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const location = useLocation();
  const { code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeGalleryShow = queryParams.get("show");

  const [showSeasons, setShowSeasons] = useState([]);

  const [expandSearch, setExpandSearch] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#febf4c");

  const {
    data: seasons,
    isLoading: isSeasonLoading,
    refetch: refetchSeasons,
  } = useGetShowSeasonQuery({
    showID: activeGalleryShow,
  });

  useEffect(() => {
    if (seasons) {
      setShowSeasons(seasons.data);
    }
  }, [seasons]);

  const handleSeasonClick = (season) => {
    navigate(
      `/gallery/${code}/${season?.code}?show=${activeGalleryShow}&season=${season?.id}`
    );
  };
  const newData = [...showSeasons];
  const sortedData = newData.sort((a, b) => b.id - a.id);
  const filteredList = sortedData?.filter((item) => {
    if (search === "") {
      return item;
    } else if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />
      <div className="container body-width">
        <div className="col-md-12">
          <div className="indication1">
            <div
              className="d-flex justify-content-between cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <div className="">
                <img src="/assets/img/home.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Home</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate("/gallery")}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Gallery</h4>
              </div>
            </div>
            <div className="d-flex justify-content-between ms-2">
              <div className="">
                <img src="/assets/img/arr-right.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1 disabled-color text-capitalize">
                  {code}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
            <div className="col-md-7">
              <div className="title-font text-center text-md-start">
                <h4 className="text-capitalize">{code}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex float-end">
                <div
                  className={expandSearch === true ? "search-active" : "search"}
                >
                  <div
                    className="icon"
                    onClick={() => setExpandSearch((prev) => !prev)}
                  ></div>
                  <div className="input">
                    <input
                      type="text"
                      id="seacrh-box"
                      placeholder={expandSearch === true ? "Search..." : " "}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            {!isSeasonLoading ? (
              <>
                {filteredList?.length === 0 ? (
                  <div className="no-data-cover">
                    <div className="no-data">
                      <h1> No Season Available</h1>
                    </div>
                  </div>
                ) : (
                  <>
                    {filteredList?.map((season, i) => {
                      return (
                        <div className="col-md-4 mt-4" key={i}>
                          <div>
                            <div
                              className={
                                season?.status === "active"
                                  ? "show-card-background-c-active"
                                  : "show-card-background-c"
                              }
                            >
                              <div
                                className="show-card-inner inner1"
                                onClick={() => handleSeasonClick(season)}
                              >
                                <div className="show-title-background1 py-2 px-3">
                                  <h4>
                                    {moment(season?.created_at).format("LL")}
                                  </h4>
                                </div>
                                <div className="show-title1">
                                  <h4>{season?.name}</h4>
                                  <h3>{season?.status}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <div className="loader-h">
                <DotLoader
                  color={color}
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default GallerySeasonPage;
