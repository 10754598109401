import { motion } from "framer-motion";
import Nav from "../../components/partials/Nav";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AddShowModal from "./addShowComponents/AddShowModal";
import EditShowModal from "./addShowComponents/EditShowModal";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useEffect } from "react";
import LoadingPage from "../../components/LoadingPage";
import { useGetAllShowsQuery } from "../../app/apiSlice";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const RealityShowPage = () => {
  const navigate = useNavigate();
  const [isloading, setShowLoading] = useState(false);
  const [selectedShow, setSelectedShow] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isReloadShows, setIsReloadShows] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const [allShows, setAllShows] = useState([]);
  const [search, setSearch] = useState("");
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const isCanCreateShowIncluded = permissions?.includes("can_create_show");
  const isCanUpdateShowIncluded = permissions?.includes("can_update_show");
  const isCanViewShowIncluded = permissions?.includes("can_view_show");

  const {
    data: shows,
    isLoading,
    refetch: refetchShows,
  } = useGetAllShowsQuery();
  useEffect(() => {
    if (shows) {
      setAllShows(shows.data.shows);
    }
  }, [shows]);
  const handleEditShowClick = (show) => {
    setSelectedShow(show);
    setShowEditModal(true);
  };
  const handleShowClick = (selectedShow) => {
    navigate(`/reality-show/${selectedShow?.code}?show=${selectedShow?.id}`);
  };

  const filteredList = allShows?.filter((item) => {
    if (search === "") {
      return item;
    } else if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });
  const handlePublish = (show) => {
    const data = new FormData();
    show?.status?.toLowerCase() === "inactive"
      ? data.append("status", "active")
      : data.append("status", "inactive");
    let message;
    if (show?.status?.toLowerCase() === "inactive") {
      message = "Are you sure you want to publish this show?";
    } else {
      message = "Are you sure you want to unpublish this show?";
    }
    Swal.fire({
      title: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setShowLoading(true);
        let finalmessage;
        if (show?.status?.toLowerCase() === "inactive") {
          finalmessage = "Show has been published successfully";
        } else {
          finalmessage = "Show has been unpublished successfully";
        }
        axios
          .post(`/api/v1/reality_shows/${show?.id}?_method=PUT`, data)
          .then((res) => {
            setShowLoading(false);
            toast.success(finalmessage, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setIsReloadShows(true);
            refetchShows().then(() => {
              setIsReloadShows(false);
            });
          })
          .catch((err) => {
            console.log(err.response);
            setShowLoading(false);
            if (err?.response?.status === 401) {
              toast.error(err?.response?.data?.errors?.name[0], {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      }
    });
  };
  useEffect(() => {
    isloading
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
  }, [isloading]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />

      <>
        <div className="container body-width">
          <div className="col-md-12">
            <div className="indication1">
              <div
                className="d-flex justify-content-between cursor-pointer"
                onClick={() => navigate("/dashboard")}
              >
                <div className="">
                  <img src="/assets/img/home.svg" alt="" className="me-2" />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1">Home</h4>
                </div>
              </div>
              <div className="d-flex justify-content-between ms-2">
                <div className="">
                  <img
                    src="/assets/img/arr-right.svg"
                    alt=""
                    className="me-2"
                  />
                </div>
                <div className="my-auto">
                  <h4 className="my-auto mt-1 disabled-color">Reality Shows</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="row w-100 d-md-flex d-block mt-md-5 justify-content-between align-items-center margin-div">
              <div className="col-md-6 col-12">
                <div className="font-reality text-center text-md-start">
                  <h4>Reality Shows</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex float-end mt-2 mt-md-0">
                  <div
                    className={
                      expandSearch === true
                        ? "search-active me-md-3 me-1"
                        : "search me-md-3 me-1"
                    }
                  >
                    <div
                      className="icon"
                      onClick={() => setExpandSearch((prev) => !prev)}
                    ></div>
                    <div className="input">
                      <input
                        type="text"
                        id="seacrh-box"
                        placeholder={expandSearch === true ? "Search..." : " "}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  {isCanCreateShowIncluded && (
                    <div>
                      <div
                        className="add-cover1 cursor-pointer"
                        data-title="add new show"
                        onClick={() => setShowModal(true)}
                      >
                        <img src="/assets/img/plus.svg" alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="row">
              {!isLoading && !isReloadShows ? (
                <>
                  {filteredList?.map((show, i) => {
                    return (
                      <div className="col-md-4 mt-4 col-12" key={i}>
                        <div>
                          <div
                            className={
                              isCanViewShowIncluded
                                ? "show-card-background cursor-pointer"
                                : "show-card-background"
                            }
                          >
                            <div className="d-flex absolute-constentant-bg1 mt-3">
                              {isCanUpdateShowIncluded && (
                                <>
                                  <div
                                    className="edit-contestant-bg cursor-pointer"
                                    onClick={() => handleEditShowClick(show)}
                                  >
                                    <h4 className="">Edit</h4>
                                  </div>
                                  <div
                                    className="edit-contestant-bg ms-2 show-title-background1-edit cursor-pointer"
                                    onClick={() => handlePublish(show)}
                                  >
                                    <h4 className="text-capitalize">
                                      {show?.status?.toLowerCase() ===
                                      "inactive"
                                        ? "Publish"
                                        : "Unpublish"}
                                    </h4>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="show-card-overlay"></div>
                            <div
                              className="show-card-inner"
                              onClick={() => {
                                isCanViewShowIncluded && handleShowClick(show);
                              }}
                            >
                              <div className="show-title">
                                <h4>{show?.name}</h4>
                              </div>
                            </div>
                            <div className="show-image">
                              <img src={show?.logo_url} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {isloading && <LoadingPage />}
                  {isCanCreateShowIncluded && (
                    <div className="col-md-4 my-4 col-12">
                      <div
                        className="show-card-background1"
                        onClick={() => setShowModal(true)}
                      >
                        <div className="show-card-inner1">
                          <img
                            src="/assets/img/addIcon.svg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <LoadingPage />
              )}
            </div>
          </div>
        </div>
      </>
      {isCanCreateShowIncluded && (
        <AddShowModal
          showModal={showModal}
          setShowModal={setShowModal}
          refetchShows={refetchShows}
          setIsReloadShows={setIsReloadShows}
        />
      )}
      {isCanUpdateShowIncluded && (
        <EditShowModal
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          refetchShows={refetchShows}
          setIsReloadShows={setIsReloadShows}
          selectedShow={selectedShow}
        />
      )}
    </motion.div>
  );
};
export default RealityShowPage;
