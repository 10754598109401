import React, { useEffect, useState } from "react";
import {
  useUpdatePasswordMutation,
} from "../../app/apiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function NewPasswordPage({ tokenCode }) {
  const email = JSON?.parse(secureLocalStorage?.getItem("user"))?.user?.email;

  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [updatePassword, { data, isSuccess, isError, error, isLoading }] =
    useUpdatePasswordMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, password_confirmation } = userInput;
    if (password && password_confirmation) {
      await updatePassword({
        password,
        password_confirmation,
        email,
        token_code: tokenCode,
      });
    } else {
      toast.error("Code required", {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.data?.message, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/");
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      let errorMessages = error?.data?.message;
      let errorMessageString = "";

      for (const key in errorMessages) {
        if (errorMessages.hasOwnProperty(key)) {
          errorMessageString += errorMessages[key].join(", ") + ", ";
        }
      }
      errorMessageString = errorMessageString.slice(0, -2);
      toast.error(errorMessageString, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isError]);
  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="tv3-cover">
        <img
          src="/assets/img/logo1.svg"
          alt=""
          className="img-fluid logo1-size"
        />
      </div>
      <div className="login-text">
        <h4>Reset Password</h4>
      </div>
      <div className="create-btn-cover w-100">
        <div className="mar-t-1">
          <div className="token-font">
            <h6>
              A token has been sent to your email address. Enter in the textbox
              below
            </h6>
          </div>
          <div className="text-box-cover cover-marg">
            <img src="/assets/img/key.png" alt="" className="text-box-key" />
            <input
              type="password"
              name="password"
              id="password"
              className="text-box-width12"
              value={userInput?.password}
              onChange={handleUserInput}
              placeholder="Password"
              required
            />
          </div>
          <div className="text-box-cover cover-marg">
            <img src="/assets/img/key.png" alt="" className="text-box-key" />
            <input
              type="password"
              name="password_confirmation"
              id="password_confirmation"
              className="text-box-width12"
              value={userInput?.password_confirmation}
              onChange={handleUserInput}
              placeholder="Confirm Password"
              required
            />
          </div>
          {!isLoading && (
            <button type="submit" className="btn btn-warning w-100">
              Submit
            </button>
          )}
          {isLoading && (
            <button className="btn btn-warning w-100" disabled>
              Submitting...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default NewPasswordPage;
