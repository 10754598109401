import { useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "../../components/partials/Nav";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import AddPicturesModal from "./albumComponents/AddPicturesModal";
import { DotLoader } from "react-spinners";
import AddVideosModal from "./albumComponents/AddVideosModal";
import { ToastContainer, toast } from "react-toastify";
import ReactPlayer from "react-player";
import UpdateVideo from "./albumComponents/UpdateVideo";
import Swal from "sweetalert2";
import LoadingPage from "../../components/LoadingPage";
import { useRef } from "react";
import {
  useDeletePicturesMutation,
  useDeleteVideoMutation,
  useGetPicturesQuery,
  useGetSeasonContestantsQuery,
  useGetVideosQuery,
} from "../../app/apiSlice";
import { setAlbumType, setIsPicFromCont } from "../../app/showsSlice";

const SelectedAlbumPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const { code, season_code, album_code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeGalleryShow = queryParams.get("show");
  const selectedGallerySeason = queryParams.get("season");
  const activeAlbum = queryParams.get("album");



  const [expandSearch, setExpandSearch] = useState(false);
  const [contestants, setContestants] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isPicturesReload, setIsPicturesReload] = useState(false);
  const [isVideosReload, setIsVideosReload] = useState(false);
  const [search, setSearch] = useState("");
  let [images, setImages] = useState([]);
  let [gallery, setGallery] = useState([]);
  let [videos, setVideos] = useState([]);
  let [loading, setLoading] = useState(true);
  let [isloading, setVideoLoading] = useState(false);
  let [color, setColor] = useState("#febf4c");

  let isPicFromCont = useSelector((state) => state.shows.isPicFromCont);
  let isVideoFromCont = useSelector((state) => state.shows.isVideoFromCont);
  let albumType = useSelector((state) => state.shows.albumType);

  const {
    data: allPictures,
    isLoading: isPicturesLoading,
    refetch: refetchPictures,
  } = useGetPicturesQuery({
    albumID: activeAlbum,
    showID: activeGalleryShow,
    seasonID: selectedGallerySeason,
  });

  const {
    data: allVideos,
    isLoading: isVideosLoading,
    refetch: refetchVideos,
  } = useGetVideosQuery({
    showID: activeGalleryShow,
    seasonID: selectedGallerySeason,
    albumID: activeAlbum,
  });

  const {
    data: allcontestants,
    isLoading: isContestantsLoading,
    refetch: refetchContestants,
  } = useGetSeasonContestantsQuery({
    showID: activeGalleryShow,
    seasonID: selectedGallerySeason,
  });
  useEffect(() => {
    if (allcontestants) {
      setContestants(allcontestants.data.contestants);
    }
  }, [allcontestants]);

  useEffect(() => {
    if (allPictures) {
      setGallery(allPictures.data);
    }
  }, [allPictures]);
  useEffect(() => {
    if (allVideos) {
      setVideos(allVideos.data);
    }
  }, [allVideos]);

  const [youtubeURL, setYoutubeURL] = useState("");
  const [showUpdateVidsModal, setShowUpdateVidsModal] = useState(false);

  useEffect(() => {
    const images = gallery?.pictures?.map((item) => item.img_url);
    setImages(images);
  }, [gallery]);

  const [showUploadPicsModal, setShowUploadPicsModal] = useState(false);
  const [showUploadVidsModal, setShowUploadVidsModal] = useState(false);

  const [currentScreen, setCurrentScreen] = useState(1);
  const [picData, setPicData] = useState({ image: "", i: 0 });
  const touchStartX = useRef(0);
  const updatePage = (id) => {
    setCurrentScreen(id);
  };

  const imgAction = (action) => {
    let i = picData.i;
    if (action === "next-img") {
      setPicData({ image: images[i + 1], i: i + 1 });
    }
    if (action === "prev-img") {
      setPicData({ image: images[i - 1], i: i - 1 });
    }
    if (!action) {
      setPicData({ image: "", i: 0 });
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };
  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (deltaX > 50 && picData.i > 0) {
      imgAction("prev-img");
    } else if (deltaX < -50 && picData.i < images.length - 1) {
      imgAction("next-img");
    }
  };

  const isPreviousDisabled = picData.i === 0;
  const isNextDisabled = picData.i === images?.length - 1;

  picData.image || isloading
    ? (document.body.style.overflowY = "hidden")
    : (document.body.style.overflowY = "auto");

  const viewImage = (image, i, ele) => {
    setPicData({ image, i });
  };
  const openPicModal = () => {
    setShowUploadPicsModal(true);
  };
  const openVidModal = () => {
    setShowUploadVidsModal(true);
  };

  const [
    deleteVideo,
    {
      data: videoData,
      isSuccess: isDeleteVideoSuccess,
      isLoading: isDeletingVideo,
    },
  ] = useDeleteVideoMutation();

  const handleClickDelete = async (video) => {
    const result = await Swal.fire({
      title: "Are you sure you want to delete this video?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    });

    if (result.isConfirmed) {
      if (result.isConfirmed) {
        await deleteVideo({ id: video?.id });
      }
    }
  };

  useEffect(() => {
    if (isDeleteVideoSuccess) {
      toast.success(videoData.msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeleteMode(false);
      setIsVideosReload(true);
      refetchVideos().then(() => {
        setIsVideosReload(false);
      });
    }
  }, [isDeleteVideoSuccess]);

  const videoPreview = (video) => {
    setYoutubeURL(video?.video_url);
    setCurrentScreen(3);
  };
  const handleEditVideoClick = (video) => {
    setSelectedVideo(video);
    setShowUpdateVidsModal(true);
  };
  const filteredList = videos?.videos?.filter((item) => {
    if (search === "") {
      return item;
    } else if (
      item?.video_caption?.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    }
  });
  const onDeleteMode = () => {
    setDeleteMode((prev) => !prev);
    setSelectedItems([]);
  };

  const checkBoxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      var temp = selectedItems;
      temp = temp.filter((id) => {
        return id !== value;
      });
      temp ? setSelectedItems(temp) : setSelectedItems([]);
    }
  };

  const [
    deletePictures,
    { data: pictureData, isSuccess: isDeleteSuccess, isLoading: isDeleting },
  ] = useDeletePicturesMutation();

  const deletePics = async () => {
    const result = await Swal.fire({
      title: "Are you sure you want to delete picture(s)?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#febf4c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    });

    if (result.isConfirmed) {
      await Promise.all(
        selectedItems.map(async (ele) => {
          await deletePictures({ id: ele });
        })
      );
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success(pictureData.msg, {
        position: "top-right",
        autoClose: 1202,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeleteMode(false);
      setIsPicturesReload(true);
      refetchPictures().then(() => {
        setIsPicturesReload(false);
      });
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isPicFromCont && albumType === "gallery") {
      setIsPicturesReload(true);
      setCurrentScreen(1);
      refetchPictures().then(() => {
        setIsPicturesReload(false);
        dispatch(setAlbumType(""));
        dispatch(setIsPicFromCont(false));
      });
    }
  }, [isPicFromCont]);

  useEffect(() => {
    if (isVideoFromCont && albumType === "video") {
      setIsVideosReload(true);
      setCurrentScreen(2);
      refetchVideos().then(() => {
        setIsVideosReload(false);
        dispatch(setAlbumType(""));
        dispatch(setIsPicFromCont(false));
      });
    }
  }, [isVideoFromCont]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { duration: 1 } }}
    >
      <Nav />
      <div className="container body-width">
        <div className="col-md-12">
          <div className="indication1">
            <div
              className="d-flex justify-content-between cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <div className="">
                <img src="/assets/img/home.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Home</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate("/gallery")}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1">Gallery</h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() => navigate(`/gallery/${code}?show=${activeGalleryShow}`)}
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1 text-capitalize">
                  {code}
                </h4>
              </div>
            </div>
            <div
              className="d-flex justify-content-between ms-2 cursor-pointer"
              onClick={() =>
                navigate(
                  `/gallery/${code}/${season_code}?show=${activeGalleryShow}&season=${selectedGallerySeason}`
                )
              }
            >
              <div className="">
                <img src="/assets/img/arr-right1.svg" alt="" className="me-2" />
              </div>
              <div className="my-auto">
                <h4 className="my-auto mt-1 text-capitalize">
                  {season_code}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-4">
          <div className="w-100 d-md-flex mt-md-5 justify-content-between align-items-center margin-div">
            <div className="col-md-5">
              <div className="row title-font">
                <h4 className="text-capitalize">{album_code}</h4>
              </div>
            </div>
            <div className="col-md-7">
              <div className="d-flex float-end mt-4 mt-md-0">
                {currentScreen === 2 && (
                  <div
                    className={
                      expandSearch === true
                        ? "search-active me-3"
                        : "search me-3"
                    }
                  >
                    <div
                      className="icon"
                      onClick={() => setExpandSearch((prev) => !prev)}
                    ></div>
                    <div className="input">
                      <input
                        type="text"
                        id="seacrh-box"
                        placeholder={expandSearch === true ? "Search..." : " "}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {deleteMode ? (
                  <>
                    <button
                      className={
                        selectedItems?.length === 0
                          ? "btn btn-outline-dark rounded-pill px-md-4"
                          : "btn btn-outline-dark rounded-pill me-3 px-md-4"
                      }
                      onClick={onDeleteMode}
                    >
                      Cancel
                    </button>
                    {selectedItems?.length >= 1 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={deletePics}
                      >
                        Delete
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <div className="me-md-4 me-2">
                      <div
                        onClick={() => updatePage(1)}
                        className={
                          currentScreen === 1
                            ? "active-btn cursor-pointer"
                            : "inactive cursor-pointer"
                        }
                      >
                        <h4>Images</h4>
                      </div>
                    </div>
                    <div className="me-md-4 me-2 float-end">
                      <div
                        onClick={() => updatePage(2)}
                        className={
                          currentScreen === 2
                            ? "active-btn cursor-pointer"
                            : "inactive cursor-pointer"
                        }
                      >
                        <h4>Videos</h4>
                      </div>
                    </div>
                    {currentScreen === 1 && (
                      <div
                        className="upload-c me-md-3 me-2"
                        data-title="delete picture(s)"
                        onClick={onDeleteMode}
                      >
                        <img
                          src="/assets/img/bag.svg"
                          alt=""
                          className="cloud-size"
                        />
                      </div>
                    )}
                    <div
                      className="upload-c"
                      title={
                        currentScreen === 1
                          ? "upload picture(s)"
                          : "upload-video(s)"
                      }
                      onClick={() => {
                        currentScreen === 1 ? openPicModal() : openVidModal();
                      }}
                    >
                      <img
                        src="/assets/img/cloud-upload.svg"
                        alt=""
                        className="cloud-size"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            {currentScreen === 1 && (
              <Fade>
                <div className="col-md-12">
                  <div className="section-cover">
                    {!isPicturesLoading && !isPicturesReload ? (
                      <div className="images-cover">
                        {images?.length === 0 ? (
                          <div className="no-data-pos">
                            <h4>No Picture Found</h4>
                          </div>
                        ) : (
                          gallery?.pictures?.map((ele, i) => {
                            return (
                              <div className="image-grid">
                                {deleteMode && (
                                  <>
                                    <input
                                      type="checkbox"
                                      id={`checkImage${i}`}
                                      className="dele-c"
                                      value={ele?.id}
                                      onChange={checkBoxHandler}
                                      checked={selectedItems?.includes(ele?.id)}
                                    />
                                    <label htmlFor={`checkImage${i}`}>
                                      <div className="image-grid-overlay cursor-pointer"></div>
                                    </label>
                                  </>
                                )}
                                <img
                                  src={ele?.img_url}
                                  alt=""
                                  key={i}
                                  onClick={() =>
                                    viewImage(ele?.img_url, i, ele)
                                  }
                                  className="cursor-pointer"
                                />
                              </div>
                            );
                          })
                        )}
                      </div>
                    ) : (
                      <div className="loader-h">
                        <DotLoader
                          color={color}
                          loading={loading}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Fade>
            )}
            {currentScreen === 2 && (
              <Fade>
                <div className="col-md-12">
                  <div className="row">
                    {!isVideosLoading && !isVideosReload ? (
                      <>
                        {filteredList?.map((video, i) => {
                          return (
                            <div className="col-md-4 mt-4" key={i}>
                              <div>
                                <div className="show-card-background">
                                  <div className="d-flex absolute-constentant-bg1 mt-3">
                                    <div
                                      className="edit-contestant-bg cursor-pointer"
                                      onClick={() =>
                                        handleEditVideoClick(video)
                                      }
                                    >
                                      <h4>Edit</h4>
                                    </div>
                                    <div
                                      className="edit-contestant-bg ms-2 show-title-background1-edit1"
                                      onClick={() => handleClickDelete(video)}
                                    >
                                      <h4>Delete</h4>
                                    </div>
                                  </div>
                                  <div className="show-card-overlay"></div>
                                  <div
                                    className="show-card-inner"
                                    onClick={() => videoPreview(video)}
                                  >
                                    <div className="show-title">
                                      <h4>{video?.video_caption}</h4>
                                    </div>
                                  </div>
                                  <div className="show-image">
                                    <img src={video?.thumbnail} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="col-md-4 my-4">
                          <div
                            className="show-card-background1"
                            onClick={openVidModal}
                          >
                            {videos?.videos?.length === 0 && (
                              <div className="show-title-background1 bg1">
                                <h4 className="my-2">Create New Video</h4>
                              </div>
                            )}
                            <div className="show-card-inner1">
                              <img
                                src="/assets/img/addIcon.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="loader-h">
                        <DotLoader
                          color={color}
                          loading={loading}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Fade>
            )}
            {currentScreen === 3 && (
              <Fade>
                <div className="col-md-12">
                  <div className="youtube-box">
                    <ReactPlayer url={youtubeURL} className="video" controls />
                  </div>
                </div>
              </Fade>
            )}
          </div>
        </div>
      </div>
      {picData.image && (
        <div className="modal-bg">
          <div
            className="close-btn-pos cursor-pointer"
            onClick={() => imgAction()}
          >
            <img src="/assets/img/close.svg" alt="" />
          </div>
          <div
            className="bg-div cursor-pointer"
            onClick={() => imgAction()}
          ></div>
          <div
            onClick={() => !isPreviousDisabled && imgAction("prev-img")}
            className="cursor-pointer prev-div-bg"
          >
            <img
              src={
                isPreviousDisabled
                  ? "/assets/img/arrowLeft1Disable.svg"
                  : "/assets/img/arrowLeft1.svg"
              }
              alt=""
              className="width-arr"
            />
          </div>
          <Fade>
            <div className="img-pic-cover">
              <img
                src={picData.image}
                alt=""
                className="img"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
              />
            </div>
          </Fade>
          <div
            onClick={() => !isNextDisabled && imgAction("next-img")}
            className="cursor-pointer next-div-bg"
          >
            <img
              src={
                isNextDisabled
                  ? "/assets/img/arrowRight1Disable.svg"
                  : "/assets/img/arrowRight1.svg"
              }
              alt=""
              className="width-arr"
            />
          </div>
        </div>
      )}
      {isDeleting && <LoadingPage />}
      {isDeletingVideo && <LoadingPage />}
      <AddPicturesModal
        setShowUploadPicsModal={setShowUploadPicsModal}
        showUploadPicsModal={showUploadPicsModal}
        refetchPictures={refetchPictures}
        setIsPicturesReload={setIsPicturesReload}
        contestants={contestants}
      />
      <AddVideosModal
        setShowUploadVidsModal={setShowUploadVidsModal}
        showUploadVidsModal={showUploadVidsModal}
        setCurrentScreen={setCurrentScreen}
        refetchVideos={refetchVideos}
        setIsVideosReload={setIsVideosReload}
        contestants={contestants}
      />
      <UpdateVideo
        setShowUpdateVidsModal={setShowUpdateVidsModal}
        showUpdateVidsModal={showUpdateVidsModal}
        selectedVideo={selectedVideo}
        refetchVideos={refetchVideos}
        setIsVideosReload={setIsVideosReload}
      />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </motion.div>
  );
};
export default SelectedAlbumPage;
