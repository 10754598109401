import { useEffect, useRef, useState } from "react";
import { useDispatch} from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { logout } from "../../app/authSlice";
import { toast } from "react-toastify";
import { realityApi, useGetShowSeasonQuery } from "../../app/apiSlice";
import secureLocalStorage from "react-secure-storage";

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code, season_code, album_code, team_name } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const activeShow = queryParams.get("show");
  const season = queryParams.get("season");
  const activeAlbum = queryParams.get("album");

  const dispatch = useDispatch();
  const profileDropdownRef = useRef(null);
  const [isOpen, setIsopen] = useState(false);
  const profile = JSON?.parse(secureLocalStorage?.getItem("user"))?.user;
  const permissions = JSON?.parse(
    secureLocalStorage?.getItem("user")
  )?.permissions;
  const [showLogout, setShowLogout] = useState(false);
  const [selectedSeason, setSelectedSeasn] = useState({});

  const isCanViewUserIncluded = permissions?.includes("can_view_user");
  function convertSnakeCaseToTitleCase(snakeCaseString) {
    return snakeCaseString
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const {
    data: seasons,
    isLoading: isSeasonLoading,
    refetch: refetchSeasons,
  } = useGetShowSeasonQuery({
    showID: activeShow,
  });
  const toggleShow = () => {
    setShowLogout((prev) => !prev);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(realityApi.util.resetApiState());
    toast.success("User Logout Successfully", {
      position: "top-right",
      autoClose: 1202,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    navigate("/");
  };

  isOpen
    ? (document.body.style.overflowY = "hidden")
    : (document.body.style.overflowY = "auto");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setShowLogout(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="container body-width">
        <div className="col-md-12">
          <div className="d-flex justify-content-between align-items-center py-md-4 py-3 nav-bar-fix">
            <div className="">
              <img
                src="/assets/img/logo1.svg"
                alt=""
                className="img-fluid logo1-size logo-display cursor-pointer"
                onClick={() => navigate("/dashboard")}
              />
              <img
                src="/assets/img/hamburger.svg"
                className="img-fluid hamburger"
                alt=""
                onClick={() => setIsopen(true)}
              />
            </div>
            <div className="profile-covr">
              <div
                className="profile-background cursor-pointer"
                onClick={toggleShow}
              >
                <div className="d-flex">
                  <div className="profile-img-cover">
                    <img src="/assets/img/default.png" alt="" className="" />
                  </div>
                </div>
                <div className="d-md-block d-none ms-2">
                  <div className="user-name">
                    <h4>{profile?.full_name}</h4>
                  </div>
                  <div className="user-name">
                    <h5 className="text-capatilize">
                      {profile?.role?.title ?? "N/A"}
                    </h5>
                  </div>
                </div>
                <div>
                  <img
                    src={
                      showLogout
                        ? "/assets/img/arr-up.svg"
                        : "/assets/img/arr-down.svg"
                    }
                    alt=""
                    className="ms-3 me-2 arr-d-size"
                  />
                </div>
              </div>
              {showLogout && (
                <div
                  className="dropdown-content-cover dropdown-btn-mobile1 signout-bg"
                  ref={profileDropdownRef}
                >
                  <div className="dropdown-content dropdown-content-mobile cont-drop">
                    <>
                      {isCanViewUserIncluded && (
                        <div
                          className="dropdown-item cursor-pointer"
                          name="no_value"
                          onClick={() => navigate("/settings")}
                        >
                          Settings
                        </div>
                      )}
                      <div
                        className="dropdown-item cursor-pointer"
                        name="no_value"
                        onClick={handleLogout}
                      >
                        Logout
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={isOpen ? "hamburgermenu-active" : "hamburgermenu"}>
        <div className="hamburger-inner">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="ham-menu">
                <h4>MENU</h4>
              </div>
              <div>
                <img
                  src="/assets/img/close1.svg"
                  alt=""
                  className="close-btn-size"
                  onClick={() => setIsopen(false)}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 ms-2">
            <div className="hamburger-menu-items">
              {location.pathname === "/dashboard" && (
                <div className="d-flex align-items-center">
                  <img
                    src="/assets/img/homepie.svg"
                    alt=""
                    className="home-svg"
                  />
                  <h4 style={{ color: "#ababab" }}>Home</h4>
                </div>
              )}
              {location.pathname === "/reality-shows" && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <img
                      src="/assets/img/show-icon.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#ababab" }}>Reality Shows</h4>
                  </div>
                </div>
              )}
              {location.pathname === "/gallery" && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <img
                      src="/assets/img/show-icon.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#ababab" }}>Gallery</h4>
                  </div>
                </div>
              )}
              {location.pathname === `/reality-show/${code}` && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/reality-shows")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Reality Shows</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3 ms-4">
                    <img
                      src="/assets/img/right1.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#ababab" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                </div>
              )}
              {location.pathname === `/gallery/${code}` && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/gallery")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Gallery</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3 ms-4">
                    <img
                      src="/assets/img/right1.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#ababab" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                </div>
              )}
              {location.pathname === `/reality-show/${code}/${season_code}` && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/reality-shows")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Reality Shows</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 ms-4"
                    onClick={() =>
                      navigate(`/reality-show/${code}?show=${activeShow}`)
                    }
                  >
                    <img
                      src="/assets/img/right.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#111" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                  <div className="ms-4">
                    <div className="d-flex align-items-center mt-3 ms-4">
                      <img
                        src="/assets/img/right1.svg"
                        alt=""
                        className="right-svg me-1"
                      />
                      <h4
                        style={{
                          color: "#ababab",
                        }}
                      >
                        {convertSnakeCaseToTitleCase(season_code)}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {location.pathname === `/gallery/${code}/${season_code}` && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/gallery")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Gallery</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 ms-4"
                    onClick={() =>
                      navigate(`/gallery/${code}?show=${activeShow}`)
                    }
                  >
                    <img
                      src="/assets/img/right.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#111" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                  <div className="ms-4">
                    <div className="d-flex align-items-center mt-3 ms-4">
                      <img
                        src="/assets/img/right1.svg"
                        alt=""
                        className="right-svg me-1"
                      />
                      <h4
                        style={{
                          color: "#ababab",
                        }}
                      >
                        {convertSnakeCaseToTitleCase(season_code)}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {location.pathname ===
                `/gallery/${code}/${season_code}/${album_code}` && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/gallery")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Gallery</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 ms-4"
                    onClick={() =>
                      navigate(`/gallery/${code}?show= ${activeShow}`)
                    }
                  >
                    <img
                      src="/assets/img/right.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#111" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                  <div className="d-flex align-items-center mt-3 ms-4">
                    <div
                      className="d-flex align-items-center ms-4"
                      onClick={() =>
                        navigate(
                          `/gallery/${code}/${season_code}?show=${activeShow}&season=${season}`
                        )
                      }
                    >
                      <img
                        src="/assets/img/right.svg"
                        alt=""
                        className="right-svg me-1"
                      />
                      <h4>{convertSnakeCaseToTitleCase(season_code)}</h4>
                    </div>
                  </div>
                  <div className="ms-4">
                    <div className="ms-4">
                      <div className="d-flex align-items-center mt-3 ms-4">
                        <img
                          src="/assets/img/right1.svg"
                          alt=""
                          className="right-svg me-1"
                        />
                        <h4
                          style={{
                            color: "#ababab",
                          }}
                        >
                          {album_code}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(location.pathname ===
                `/reality-show/${code}/${season_code}/judges_score` ||
                location.pathname ===
                  `/reality-show/${code}/${season_code}/leader_board` ||
                location.pathname ===
                  `/reality-show/${code}/${season_code}/transactions`) && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/reality-shows")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Reality Shows</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 ms-4"
                    onClick={() =>
                      navigate(`/reality-show/${code}?show=${activeShow}`)
                    }
                  >
                    <img
                      src="/assets/img/right.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#111" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                  <div className="d-flex align-items-center mt-3 ms-4">
                    <div
                      className="d-flex align-items-center ms-4"
                      onClick={() =>
                        navigate(
                          `/reality-show/${code}/${season_code}?show=${activeShow}&season=${season}`
                        )
                      }
                    >
                      <img
                        src="/assets/img/right.svg"
                        alt=""
                        className="right-svg me-1"
                      />
                      <h4>{convertSnakeCaseToTitleCase(season_code)}</h4>
                    </div>
                  </div>
                  <div className="ms-4">
                    <div className="ms-4">
                      <div
                        className="d-flex align-items-center mt-3 ms-4"
                        onClick={() =>
                          navigate(
                            `/reality-show/${code}/${season_code}?show=${activeShow}&season=${season}`
                          )
                        }
                      >
                        <img
                          src="/assets/img/right1.svg"
                          alt=""
                          className="right-svg me-1"
                        />
                        <h4
                          style={{
                            color: "#ababab",
                          }}
                        >
                          Contestants
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {location.pathname ===
                `/reality-show/${code}/${season_code}/categories` && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/reality-shows")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Reality Shows</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 ms-4"
                    onClick={() =>
                      navigate(`/reality-show/${code}?show=${activeShow}`)
                    }
                  >
                    <img
                      src="/assets/img/right.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#111" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                  <div className="ms-4">
                    <div className="d-flex align-items-center mt-3 ms-4">
                      <img
                        src="/assets/img/right1.svg"
                        alt=""
                        className="right-svg me-1"
                      />
                      <h4
                        style={{
                          color: "#ababab",
                        }}
                      >
                        {convertSnakeCaseToTitleCase(season_code)}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {location.pathname ===
                `/reality-show/${code}/${season_code}/${team_name}` && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    onClick={() => navigate("/reality-shows")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/img/show-icon1.svg"
                        alt=""
                        className="home-svg"
                      />
                      <h4 style={{ color: "#111" }}>Reality Shows</h4>
                    </div>
                    <div className="me-2">
                      <img src="/assets/img/up1.svg" alt="" />
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 ms-4"
                    onClick={() =>
                      navigate(`/reality-show/${code}?show=${activeShow}`)
                    }
                  >
                    <img
                      src="/assets/img/right.svg"
                      alt=""
                      className="right-svg me-1"
                    />
                    <h4 style={{ color: "#111" }}>
                      {convertSnakeCaseToTitleCase(code)}
                    </h4>
                  </div>
                  <div className="ms-4">
                    <div
                      className="d-flex align-items-center mt-3 ms-4"
                      onClick={() =>
                        navigate(
                          `/reality-show/${code}/${season_code}/categories?show=${activeShow}&season=${season}`
                        )
                      }
                    >
                      <img
                        src="/assets/img/right.svg"
                        alt=""
                        className="right-svg me-1"
                      />
                      <h4
                        style={{
                          color: "#111",
                        }}
                      >
                        {convertSnakeCaseToTitleCase(season_code)}
                      </h4>
                    </div>
                  </div>
                  <div className="ms-4">
                    <div className="d-flex align-items-center mt-3 ms-4">
                      <img
                        src="/assets/img/right1.svg"
                        alt=""
                        className="right-svg me-1"
                      />
                      <h4
                        style={{
                          color: "#ababab",
                        }}
                      >
                        {convertSnakeCaseToTitleCase(team_name)}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {location.pathname === "/settings" && (
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src="/assets/img/homepie1.svg"
                      alt=""
                      className="home-svg"
                    />
                    <h4 style={{ color: "#111" }}>Home</h4>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="logout-margin d-flex align-items-center cursor-pointer"
            onClick={handleLogout}
          >
            <img src="/assets/img/log.svg" alt="" className="logout-size" />

            <div className="my-auto sign-font">
              <h4 className="my-auto">Sign Out</h4>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};
export default Nav;
